import { Box, Button, Flex, Group, Modal, Stack, Title } from '@mantine/core';
import { EditorContent } from '@tiptap/react';
import { watchPreviewContent } from '@tiptap-pro/extension-collaboration-history';
import { FC, useCallback, useEffect } from 'react';

import { useAdvancedEditor } from '../../hooks';
import { VersionItem } from './components';
import { useVersions } from './hooks';
import { VersioningModalProps } from './types';
import { getVersionName } from './utils';
import classNames from './VersioningModal.module.css';

export const VersioningModal: FC<VersioningModalProps> = ({ editor, provider, isOpen, onClose, isCompleted }) => {
  const { currentVersionId, setCurrentVersionId, versions, versionData, lastVersion, isLastVersion } = useVersions({
    editor,
  });

  const { editor: previewEditor } = useAdvancedEditor({ content: '', options: { editable: false } });

  const handleVersionChange = useCallback(
    (newVersion: number) => {
      setCurrentVersionId(newVersion);

      provider.sendStateless(
        JSON.stringify({
          action: 'version.preview',
          version: newVersion,
        })
      );
    },
    [provider, setCurrentVersionId]
  );

  const handleClose = useCallback(() => {
    onClose();
    setCurrentVersionId(null);
    previewEditor?.commands.clearContent();
  }, [onClose, previewEditor?.commands, setCurrentVersionId]);

  const handleRevert = useCallback(() => {
    if (currentVersionId === null || !versionData) {
      return;
    }

    editor?.commands.revertToVersion(currentVersionId);
    onClose();
  }, [currentVersionId, editor?.commands, onClose, versionData]);

  useEffect(() => {
    if (isOpen && currentVersionId === null && versions.length > 0) {
      if (!lastVersion && lastVersion !== 0) {
        return;
      }

      setCurrentVersionId(lastVersion);

      provider?.sendStateless(
        JSON.stringify({
          action: 'version.preview',
          version: lastVersion,
        })
      );
    }
  }, [currentVersionId, lastVersion, isOpen, provider, setCurrentVersionId, versions]);

  useEffect(() => {
    if (!isOpen) {
      return () => {};
    }

    const unbindContentWatcher = watchPreviewContent(provider, (content) => {
      if (previewEditor) {
        previewEditor.commands.setContent(content);
      }
    });

    return () => {
      unbindContentWatcher();
    };
  }, [isOpen, provider, previewEditor]);

  const shouldDisableRestore = !versionData || isLastVersion || isCompleted;

  return (
    <Modal opened={isOpen} onClose={handleClose} size="55rem" withCloseButton={false} padding={0}>
      <Flex direction="row" align="stretch" h={700}>
        <Box className={classNames.main}>
          <EditorContent editor={previewEditor} />
        </Box>
        <Box className={classNames.sidebar}>
          <Box className={classNames.title}>
            <Title order={5} mb={16}>
              History ({versions.length} versions)
            </Title>
          </Box>
          <Stack gap={6} className={classNames.versions} flex={1}>
            {versions.map((v: any) => (
              <VersionItem
                date={v.date}
                number={v.version}
                title={getVersionName(v)}
                onClick={() => handleVersionChange(v.version)}
                isActive={currentVersionId === v.version}
                key={`version_item_${v.version}`}
              />
            ))}
          </Stack>
          <Group gap={8} className={classNames.buttons}>
            <Button variant="outline" type="button" onClick={onClose}>
              Close
            </Button>
            <Button type="button" disabled={shouldDisableRestore} onClick={handleRevert}>
              Restore
            </Button>
          </Group>
        </Box>
      </Flex>
    </Modal>
  );
};

import { Center, px, SegmentedControl, Tooltip } from '@mantine/core';
import { IconColumns, IconId, IconTable } from '@tabler/icons-react';
import { FC } from 'react';

import { LayoutTypes } from '../../../../types';
import { LayoutSelectProps } from './types';

export const LayoutSelect: FC<LayoutSelectProps> = ({ setLayoutField }) => (
  <SegmentedControl
    onChange={(value) => {
      setLayoutField((value?.toString() as LayoutTypes) || LayoutTypes.Stream);
    }}
    data={[
      {
        label: (
          <Tooltip label="Stream view" withArrow>
            <Center>
              <IconId size={px('1.125rem')} />
            </Center>
          </Tooltip>
        ),
        value: LayoutTypes.Stream,
      },
      {
        label: (
          <Tooltip label="Report view" withArrow>
            <Center>
              <IconTable size={px('1.125rem')} />
            </Center>
          </Tooltip>
        ),
        value: LayoutTypes.Table,
      },
      {
        label: (
          <Tooltip label="Board view" withArrow>
            <Center>
              <IconColumns size={px('1.125rem')} />
            </Center>
          </Tooltip>
        ),
        value: LayoutTypes.Columns,
      },
    ]}
  />
);

import { Box, ScrollArea } from '@mantine/core';
import { FC, useMemo } from 'react';

import { useSetDocumentTitle, useTemplatesManager } from '../../hooks';
import { Header, TemplatesList } from './components';

export const TemplatesListPage: FC = () => {
  const { templates } = useTemplatesManager();

  useSetDocumentTitle('Templates');

  const myTemplates = useMemo(() => templates.sort((a, b) => a.name.localeCompare(b.name)), [templates]);

  return (
    <div style={{ paddingBottom: '16px', height: '100%', overflow: 'auto' }}>
      <ScrollArea.Autosize w="100%" type="auto">
        <Header />
        <Box pl="sm" pt="sm" pr="sm">
          <TemplatesList templates={myTemplates} />
        </Box>
      </ScrollArea.Autosize>
    </div>
  );
};

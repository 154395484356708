import { useState } from 'react';

import { Filters } from '../../components/FilterControl/types';
import { SortTypes } from '../../components/Sorting/types';

export const useFilters = () => {
  const [filters, setFilters] = useState<Filters>({
    sortField: SortTypes.Modified,
    sortOrder: true,
    searchQuery: '',
    filter: null,
  });

  const handleFilterChange = (updatedFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...updatedFilters }));
  };

  return { filters, handleFilterChange };
};

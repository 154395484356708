import { useEffect, useState } from 'react';

import { Card, CollectionType } from '../../../../../data';
import { useCardCollections, useCollectionsManager } from '../../../../../hooks';

interface Props {
  card: Card;
}

export const useSelectOptions = ({ card }: Props) => {
  const [data, setData] = useState<{ id: string; title: string }[]>([]);

  const { collections } = useCollectionsManager();
  const cardCollections = useCardCollections({ card, collectionType: CollectionType.Meeting });

  useEffect(() => {
    const meetingSet = collections
      ?.filter((c) => c.type === CollectionType.Meeting && !cardCollections.some((cc) => cc.id === c.id))
      .map(({ id, title }) => ({ id, title }));

    if (meetingSet) {
      setData(meetingSet);
    }
  }, [cardCollections, collections]);

  return { data, setData };
};

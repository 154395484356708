import { Stack, Text, Title } from '@mantine/core';
import { IconReportSearch } from '@tabler/icons-react';
import { FC } from 'react';

export const EmptyStream: FC = () => (
  <Stack h="100%" align="center" pt={48} gap={0}>
    <IconReportSearch size={164} color="gray" stroke={0.8} />

    <Title order={2} mt={16}>
      There aren&apos;t currently any cards in this stream.
    </Title>

    <Text mt={16} c="dimmed">
      Create a new card by clicking the &apos;+&apos; button
    </Text>
    <Text c="dimmed">or</Text>
    <Text c="dimmed">Add an existing card to this stream from the &apos;...&apos; menu on any card.</Text>
  </Stack>
);

import { Anchor, Button, Center, LoadingOverlay, Paper, PasswordInput, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FC, useCallback } from 'react';

import { useAuthContext } from '../../../../contexts';
import { PasswordChangeFormParams } from '../../../../contexts/AuthContext/types';
import { useUsersManager } from '../../../../hooks';

export const PasswordChangeForm: FC = () => {
  const { currentUser } = useUsersManager();
  const { changePasswordMutation } = useAuthContext();
  const { isPending, error, isSuccess } = changePasswordMutation;

  const form = useForm<PasswordChangeFormParams>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (value) => (value.length < 2 ? 'Invalid password' : null),
      confirmPassword: (value, values) => (value !== values.password ? 'Passwords did not match' : null),
    },
  });

  const handleSubmit = useCallback(
    async (values: PasswordChangeFormParams) => {
      if (currentUser?.email) {
        changePasswordMutation.mutate({ email: currentUser.email, password: values.password });

        form.reset();
      }
    },
    [currentUser?.email, changePasswordMutation, form]
  );

  return (
    <Paper w="500" p="xl" shadow="xl" style={{ position: 'relative' }}>
      <LoadingOverlay visible={isPending} loaderProps={{ children: 'Loading...' }} />

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Title ta="center" mb="md">
          Change your password
        </Title>
        <PasswordInput
          label="New Password"
          placeholder="New password"
          required
          mt="md"
          {...form.getInputProps('password')}
        />
        <PasswordInput
          label="Confirm New Password"
          placeholder="Confirm new password"
          required
          mt="md"
          {...form.getInputProps('confirmPassword')}
        />
        <Button type="submit" fullWidth mt="xl">
          Change Password
        </Button>
        {!!error && (
          <Center mt={16}>
            <Text c="#E03131">
              Unexpected has has occurred. Please try again, or contact Andrew at{' '}
              <Anchor href="mailto:ao@onlychoices.net">ao@onlychoices.net</Anchor> for assistance.
            </Text>
          </Center>
        )}
        {isSuccess && (
          <Center mt={16}>
            <Text c="#2ac62c">You password has been successfully changed.</Text>
          </Center>
        )}
      </form>
    </Paper>
  );
};

import { ActionIcon, Paper, Stack, Text, Tooltip } from '@mantine/core';
import { IconArrowBackUp } from '@tabler/icons-react';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSetDocumentTitle, useTemplatesManager } from '../../../../hooks';
import { TemplateEditZone } from './components';
import { TemplateProps } from './types';

export const Template: FC<TemplateProps> = ({ templateId }) => {
  const { getTemplate } = useTemplatesManager();
  const navigate = useNavigate();

  const template = getTemplate(templateId);

  useSetDocumentTitle(`Edit ${template?.name}`);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!template || template.id === '') {
    return <Text>Loading...</Text>;
  }

  return (
    <Paper shadow="xs" p="md">
      <Tooltip label="To all templates" withArrow>
        <ActionIcon onClick={handleGoBack} variant="filled" color="blue" size={26} mb={24}>
          <IconArrowBackUp stroke={1.5} />
        </ActionIcon>
      </Tooltip>

      <Text mb={16} fw={500} fz={24}>
        Edit Template
      </Text>
      <Stack>
        <TemplateEditZone template={template} />
      </Stack>
    </Paper>
  );
};

import { Stack, Text } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../../../../hooks';
import { FullName, ProfileAvatar } from './components';

export const UserDetails: FC = () => {
  const { currentUser } = useUsersManager();

  if (!currentUser) {
    return null;
  }

  const { displayname, fullname } = currentUser;

  return (
    <Stack gap="2px" align="center">
      <ProfileAvatar currentUser={currentUser} />
      <FullName fullname={fullname} />
      <Text c="gray" fz="sm">
        @{displayname}
      </Text>
    </Stack>
  );
};

import { Card } from '../../../../../../../data';

export const applyNoActivityFor3DaysFilter = (cards: Card[]) => {
  return cards.filter(({ tmodified }) => {
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

    return new Date(tmodified) < threeDaysAgo;
  });
};

import { Box, Center, Group, ScrollArea, SegmentedControl, Stack, Table, Text } from '@mantine/core';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

import { BackgroundImage } from '../../components/BackgroundImage';
import { PageHeader } from '../../components/PageHeader';
import { StateLabel } from '../../components/StateLabel';
import { UserAvatar } from '../../components/UserAvatar';
import { DEFAULT_POSTER } from '../../constants/backgroundImage';
import { Card } from '../../data';
import { useRequestsManager, useSetDocumentTitle } from '../../hooks';
import { getCardRequestsMap, getCardRoute } from '../../utils';
import { FilterControl } from './components';
import { useArchiveCards, useFilters } from './hooks';

export const ArchivePage: FC = () => {
  useSetDocumentTitle('Archive');
  const { accountId } = useParams();
  const { requests } = useRequestsManager();

  const { filters, handleFilterChange } = useFilters({});
  const [range, setRange] = useState('all');
  const navigate = useNavigate();

  // Get all cards
  const { archiveCards } = useArchiveCards({
    range,
    filters,
  });

  // Add requests
  const openRequests = requests.filter((request) => request.response === '');
  const cardRequests = getCardRequestsMap(openRequests, archiveCards);

  const onOpenCard = (card: Card) => {
    if (!accountId) {
      return;
    }
    navigate(getCardRoute(accountId, card.id));
  };

  return (
    <div style={{ paddingBottom: '16px', height: '100%', overflow: 'auto' }}>
      <ScrollArea.Autosize w="100%" type="auto">
        <BackgroundImage src={DEFAULT_POSTER}>
          <Stack>
            <PageHeader />
            <Stack>
              <Center>
                <Text fz="50px" pt="0" mt="0" mb="0" pb="0" style={{ textShadow: '0px 0px 12px white' }}>
                  The Vault
                </Text>
              </Center>
              <Center>
                <Text
                  style={{
                    background: 'rgb(255 255 255 / 30%)',
                    backdropFilter: 'blur(10px) saturate(100%)',
                    padding: '8px 10px',
                    borderRadius: '8px',
                  }}
                >
                  All Completed and Archived Cards
                </Text>
              </Center>
            </Stack>
          </Stack>
          <Group justify="space-between" pl="sm" pr="sm" pb="sm">
            <Group>
              <SegmentedControl
                mt="lg"
                value={range}
                onChange={setRange}
                data={[
                  { label: 'all time', value: 'all' },
                  { label: 'last year', value: 'lastyear' },
                  { label: 'this year', value: 'thisyear' },
                  { label: 'last month', value: 'lastmonth' },
                  { label: 'this month', value: 'thismonth' },
                ]}
              />
            </Group>
            <FilterControl filters={filters} onChangeFilter={handleFilterChange} />
          </Group>
        </BackgroundImage>
        <Box pl="sm" pt="sm" pr="sm">
          <Table striped highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Card Title</Table.Th>
                <Table.Th>Requests</Table.Th>
                <Table.Th>Creator</Table.Th>
                <Table.Th>State</Table.Th>
                <Table.Th>Created</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {archiveCards.map((card) => (
                <Table.Tr key={card.id}>
                  <Table.Td>
                    <Text style={{ cursor: 'pointer' }} onClick={() => onOpenCard(card)}>
                      {card.description}
                    </Text>
                  </Table.Td>
                  <Table.Td align="center">
                    <Text>
                      {cardRequests[card.id].filter((req: any) => req.response === '').length > 0
                        ? cardRequests[card.id].filter((req: any) => req.response === '').length
                        : null}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <UserAvatar short user_id={card.creator_id} />
                  </Table.Td>
                  <Table.Td>
                    <StateLabel status={card.state} />
                  </Table.Td>
                  <Table.Td>
                    <Text fz="sm" color="grey">
                      <ReactTimeAgo date={card.tcreated} locale="en-US" />
                    </Text>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Box>
      </ScrollArea.Autosize>
    </div>
  );
};

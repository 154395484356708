import { Menu, px, Text, Tooltip } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { IconTable } from '@tabler/icons-react';
import { FC } from 'react';

import { CellShades } from './components';
import { controls } from './constants';
import { controlSx } from './styles';
import { TableControlProps } from './types';

export const TableControl: FC<TableControlProps> = ({ editor }) => (
  <Menu shadow="md">
    <Menu.Target>
      <Tooltip label="Table" withArrow>
        <RichTextEditor.Control>
          <IconTable stroke={1.5} size="1rem" />
        </RichTextEditor.Control>
      </Tooltip>
    </Menu.Target>

    <Menu.Dropdown>
      <RichTextEditor.ControlsGroup style={{ flexDirection: 'column', gap: '2px' }}>
        {controls.map(({ title, icon: Icon, action }) => (
          <RichTextEditor.Control key={title} onClick={() => action(editor)} aria-label={title} style={controlSx}>
            <Icon stroke={1.5} size={px('1rem')} />
            <Text fz="xs">{title}</Text>
          </RichTextEditor.Control>
        ))}

        <CellShades editor={editor} />
      </RichTextEditor.ControlsGroup>
    </Menu.Dropdown>
  </Menu>
);

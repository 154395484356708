import { Group, Stack, Text } from '@mantine/core';
import { IconPointFilled } from '@tabler/icons-react';
import { FC } from 'react';

import { TimeAgo } from '../../../../../../../../../../components/TimeAgo';
import { TimelineItemProps } from './types';

export const TimelineItem: FC<TimelineItemProps> = ({ isMe, user, time, rightSection, children }) => (
  <Stack gap="8px">
    <Group gap="8px" mt="-4px" w="100%" justify="space-between">
      <Group gap="8px" align="center">
        <Text c="dark.7" fw="500">
          {user?.fullname} {isMe ? '(me)' : ''}
        </Text>

        {time && (
          <>
            <IconPointFilled size={12} style={{ color: '#8a92a9' }} />
            <TimeAgo time={time} />
          </>
        )}
      </Group>

      <Group gap="8px" align="center" flex={1} justify="flex-end">
        {rightSection}
      </Group>
    </Group>

    {children}
  </Stack>
);

import { Avatar, Button, Flex, Stack } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../../../../hooks';
import { VersionItemProps } from './types';
import { renderDate } from './utils';
import classNames from './VersionItem.module.css';

export const VersionItem: FC<VersionItemProps> = ({ title, date, number, isActive, onClick }) => {
  const { getUser } = useUsersManager();
  const user = title ? getUser(title) : null;

  return (
    <Button
      classNames={{
        inner: classNames.inner,
        root: classNames.root,
      }}
      onClick={onClick}
      variant={isActive ? 'outline' : 'subtle'}
    >
      <Stack gap="xs" align="flex-start" justify="flex-start">
        {user && (
          <Flex align="center" gap={8}>
            <Avatar src={user?.avatar} size="sm" radius="xl" alt={user?.displayname} />
            {user?.displayname} - version {number}
          </Flex>
        )}
        {!user && <Flex>{title}</Flex>}
        {renderDate(date)}
      </Stack>
    </Button>
  );
};

export const filterOptions = (options: { id: string; title: string }[], search: string) => {
  const sortedOptions = options.sort((a, b) => a.title.localeCompare(b.title));

  if (search === '') {
    return sortedOptions;
  }

  const searchLower = search.toLowerCase().trim();
  return sortedOptions.filter(({ title }) => title.toLowerCase().includes(searchLower));
};

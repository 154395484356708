import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { REFETCH_INTERVAL } from '../constants/query';
import { useAuthContext } from '../contexts/AuthContext';
import { cardsService, collectionsService } from '../services';
import { CreateCardParams } from '../services/cardsService';
import { AddCardToCollectionParams } from '../services/collectionsService';

export const useCardsManager = () => {
  const { isAuthenticated } = useAuthContext();
  const queryClient = useQueryClient();

  const { data: collectionCards } = useQuery({
    queryKey: ['collectionCards'],
    queryFn: collectionsService.getCollectionCards,
    enabled: !!isAuthenticated,
    initialData: [],
    retry: false,
    refetchInterval: REFETCH_INTERVAL,
  });
  const { data: cards, isFetching } = useQuery({
    queryKey: ['cards'],
    queryFn: cardsService.getCards,
    enabled: !!isAuthenticated,
    initialData: [],
    retry: false,
    refetchInterval: REFETCH_INTERVAL,
  });
  const { data: snoozedCards } = useQuery({
    queryKey: ['snoozedCards'],
    queryFn: cardsService.getSnoozedCards,
    enabled: !!isAuthenticated,
    initialData: [],
    retry: false,
    refetchInterval: REFETCH_INTERVAL,
  });
  const { data: nudgedCards } = useQuery({
    queryKey: ['nudgedCards'],
    queryFn: cardsService.getNudgedCards,
    enabled: !!isAuthenticated,
    initialData: [],
    retry: false,
    refetchInterval: REFETCH_INTERVAL,
  });

  const addCardToCollectionMutation = useMutation({
    mutationFn: collectionsService.addCardToCollection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['collectionCards'] });
    },
  });
  const removeCardFromCollectionMutation = useMutation({
    mutationFn: collectionsService.removeCardFromCollection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['collectionCards'] });
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ['collectionCards'] });
    },
  });
  const addCardMutation = useMutation({
    mutationFn: cardsService.createCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cards'] });
    },
  });
  const updateCardMutation = useMutation({
    mutationFn: cardsService.updateCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cards'] });
    },
  });
  const uploadImageMutation = useMutation({
    mutationFn: cardsService.uploadImage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cards'] });
    },
  });
  const deleteCardMutation = useMutation({
    mutationFn: cardsService.deleteCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cards'] });
    },
  });
  const snoozeCardMutation = useMutation({
    mutationFn: cardsService.snoozeCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['snoozedCards'] });
      queryClient.invalidateQueries({ queryKey: ['cards'] });
      queryClient.invalidateQueries({ queryKey: ['collectionCards'] });
    },
  });
  const unSnoozeCardMutation = useMutation({
    mutationFn: cardsService.unSnoozeCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['snoozedCards'] });
      queryClient.invalidateQueries({ queryKey: ['cards'] });
      queryClient.invalidateQueries({ queryKey: ['collectionCards'] });
    },
  });
  const nudgeCardMutation = useMutation({
    mutationFn: cardsService.nudgeCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['nudgedCards'] });
      queryClient.invalidateQueries({ queryKey: ['cards'] });
    },
  });

  const getCard = useCallback((id: string) => cards.find((card) => card.id === id), [cards]);
  const getCardsForCollection = useCallback(
    (id: string) => collectionCards.filter((card) => card.collection_id === id),
    [collectionCards]
  );

  const addCollectionCard = useCallback(
    (params: AddCardToCollectionParams) => addCardToCollectionMutation.mutateAsync(params),
    [addCardToCollectionMutation]
  );

  const addCard = useCallback(
    async (params: CreateCardParams, collectionId: string) => {
      const card = await addCardMutation.mutateAsync(params);

      await addCollectionCard({ collectionId, cardId: card.id });
    },
    [addCardMutation, addCollectionCard]
  );

  return {
    isLoadingCards: isFetching,
    collectionCards,
    cards,
    snoozedCards,
    nudgedCards,
    getCard,
    getCardsForCollection,
    addCollectionCard,
    addCard,
    removeCollectionCard: removeCardFromCollectionMutation.mutateAsync,
    updateCard: updateCardMutation.mutateAsync,
    removeCard: deleteCardMutation.mutateAsync,
    snoozeCard: snoozeCardMutation.mutateAsync,
    uploadImage: uploadImageMutation.mutateAsync,
    unSnoozeCard: unSnoozeCardMutation.mutateAsync,
    nudgeCard: nudgeCardMutation.mutateAsync,
  };
};

import { useMemo } from 'react';

import { useAuthContext } from '../../../../contexts';
import { useCardsManager, useFavorites, useRequestsManager, useUsersManager } from '../../../../hooks';
import { Filters } from '../../components/FilterControl/types';
import { getArchiveCards } from './utils';

interface UseArchiveCardsProps {
  range: string;
  filters: Filters;
}

export const useArchiveCards = (props: UseArchiveCardsProps) => {
  const { cards } = useCardsManager();
  const { getUser } = useUsersManager();
  const { currentUserId } = useAuthContext();
  const { getRequestsForCard } = useRequestsManager();
  const { favoriteCards } = useFavorites();

  const archiveCards = useMemo(
    () => getArchiveCards({ cards, getUser, currentUserId, getRequestsForCard, favoriteCards, ...props }),
    [cards, props, getUser, currentUserId, getRequestsForCard, favoriteCards]
  );

  return {
    archiveCards,
  };
};

import { Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import { FC } from 'react';

import { DeleteCardModal } from '../../../../../../../DeleteCardModal';
import { DeleteCardProps } from './types';

export const DeleteCard: FC<DeleteCardProps> = ({ card }) => {
  const [deleteCardOpened, deleteCardHandlers] = useDisclosure(false);

  const isCompleted = card.state === 'completed' || card.state === 'archived';

  return (
    <>
      <DeleteCardModal card={card} isOpened={deleteCardOpened} onClose={deleteCardHandlers.close} />

      <Button variant="light" disabled={isCompleted} onClick={deleteCardHandlers.open}>
        <Group gap="4px">
          <IconTrash size={14} />
          Delete Card
        </Group>
      </Button>
    </>
  );
};

import { FilterTypes } from './types';

export const FILTER_LABELS: Record<FilterTypes, string> = {
  [FilterTypes.Snoozed]: 'Snoozed',
  [FilterTypes.Favorites]: 'Favorites',
  [FilterTypes.OpenRequestsToMe]: 'Open Requests to Me',
  [FilterTypes.OpenRequestsFromMe]: "Open Requests I've Sent",
  [FilterTypes.NoActivityFor3Days]: 'No Activity for 3 Days',
  [FilterTypes.NewInLast24Hours]: 'New in the Last 24 Hours',
};

import { FilterTypes } from '../../../../../../components/FilterControl/types';
import { useAuthContext } from '../../../../../../contexts';
import { Collection, CollectionType, Request } from '../../../../../../data';
import { useCardsManager } from '../../../../../../hooks';
import { useIsAllCards } from '../../../useIsAllCards';
import { getAllStreamCards, getMyStreamCards, getStreamCards } from './utils';

interface UseSourceCardsProps {
  collection?: Collection | null;
  cardRequests: Record<string, Request[]>;
  requests: Request[];
  filter: FilterTypes | null;
}

export const useSourceCards = ({ collection, cardRequests, requests, filter }: UseSourceCardsProps) => {
  const { currentUserId } = useAuthContext();
  const { snoozedCards, cards, getCardsForCollection, collectionCards } = useCardsManager();

  const isAllCards = useIsAllCards();

  if (isAllCards) {
    return getAllStreamCards({ cards, cardRequests, collectionCards });
  }

  if (!currentUserId || !collection) {
    return [];
  }

  if (collection.type === CollectionType.User) {
    return getMyStreamCards({
      cards,
      requests,
      snoozedCards,
      userId: currentUserId,
      onlySnoozed: filter === FilterTypes.Snoozed,
    });
  }

  return getStreamCards({
    collectionCards: getCardsForCollection(collection.id),
    cards,
  });
};

import { Group, Text } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../../../../../../../../hooks';
import { AvatarRequestsGroup } from '../../../../../../../AvatarRequestsGroup';
import { UnableRequestsProps } from './types';

export const UnableRequests: FC<UnableRequestsProps> = ({ unableRequests }) => {
  const { getUser } = useUsersManager();

  const unableRequestsWithUsers = unableRequests.map((request) => ({
    ...request,
    user: getUser(request.responder_id),
  }));

  if (unableRequestsWithUsers.length === 0) {
    return null;
  }

  return (
    <Group gap={8}>
      <Text>Unable to do </Text>
      <AvatarRequestsGroup requests={unableRequestsWithUsers} />
    </Group>
  );
};

import { AppShell } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { CollectionModalContextProvider } from '../contexts';
import { AuthGuard } from '../guards';
import { AdditionalNavBar, MainNavbar } from './components';
import { SIDEBAR_GROUPS_WIDTH, SIDEBAR_WIDTH } from './const';
import { useNavigationGroups } from './hooks';
import { isGroupActive } from './utils';

export const NavbarLayout: FC = () => {
  const { pathname } = useLocation();
  const { isLoading, navigationGroups } = useNavigationGroups();

  const currentGroup = navigationGroups.find(({ link }) => isGroupActive(pathname, link));
  const shouldShowAdditionalSidebar = Boolean(currentGroup?.links);

  const [isSidebarOpen, { open, close }] = useDisclosure(shouldShowAdditionalSidebar);

  const width = isSidebarOpen ? SIDEBAR_WIDTH : SIDEBAR_GROUPS_WIDTH;

  const handleLinkClick = (path: string) => {
    const isNewPath = path !== pathname;

    if (isNewPath || !shouldShowAdditionalSidebar) {
      return;
    }

    if (isSidebarOpen) {
      close();
    } else {
      open();
    }
  };

  useEffect(() => {
    if (shouldShowAdditionalSidebar) {
      open();
    } else {
      close();
    }
  }, [close, open, pathname, shouldShowAdditionalSidebar]);

  if (isLoading) {
    return null;
  }

  return (
    <AuthGuard>
      <CollectionModalContextProvider>
        <AppShell p="0px" navbar={{ width, breakpoint: 'sm' }}>
          <AppShell.Navbar>
            <MainNavbar navigationGroups={navigationGroups} onLinkClick={handleLinkClick}>
              {isSidebarOpen && currentGroup && <AdditionalNavBar currentGroup={currentGroup} onCollapse={close} />}
            </MainNavbar>
          </AppShell.Navbar>
          <AppShell.Main>
            <div
              style={{
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: `${width}px`,
                right: '0',
                height: '100%',
                backgroundColor: '#f4f4f4',
              }}
            >
              <Outlet />
            </div>
          </AppShell.Main>
        </AppShell>
      </CollectionModalContextProvider>
    </AuthGuard>
  );
};

import { RequestResponseTypes } from '../../../../../../../../../../../../../../data';

export const ResponseToLabel = {
  [RequestResponseTypes.Done]: 'Mark as done',
  [RequestResponseTypes.Unable]: 'Unable to do',
  [RequestResponseTypes.InProgress]: 'In progress',
};

export const ActionResponse: (keyof typeof ResponseToLabel)[] = [
  RequestResponseTypes.Done,
  RequestResponseTypes.Unable,
  RequestResponseTypes.InProgress,
];

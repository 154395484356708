import { Button, Group } from '@mantine/core';
import { IconArchive } from '@tabler/icons-react';
import { FC, useCallback } from 'react';

import { useCardsManager } from '../../../../../../../../../../../../hooks';
import { ArchiveCardProps } from './types';

export const ArchiveCard: FC<ArchiveCardProps> = ({ card }) => {
  const { updateCard } = useCardsManager();

  const handleArchiveCard = useCallback(async () => {
    await updateCard({
      ...card,
      state: 'archived',
    });
  }, [card, updateCard]);

  const handleUnarchiveCard = useCallback(async () => {
    await updateCard({
      ...card,
      state: 'open',
    });
  }, [card, updateCard]);

  if (card.state === 'archived') {
    return (
      <Button variant="light" onClick={handleUnarchiveCard}>
        <Group gap="4px">
          <IconArchive size={14} />
          Unarchive Card
        </Group>
      </Button>
    );
  }

  return (
    <Button variant="light" onClick={handleArchiveCard} disabled={card.state !== 'open'}>
      <Group gap="4px">
        <IconArchive size={14} />
        Archive Card
      </Group>
    </Button>
  );

  return null;
};

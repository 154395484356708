import { ActionIcon, Table, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { FC } from 'react';

import { useWebhooksManager } from '../../../../hooks';
import { useRemoveWebhook } from './hooks';

export const WebhooksTable: FC = () => {
  const { webhooks } = useWebhooksManager();

  const handleRemoveWebhook = useRemoveWebhook();

  return (
    <Table striped highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Name</Table.Th>
          <Table.Th>Url</Table.Th>
          <Table.Th>Data</Table.Th>
          <Table.Th>Scope</Table.Th>
          <Table.Th>Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {webhooks.map(({ id, name, url, data, scope }) => (
          <Table.Tr key={url}>
            <Table.Td>
              <Text>{name}</Text>
            </Table.Td>
            <Table.Td>
              <Text>{url}</Text>
            </Table.Td>
            <Table.Td>
              <Text>{data}</Text>
            </Table.Td>
            <Table.Td>
              <Text>{scope}</Text>
            </Table.Td>
            <Table.Td>
              <ActionIcon variant="transparent" onClick={() => handleRemoveWebhook(id)}>
                <IconTrash />
              </ActionIcon>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

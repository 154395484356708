import { useLocation, useParams } from 'react-router-dom';

import { getFavoritesRoute } from '../../../../utils';

export const useIsFavorites = () => {
  const { accountId } = useParams();
  const { pathname } = useLocation();

  const isFavorites = Boolean(accountId && getFavoritesRoute(accountId) === pathname);

  return isFavorites;
};

import { Request, RequestResponseTypes, RequestTypes, User } from '../../../../../../../../data';

interface GroupRequestsByTopicResponse {
  openRequestsToMe: Request[];
  waitingForRequests: Request[];
  completedRequests: Request[];
  completedApprovalRequests: Request[];
  letsMeetRequests: Request[];
  unableRequests: Request[];
  inProgressRequests: Request[];
}

export const groupRequestsByTopic = (requests: Request[], currentUser?: User) =>
  requests.reduce<GroupRequestsByTopicResponse>(
    (acc, request) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { withdrawn, response, requester_id, responder_id, request_type } = request;

      if (withdrawn) {
        return acc;
      }

      if (response === RequestResponseTypes.Empty) {
        if (responder_id === currentUser?.id) {
          acc.openRequestsToMe.push(request);
          return acc;
        }

        acc.waitingForRequests.push(request);
        return acc;
      }

      if (request_type === RequestTypes.Approval) {
        if (response !== RequestResponseTypes.LetsMeet) {
          acc.completedApprovalRequests.push(request);
          return acc;
        }
        if (responder_id === currentUser?.id || requester_id === currentUser?.id) {
          acc.letsMeetRequests.push(request);
          return acc;
        }
      }

      if (request_type === RequestTypes.Action && response === RequestResponseTypes.Unable) {
        acc.unableRequests.push(request);
        return acc;
      }

      if (requester_id === currentUser?.id && response !== RequestResponseTypes.InProgress) {
        acc.completedRequests.push(request);
        return acc;
      }

      if (response === RequestResponseTypes.InProgress) {
        acc.inProgressRequests.push(request);
        return acc;
      }

      return acc;
    },
    {
      openRequestsToMe: [],
      waitingForRequests: [],
      completedRequests: [],
      completedApprovalRequests: [],
      letsMeetRequests: [],
      unableRequests: [],
      inProgressRequests: [],
    }
  );

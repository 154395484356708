import { Button, Flex, Modal, Stack, Text } from '@mantine/core';
import { FC, useCallback } from 'react';

import { useTemplatesManager } from '../../../../hooks';
import { DeleteTemplateModalProps } from './types';

export const DeleteTemplateModal: FC<DeleteTemplateModalProps> = ({ templateId, isOpened, onClose }) => {
  const { deleteTemplate } = useTemplatesManager();

  const handleRemoveTemplate = useCallback(async () => {
    if (!templateId) {
      return;
    }

    await deleteTemplate(templateId);
    onClose();
  }, [templateId, deleteTemplate, onClose]);

  return (
    <Modal
      onClick={(e) => {
        e.stopPropagation();
      }}
      opened={isOpened}
      onClose={onClose}
      title="Warning: This cannot be undone!"
    >
      <Stack>
        <Text size="sm">Please confirm that you want to delete this template forever.</Text>
        <Flex justify="flex-end" gap="12">
          <Button onClick={onClose} color="grey" c="black" variant="outline">
            Cancel
          </Button>
          <Button onClick={handleRemoveTemplate} color="blue" variant="filled">
            Delete forever
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
};

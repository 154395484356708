import { FC } from 'react';

import {
  CompletedApprovals,
  CompletedRequests,
  InProgressRequests,
  LetsMeetRequests,
  RequestsFrom,
  UnableRequests,
  WaitingFor,
} from './components';
import { RequestStatusesProps } from './types';
import { groupRequestsByTopic } from './utils';

export const RequestStatuses: FC<RequestStatusesProps> = ({ requests, currentUser, hasNudge }) => {
  const {
    openRequestsToMe,
    waitingForRequests,
    completedRequests,
    completedApprovalRequests,
    letsMeetRequests,
    unableRequests,
    inProgressRequests,
  } = groupRequestsByTopic(requests, currentUser);

  return (
    <>
      <RequestsFrom openRequestsToMe={openRequestsToMe} hasNudge={hasNudge} />
      <WaitingFor waitingForRequests={waitingForRequests} />
      <CompletedRequests completedRequests={completedRequests} />
      <CompletedApprovals completedApprovals={completedApprovalRequests} />
      <UnableRequests unableRequests={unableRequests} />
      <InProgressRequests inProgressRequests={inProgressRequests} />
      <LetsMeetRequests letsMeetRequests={letsMeetRequests} />
    </>
  );
};

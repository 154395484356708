import { Collection, CollectionType } from '../../../../data';
import {
  getAllCardsStreamRoute,
  getFavoritesRoute,
  getMeetingRoute,
  getStreamRoute,
  getVaultRoute,
} from '../../../../utils';
import { NavGroup, NavItem } from '../../../types';

const generateStreamItems = (accountId: string, streams?: Collection[]): NavItem[] =>
  streams?.map(({ id, title, icon }) => ({ link: getStreamRoute(accountId, id), label: title, icon })) ?? [];

const generateMeetingItems = (accountId: string, meetings?: Collection[]): NavItem[] =>
  meetings?.map(({ id, title, icon }) => ({ link: getMeetingRoute(accountId, id), label: title, icon })) ?? [];

export const generateNavGroups = (accountId: string, streams?: Collection[], meetings?: Collection[]): NavGroup[] => {
  const streamLinks = generateStreamItems(accountId, streams);
  const meetingLinks = generateMeetingItems(accountId, meetings);

  return [
    {
      label: 'Favorites',
      icon: 'star',
      link: getFavoritesRoute(accountId),
    },
    {
      label: 'Streams',
      icon: 'zeppelin',
      link: getAllCardsStreamRoute(accountId),
      links: streamLinks,
      collectionType: CollectionType.Stream,
    },
    {
      label: 'Meetings',
      icon: 'meeting',
      link: meetingLinks[0]?.link ?? '/meeting/',
      links: meetingLinks,
      collectionType: CollectionType.Meeting,
    },
    {
      label: 'The Vault',
      icon: 'archive',
      link: getVaultRoute(accountId),
    },
  ];
};

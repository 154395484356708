import { useMemo } from 'react';

import { Filters } from '../../../../components/FilterControl/types';
import { SortTypes } from '../../../../components/Sorting/types';
import { useAuthContext } from '../../../../contexts';
import { Collection } from '../../../../data';
import { useCardsManager, useFavorites, useRequestsManager } from '../../../../hooks';
import { StreamCategory } from '../../../../types';
import { getCardRequestsMap, sortByDeadline, sortCards } from '../../../../utils';
import { useSourceCards } from './hooks';
import { filterCards, sortByPriority } from './utils';

interface UseStreamCategoriesProps {
  cardRequests: ReturnType<typeof getCardRequestsMap>;
  collection?: Collection | null;
  filters: Filters;
}

export const useStreamCategories = ({
  cardRequests,
  collection,
  filters,
}: UseStreamCategoriesProps): StreamCategory[] => {
  const { requests } = useRequestsManager();
  const { currentUserId } = useAuthContext();
  const { nudgedCards } = useCardsManager();
  const { favoriteCards } = useFavorites();

  const { sortField, sortOrder, searchQuery, filter } = filters;

  const sourceCards = useSourceCards({
    collection,
    cardRequests,
    requests,
    filter,
  });

  return useMemo(() => {
    if (!currentUserId) {
      return [];
    }

    const filteredCards = filterCards({
      sourceCards,
      searchQuery,
      filter,
      cardRequests,
      userId: currentUserId,
      favoriteCards,
    });

    if (filteredCards.length === 0) {
      return [];
    }

    switch (sortField) {
      case SortTypes.Priority:
        return sortByPriority({
          cards: filteredCards,
          nudged: nudgedCards,
          cardRequests,
          userId: currentUserId,
          sortOrder,
        });
      case SortTypes.NextDeadline:
        return sortByDeadline({ cards: filteredCards, cardRequests, sortOrder });
      default:
        return sortCards({ cards: filteredCards, sortField, sortOrder });
    }
  }, [cardRequests, currentUserId, filter, nudgedCards, searchQuery, sortField, sortOrder, sourceCards, favoriteCards]);
};

import { Button, Table, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { FC, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { UserAvatar } from '../../../../components/UserAvatar';
import { getTemplateRoute } from '../../../../utils';
import { DeleteTemplateModal } from '../DeleteTemplateModal';
import { TemplatesListProps } from './types';

export const TemplatesList: FC<TemplatesListProps> = ({ templates }) => {
  const navigate = useNavigate();
  const { accountId } = useParams();

  const [templateId, setTemplateId] = useState<string>();
  const [deleteTemplateOpened, deleteTemplateHandlers] = useDisclosure(false);

  const onEditTemplate = useCallback(
    (id: string) => {
      if (!accountId) {
        return;
      }
      navigate(getTemplateRoute(accountId, id));
    },
    [accountId, navigate]
  );

  const handleOpenDeleteTemplateModal = useCallback(
    (id: string) => {
      setTemplateId(id);
      deleteTemplateHandlers.open();
    },
    [deleteTemplateHandlers]
  );

  return (
    <>
      <DeleteTemplateModal
        templateId={templateId}
        isOpened={deleteTemplateOpened}
        onClose={deleteTemplateHandlers.close}
      />
      <Table striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Creator</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {templates.map((template) => (
            <Table.Tr key={template.id}>
              <Table.Td>
                <Text>{template.name}</Text>
              </Table.Td>
              <Table.Td>
                <UserAvatar user_id={template.creator_id} displaynameonly />
              </Table.Td>
              <Table.Td>
                <Button onClick={() => onEditTemplate(template.id)}>
                  <IconEdit size={16} style={{ marginRight: '4px' }} />
                  Edit
                </Button>
                <Button onClick={() => handleOpenDeleteTemplateModal(template.id)} ml={8}>
                  <IconTrash size={16} style={{ marginRight: '4px' }} />
                  Delete
                </Button>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </>
  );
};

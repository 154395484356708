import { ActionIcon, Box, Center, Group, Menu, px, Stack, Text, Tooltip } from '@mantine/core';
import { IconPhoto, IconSettings, IconTrash } from '@tabler/icons-react';
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AddCardForm } from '../../../../components/AddCardForm';
import { BackgroundImage } from '../../../../components/BackgroundImage';
import { PageHeader } from '../../../../components/PageHeader';
import { useCollectionModalContext } from '../../../../contexts';
import { CollectionType } from '../../../../data';
import { useCollectionsManager, useRemoveCollection } from '../../../../hooks';
import { getMeetingRoute, getNewRandomImage } from '../../../../utils';
import { MeetingPageProps } from './types';

export const MeetingPageHeader: FC<MeetingPageProps> = ({ collection }) => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const { openCollectionModal } = useCollectionModalContext();
  const { updateCollection } = useCollectionsManager();

  const onDeleteMeeting = useCallback(() => {
    if (!accountId) {
      return;
    }
    navigate(getMeetingRoute(accountId), { replace: true });
  }, [accountId, navigate]);

  const handleRemoveMeeting = useRemoveCollection(collection, onDeleteMeeting);

  const handleEditMeeting = useCallback(() => {
    openCollectionModal(CollectionType.Meeting, collection);
  }, [collection, openCollectionModal]);

  const handleNewRandomImage = useCallback(() => {
    if (collection !== undefined && collection.id !== '') {
      const tmp = {
        ...collection,
        header: getNewRandomImage(),
      };
      updateCollection(tmp);
    }
  }, [collection, updateCollection]);

  return (
    <BackgroundImage src={collection.header}>
      <Stack>
        <Group grow justify="apart">
          <PageHeader>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Tooltip label="Meeting Config" withArrow>
                  <ActionIcon variant="filled" color="gray">
                    <IconSettings size={px('1rem')} />
                  </ActionIcon>
                </Tooltip>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Meeting Configuration</Menu.Label>
                <Menu.Item leftSection={<IconSettings size={14} />} onClick={handleEditMeeting}>
                  Edit meeting
                </Menu.Item>
                <Menu.Item leftSection={<IconPhoto size={14} />} onClick={handleNewRandomImage}>
                  New random image
                </Menu.Item>
                <Menu.Item leftSection={<IconTrash size={14} />} onClick={handleRemoveMeeting}>
                  Delete meeting
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </PageHeader>
        </Group>
        <Stack>
          <Stack gap={0}>
            <Center>
              <Text fz="50px" mt="-10px" pt="0" mb="0" pb="0" style={{ textShadow: '0px 0px 12px white' }}>
                {collection.title}
              </Text>
            </Center>
            {collection.description && (
              <Center>
                <Text
                  style={{
                    background: 'rgb(255 255 255 / 30%)',
                    backdropFilter: 'blur(10px) saturate(100%)',
                    padding: '8px 10px',
                    borderRadius: '8px',
                  }}
                >
                  {collection.description}
                </Text>
              </Center>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Box pl={12} pb={12} pt={12}>
        <AddCardForm collection_id={collection.id} />
      </Box>
    </BackgroundImage>
  );
};

import { ActionIcon, Group, px, Text, TextInput } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { FC, useCallback, useEffect, useState } from 'react';

import { EditableTitleProps } from './types';

export const EditableTitle: FC<EditableTitleProps> = ({
  title,
  isEditing,
  onCancel,
  onClick,
  onSaveTitle,
  ...props
}) => {
  const [value, setValue] = useState(title);

  const handleSave = useCallback(() => {
    onSaveTitle(value);
  }, [onSaveTitle, value]);

  const handleCancel = useCallback(() => {
    setValue(title);
    onCancel();
  }, [onCancel, title]);

  useEffect(() => {
    if (!isEditing && value !== title) {
      setValue(title);
    }
  }, [isEditing, title, value]);

  if (!isEditing) {
    return (
      <Text onClick={onClick} {...props} style={{ cursor: 'pointer' }}>
        {title}
      </Text>
    );
  }

  return (
    <Group>
      <TextInput
        w={500}
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSave();
          }
        }}
      />
      <ActionIcon onClick={handleCancel}>
        <IconX size={px('1rem')} />
      </ActionIcon>
      <ActionIcon onClick={handleSave}>
        <IconCheck size={px('1rem')} />
      </ActionIcon>
    </Group>
  );
};

import { BackgroundImage, Center, Group, Stack, Text } from '@mantine/core';
import { FC } from 'react';

import { PageHeader } from '../../../../components/PageHeader';
import { TEMPLATES_DEFAULT_POSTER } from '../../../../constants/backgroundImage';
import { AddTemplateForm } from '../AddTemplateForm';

export const Header: FC = () => (
  <BackgroundImage mih={230} src={TEMPLATES_DEFAULT_POSTER}>
    <Stack>
      <PageHeader />
      <Stack pb="48px">
        <Center>
          <Text fz="50px" pt="0" mt="xl" mb="0" pb="0" style={{ textShadow: '0px 0px 12px white' }}>
            Templates
          </Text>
        </Center>
      </Stack>
    </Stack>
    <Group align="center" pt="sm" pl="sm" pr="sm" pb="sm">
      <AddTemplateForm />
    </Group>
  </BackgroundImage>
);

import { Avatar, Tooltip } from '@mantine/core';
import { FC } from 'react';

import { AvatarRequestsGroupProps } from './types';

export const AvatarRequestsGroup: FC<AvatarRequestsGroupProps> = ({ requests }) => (
  <Tooltip.Group>
    <Avatar.Group>
      {requests.map(({ id, user, comment }) => (
        <Tooltip
          key={id}
          label={comment ? `${user?.fullname}: ${comment}` : user?.fullname}
          multiline
          withArrow
          maw={320}
        >
          <Avatar size="sm" radius="xl" src={user?.avatar} alt={user?.displayname} />
        </Tooltip>
      ))}
    </Avatar.Group>
  </Tooltip.Group>
);

import { Avatar, Group, Text, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import { FC } from 'react';

import { useUsersManager } from '../../../../../../hooks';
import { getCardOwner } from '../../../../../../utils';
import classNames from './CompletedStatus.module.css';
import { CompletedStatusProps } from './types';

export const CompletedStatus: FC<CompletedStatusProps> = ({ card }) => {
  const { getUser } = useUsersManager();

  if (!card) {
    return null;
  }

  const user = getUser(getCardOwner(card));
  const completedOn = dayjs(card.tmodified).format('MMMM D, YYYY h:mm A');

  return (
    <Group classNames={{ root: classNames.completedContainer }} gap={8}>
      <Text>Completed by</Text>
      {user && (
        <Tooltip label={`Completed on ${completedOn}`} withArrow>
          <Avatar size="sm" radius="xl" src={user.avatar} alt={user.displayname} />
        </Tooltip>
      )}
    </Group>
  );
};

import { Menu, Tooltip } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { IconAlignCenter } from '@tabler/icons-react';
import { FC } from 'react';

import { AlignCenterControl, AlignLeftControl, AlignRightControl } from '../../controls';

export const TextAlignControl: FC = () => (
  <Menu shadow="md">
    <Menu.Target>
      <Tooltip label="Align text" withArrow>
        <RichTextEditor.Control>
          <IconAlignCenter stroke={1.5} size="1rem" />
        </RichTextEditor.Control>
      </Tooltip>
    </Menu.Target>

    <Menu.Dropdown>
      <RichTextEditor.ControlsGroup>
        <AlignLeftControl />
        <AlignCenterControl />
        <AlignRightControl />
      </RichTextEditor.ControlsGroup>
    </Menu.Dropdown>
  </Menu>
);

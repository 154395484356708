export const getStatusColor = (status: string) => {
  switch (status) {
    case 'open':
      return 'blue';
    case 'submitted':
      return 'orange';
    case 'execution':
      return 'cyan';
    case 'completed':
      return 'green';
    case 'archived':
      return 'black';
    default:
      return 'gray';
  }
};

export const getStatusLabel = (status: string) => {
  switch (status) {
    case 'open':
      return 'Open';
    case 'submitted':
      return 'In Approval';
    case 'execution':
      return 'In Execution';
    case 'completed':
      return 'Completed';
    case 'archived':
      return 'Archived';
    default:
      return 'ERROR';
  }
};

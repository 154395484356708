import { Card, Request, User } from '../../../../../../data';
import { getCardOwner } from '../../../../../../utils';
import { isReadyForAction } from './utils';

interface GetSuggestedActionParams {
  currentUser?: User;
  requests: Request[];
  card?: Card;
}

export const getSuggestedAction = ({ currentUser, requests, card }: GetSuggestedActionParams) => {
  if (!card || card.state === 'completed' || !currentUser?.id) {
    return null;
  }

  const isNotOwner = getCardOwner(card) !== currentUser.id;

  if (isNotOwner) {
    return null;
  }

  if (card.state === 'open' && requests.length === 0) {
    return 'Ready for 1st request';
  }

  const isReady = isReadyForAction(requests);

  if (!isReady) {
    return null;
  }

  switch (card.state) {
    case 'open':
      return 'Ready for approval?';
    case 'submitted':
      return 'Ready for execution?';
    case 'execution':
      return 'Ready to complete?';
    default:
      return null;
  }
};

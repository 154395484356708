import { useCallback, useMemo, useState } from 'react';

import { useAuthContext } from '../../../../../../../../contexts';
import { useRequestsManager } from '../../../../../../../../hooks';
import { isCompletedRequest, isOpenRequestToMe, isWaitingForRequest } from '../../../../../../../../utils';
import { FilterTabsValue } from '../../types';
import { UseFilteredRequestsProps, UseFilteredRequestsResult } from './types';

export const useFilteredRequests = ({ card }: UseFilteredRequestsProps): UseFilteredRequestsResult => {
  const { currentUserId } = useAuthContext();

  const [activeTab, setActiveTab] = useState<FilterTabsValue | null>(FilterTabsValue.All);
  const { getRequestsForCard } = useRequestsManager();

  const requests = getRequestsForCard(card.id);

  const handleTabChange = useCallback((value: string | null) => {
    setActiveTab(value as FilterTabsValue);
  }, []);

  const { all, done, toMe, waitingFor } = useMemo(
    () => ({
      waitingFor: requests.filter((request) => isWaitingForRequest(request)),
      done: requests.filter((request) => isCompletedRequest(request)),
      toMe: requests.filter((request) => isOpenRequestToMe(request, currentUserId)),
      all: requests,
    }),
    [currentUserId, requests]
  );

  const currentRequests = useMemo(() => {
    switch (activeTab) {
      case FilterTabsValue.Done:
        return done;
      case FilterTabsValue.ToMe:
        return toMe;
      case FilterTabsValue.WaitingFor:
        return waitingFor;
      case FilterTabsValue.All:
      default:
        return all;
    }
  }, [activeTab, all, done, toMe, waitingFor]);

  const filteredRequestsCount = useMemo(
    () => ({
      [FilterTabsValue.All]: all.length,
      [FilterTabsValue.Done]: done.length,
      [FilterTabsValue.ToMe]: toMe.length,
      [FilterTabsValue.WaitingFor]: waitingFor.length,
    }),
    [all.length, done.length, toMe.length, waitingFor.length]
  );

  return {
    activeTab,
    handleTabChange,
    currentRequests,
    filteredRequestsCount,
  };
};

import { FC } from 'react';

import { DeadlineBadge } from '../../../../../../components/DeadlineBadge';
import { getNearestDeadline } from '../../../../../../utils';
import { DeadlineProps } from './types';

export const Deadline: FC<DeadlineProps> = ({ card, cardRequests }) => {
  const requests = cardRequests[card.id] || [];

  const nearestDeadline = getNearestDeadline(requests);

  return <DeadlineBadge tdeadline={nearestDeadline} isComplete={false} size="xs" />;
};

import { useCallback, useMemo } from 'react';

import { FAVORITES_DEFAULT_POSTER } from '../../constants/backgroundImage';
import { useAuthContext } from '../../contexts/AuthContext';
import { CollectionType } from '../../data';
import { useCardsManager } from '../useCardsManager';
import { useCollectionsManager } from '../useCollectionsManager';

export const useFavorites = () => {
  const { getUserFavoritesCollection, addCollection } = useCollectionsManager();
  const { addCollectionCard, removeCollectionCard, collectionCards } = useCardsManager();
  const { currentUserId } = useAuthContext();

  const userFavoritesCollection = useMemo(
    () => (currentUserId ? getUserFavoritesCollection(currentUserId) : null),
    [currentUserId, getUserFavoritesCollection]
  );

  const favoriteCards = useMemo(
    () =>
      collectionCards.filter(
        ({ collection_id }) => userFavoritesCollection && collection_id === userFavoritesCollection.id
      ),
    [collectionCards, userFavoritesCollection]
  );

  const isCardFavorite = useCallback(
    (cardId: string) => favoriteCards.some(({ card_id }) => card_id === cardId),
    [favoriteCards]
  );

  const handleFavoriteCard = useCallback(
    async (cardId: string) => {
      try {
        if (userFavoritesCollection) {
          await addCollectionCard({ collectionId: userFavoritesCollection.id, cardId });
          return;
        }

        const favoritesCollection = await addCollection({
          title: 'Favorites',
          description: '',
          type: CollectionType.Favorites,
          header: FAVORITES_DEFAULT_POSTER,
          icon: 'activity',
        });

        if (favoritesCollection == null) {
          return;
        }

        await addCollectionCard({ collectionId: favoritesCollection.id, cardId });
      } catch {
        //
      }
    },
    [addCollection, addCollectionCard, userFavoritesCollection]
  );

  const handleUnfavoriteCard = useCallback(
    async (cardId: string) => {
      if (!userFavoritesCollection) {
        return;
      }

      try {
        await removeCollectionCard({ collectionId: userFavoritesCollection.id, cardId });
      } catch (error) {
        //
      }
    },
    [removeCollectionCard, userFavoritesCollection]
  );

  const handleToggleFavoriteCard = useCallback(
    async (cardId: string) => {
      if (isCardFavorite(cardId)) {
        await handleUnfavoriteCard(cardId);
      } else {
        await handleFavoriteCard(cardId);
      }
    },
    [handleFavoriteCard, handleUnfavoriteCard, isCardFavorite]
  );

  return {
    userFavoritesCollection,
    handleFavoriteCard,
    handleUnfavoriteCard,
    handleToggleFavoriteCard,
    isCardFavorite,
    favoriteCards,
  };
};

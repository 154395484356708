import { Collection, CollectionType } from '../../../../data';

export const formatOptions = (collections: Collection[], cardCollections: Collection[]) =>
  collections
    .reduce(
      (acc, c) => {
        if (c.type === CollectionType.Stream && !cardCollections.some((cc) => cc.id === c.id)) {
          acc.push({ value: c.id, label: c.title });
        }
        return acc;
      },
      [] as { value: string; label: string }[]
    )
    .sort((a, b) => a.label.localeCompare(b.label));

import { SortTypes } from '../../../../components/Sorting/types';

export interface FilterControlProps {
  filters: Filters;
  onChangeFilter: (filters: Partial<Filters>) => void;
}

export enum FilterTypes {
  ArchivedCards = 'ArchivedCards',
  CompletedCards = 'CompletedCards',
  Favorites = 'Favorites',
  CardsIOwn = 'CardsIOwn',
  CardsIContributedTo = 'CardsIContributedTo',
}
export interface Filters {
  sortField: SortTypes;
  sortOrder: boolean;
  searchQuery: string;
  filter: FilterTypes | null;
}

import { Textarea } from '@mantine/core';
import { FC, useState } from 'react';

import { ApprovalButtons, RequestButtons } from './components';
import { RequestFormProps } from './types';

const SECONDS_TO_REVERT = 30;

export const RequestForm: FC<RequestFormProps> = ({ card, request, isComplete }) => {
  const [comment, setComment] = useState('');
  const [saveTime, setSaveTime] = useState<number | null>(null);

  const shouldShowApprovalButtons = request.request_type === 'approval';

  const onSetSaveTime = () => {
    setSaveTime(Date.now());
  };

  if (!shouldShowApprovalButtons && isComplete) {
    return null;
  }

  const shouldShowUndo = isComplete && Boolean(saveTime && Date.now() - saveTime < SECONDS_TO_REVERT * 1000);
  const shouldHideEditor = isComplete || shouldShowUndo;

  return (
    <>
      {!shouldHideEditor && (
        <Textarea
          mt="4px"
          value={comment}
          onChange={(e) => {
            setComment(e.target.value?.toString() || '');
          }}
          placeholder="Optional comment related to request"
        />
      )}
      {shouldShowApprovalButtons && (
        <ApprovalButtons
          card={card}
          request={request}
          comment={comment}
          isComplete={isComplete}
          onSetSaveTime={onSetSaveTime}
          shouldShowUndo={shouldShowUndo}
        />
      )}
      {!shouldShowApprovalButtons && <RequestButtons request={request} comment={comment} />}
    </>
  );
};

import { Menu, Modal, Tooltip, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FC } from 'react';

import { useCardsManager } from '../../hooks';
import { CompleteCardForm } from '../RequestActions/components';
import { StateLabel } from '../StateLabel';
import { StateBtnProps } from './types';

const states = ['open', 'submitted', 'execution', 'completed', 'archived'];

export const StateBtn: FC<StateBtnProps> = ({ card }) => {
  const [opened, { open, close }] = useDisclosure(false);

  const { updateCard } = useCardsManager();

  const handleStateChange = async (state: string) => {
    if (state === 'completed') {
      open();
    } else {
      await updateCard({ ...card, state });
    }
  };

  return (
    <>
      <Modal
        onClick={(e) => {
          e.stopPropagation();
        }}
        size="xl"
        opened={opened}
        onClose={close}
        withCloseButton={false}
      >
        <CompleteCardForm card={card} onHide={close} />
      </Modal>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Tooltip
            onClick={(e) => {
              e.stopPropagation();
            }}
            label="Change card state"
            withArrow
          >
            <UnstyledButton>
              <StateLabel status={card.state} />
            </UnstyledButton>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Change State</Menu.Label>
          {states.map((state) => (
            <Menu.Item
              key={state}
              onClick={(e) => {
                e.stopPropagation();
                handleStateChange(state);
              }}
            >
              <StateLabel status={state} />
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

import { Button, Group } from '@mantine/core';
import { IconUserDown } from '@tabler/icons-react';
import { FC } from 'react';

import { useDelegateCardContext } from '../../../../../../../../../../../../contexts';
import { DelegateCardProps } from './types';

export const DelegateCard: FC<DelegateCardProps> = ({ card }) => {
  const { openDelegateCardModal } = useDelegateCardContext();

  const isCompleted = card.state === 'completed' || card.state === 'archived';

  return (
    <Button variant="light" disabled={isCompleted} onClick={() => openDelegateCardModal(card)}>
      <Group gap="4px">
        <IconUserDown size={14} />
        Delegate Card
      </Group>
    </Button>
  );
};

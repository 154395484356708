import { SortTypes } from '../Sorting/types';

export interface FilterControlProps {
  filters: Filters;
  onChangeFilter: (filters: Partial<Filters>) => void;
  enableFilters?: boolean;
}

export enum FilterTypes {
  Snoozed = 'Snoozed',
  Favorites = 'Favorites',
  OpenRequestsToMe = 'OpenRequestsToMe',
  OpenRequestsFromMe = 'OpenRequestsFromMe',
  NoActivityFor3Days = 'NoActivityFor3Days',
  NewInLast24Hours = 'NewInLast24Hours',
}
export interface Filters {
  sortField: SortTypes;
  sortOrder: boolean;
  searchQuery: string;
  filter: FilterTypes | null;
}

import { ActionIcon, Group, px, Select, Tooltip } from '@mantine/core';
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import { FC, useCallback } from 'react';

import { SORT_LABELS } from './const';
import classNames from './Sorting.module.css';
import { SortingProps, SortTypes } from './types';

export const Sorting: FC<SortingProps> = ({ sortField, sortOrder, onChangeFilter }) => {
  const sortOptions = Object.values(SortTypes).map((value) => ({ value, label: SORT_LABELS[value] }));

  const handleChangeSortField = useCallback(
    (value: SortTypes) => {
      onChangeFilter({ sortField: value });
    },
    [onChangeFilter]
  );

  const handleChangeSortOrder = useCallback(() => {
    onChangeFilter({ sortOrder: !sortOrder });
  }, [onChangeFilter, sortOrder]);

  return (
    <Group gap="0" align="center">
      <ActionIcon
        variant="filled"
        size={36}
        onClick={(e) => {
          e.preventDefault();
          handleChangeSortOrder();
        }}
        className={classNames.searchFilter}
      >
        {sortOrder ? (
          <Tooltip label="Sort Ascending" withArrow>
            <IconSortAscending size={px('1.125rem')} />
          </Tooltip>
        ) : (
          <Tooltip label="Sort Descending" withArrow>
            <IconSortDescending size={px('1.125rem')} />
          </Tooltip>
        )}
      </ActionIcon>
      <Tooltip label="Sort Cards" withArrow>
        <Select
          style={{ width: '150px' }}
          value={sortField}
          onChange={(value) => {
            if (value) {
              handleChangeSortField(value as SortTypes);
            }
          }}
          allowDeselect={false}
          data={sortOptions}
          classNames={{ input: classNames.searchInput }}
        />
      </Tooltip>
    </Group>
  );
};

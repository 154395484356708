import { Stack, TextInput } from '@mantine/core';
import { FC, useCallback, useState } from 'react';

import { Editor } from '../../../../../../components/Editor';
import { useAdvancedEditor, useLeaveConfirmation, useTemplatesManager } from '../../../../../../hooks';
import { TemplateEditZoneProps } from './types';

export const TemplateEditZone: FC<TemplateEditZoneProps> = ({ template }) => {
  const { name: templateName, content } = template;

  const [name, setName] = useState<string>(templateName);

  const { updateTemplate } = useTemplatesManager();
  const { editor, dirtyBody, onClear, onSubmit } = useAdvancedEditor({ content });

  useLeaveConfirmation(dirtyBody);

  const handleSave = useCallback(async () => {
    if (!editor) {
      return;
    }

    const tmp = {
      ...template,
      name,
      content: editor?.getHTML(),
    };

    await updateTemplate(tmp);

    onSubmit();
  }, [template, name, editor, updateTemplate, onSubmit]);

  const shouldDisableSave = name === templateName && !dirtyBody;

  return (
    <Stack>
      <TextInput label="Name" value={name} onChange={(e) => setName(e.target.value)} />
      <Editor editor={editor} onSave={handleSave} onClear={onClear} isDisabled={shouldDisableSave} />
    </Stack>
  );
};

import { Group } from '@mantine/core';
import { FC } from 'react';

import { CustomButton } from '../../../../../../../../../../../../../../components/CustomButton';
import { useAddToMeetingContext } from '../../../../../../../../../../../../../../contexts';
import { Card, Request } from '../../../../../../../../../../../../../../data';
import { useApproval } from './hooks';

interface ApprovalButtonsProps {
  card: Card;
  request: Request;
  comment: string;
  isComplete: boolean;
  shouldShowUndo: boolean;
  onSetSaveTime(): void;
}

export const ApprovalButtons: FC<ApprovalButtonsProps> = ({
  card,
  request,
  comment,
  isComplete,
  shouldShowUndo,
  onSetSaveTime,
}) => {
  const { openAddToMeetingModal } = useAddToMeetingContext();

  const { handleApprove, handleDeny, handleLetsMeet, handleUndo } = useApproval(request, comment, onSetSaveTime);

  if (isComplete && !shouldShowUndo) {
    return null;
  }

  if (shouldShowUndo) {
    return (
      <Group mt="12px">
        <CustomButton onClick={handleUndo}>Undo</CustomButton>
      </Group>
    );
  }

  return (
    <Group mt="12px">
      <CustomButton onClick={handleApprove}>Yes</CustomButton>
      <CustomButton onClick={handleDeny}>No</CustomButton>
      <CustomButton
        onClick={() => {
          openAddToMeetingModal(card, handleLetsMeet);
        }}
      >
        Let&apos;s Meet
      </CustomButton>
    </Group>
  );
};

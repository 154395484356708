import { Card, Request, RequestResponseTypes, SnoozedCard } from '../../../../../../../data';
import { getCardOwner, isSnoozed } from '../../../../../../../utils';
import { COMPLETED_CARD_TTL } from './constants';
import { isModifiedMoreThan } from './isModifiedMoreThan';

interface GetMyStreamCardsParams {
  cards: Card[];
  requests: Request[];
  snoozedCards: SnoozedCard[];
  userId: string;
  onlySnoozed?: boolean;
}

/**
 * Snoozed cards are cards that should be hidden from the stream for a period of time.
 * Completed cards must be hidden if they were completed more than a day ago.
 * If a completed card was un-snoozed, it must be shown.
 */
const shouldHideCard = (card: Card, userId: string, snoozedCards: SnoozedCard[]) => {
  const today = new Date().getTime();
  const snoozedCard = snoozedCards.find(({ card_id, user_id }) => card_id === card.id && user_id === userId);

  const isCompleted = card.state === 'completed';
  const isArchived = card.state === 'archived';

  if (isArchived) {
    return true;
  }

  if (!snoozedCard) {
    return isCompleted && isModifiedMoreThan(card, COMPLETED_CARD_TTL);
  }

  if (snoozedCard.tuntil > today) {
    return isCompleted ? isModifiedMoreThan(card, COMPLETED_CARD_TTL) : true;
  }

  return false;
};

export const getMyStreamCards = ({
  cards,
  requests,
  snoozedCards,
  userId,
  onlySnoozed = false,
}: GetMyStreamCardsParams) => {
  const myRequests = requests.reduce<Record<string, string>>((result, request) => {
    if (
      (request.requester_id === userId || request.responder_id === userId) &&
      request.response === RequestResponseTypes.Empty &&
      !request.withdrawn
    ) {
      result[request.card_id] = request.id;
    }
    return result;
  }, {});

  return cards.reduce<Card[]>((result, card) => {
    const isMyCard = getCardOwner(card) === userId || myRequests[card.id];

    if (isMyCard) {
      const shouldShow = onlySnoozed
        ? isSnoozed(card, userId, snoozedCards)
        : !shouldHideCard(card, userId, snoozedCards);

      return shouldShow ? [...result, card] : result;
    }

    return result;
  }, []);
};

import { FC } from 'react';

export const LogoIcon: FC = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 369.01 253.94">
    <defs>
      <linearGradient id="linear-gradient" x1="66.86" y1="95.65" x2="173.66" y2="95.65" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#fbb03b" />
        <stop offset=".62" stopColor="#f7931e" />
        <stop offset="1" stopColor="#f7781e" />
      </linearGradient>
    </defs>
    <g>
      <path
        fill="#333"
        d="m44.92,195.77v-.09c0-8.76,6.91-15.93,16.42-15.93s16.33,7.08,16.33,15.84v.09c0,8.76-6.91,15.93-16.42,15.93s-16.33-7.08-16.33-15.84Zm25.66,0v-.09c0-5.28-3.87-9.68-9.33-9.68s-9.24,4.31-9.24,9.59v.09c0,5.28,3.87,9.68,9.33,9.68s9.24-4.31,9.24-9.59Z"
      />
      <path
        fill="#333"
        d="m82.1,190.57c0-1.85,1.5-3.34,3.34-3.34s3.34,1.5,3.34,3.34v.26c1.54-1.98,3.52-3.78,6.91-3.78,5.06,0,8.01,3.34,8.01,8.76v12.19c0,1.85-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34v-10.08c0-3.17-1.5-4.8-4.05-4.8s-4.18,1.63-4.18,4.8v10.08c0,1.85-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34v-17.43Z"
      />
      <path
        fill="#333"
        d="m108.94,182.04c0-1.85,1.5-3.34,3.34-3.34s3.34,1.5,3.34,3.34v25.96c0,1.85-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34v-25.96Z"
      />
      <path
        fill="#333"
        d="m122.27,217.64c-.97-.35-2.11-1.14-2.11-2.77s1.28-2.73,2.64-2.73c.53,0,.84.09,1.1.18.57.18.92.26,1.41.26,1.19,0,1.76-.22,2.38-1.41l.22-.53-8.05-18.66c-.18-.44-.35-1.1-.35-1.54,0-1.89,1.45-3.21,3.3-3.21,1.67,0,2.64.92,3.21,2.51l5.11,13.73,4.93-13.69c.53-1.41,1.5-2.55,3.21-2.55s3.17,1.32,3.17,3.08c0,.53-.18,1.28-.31,1.58l-8.23,19.76c-1.98,4.8-3.96,6.6-7.97,6.6-1.5,0-2.55-.22-3.65-.62Z"
      />
      <path
        fill="#333"
        d="m157.6,195.77v-.09c0-8.76,6.6-15.93,16.06-15.93,4.62,0,7.75,1.23,10.3,3.12.7.53,1.32,1.5,1.32,2.69,0,1.85-1.5,3.3-3.34,3.3-.92,0-1.54-.35-2.02-.66-1.89-1.41-3.87-2.2-6.29-2.2-5.19,0-8.93,4.31-8.93,9.59v.09c0,5.28,3.65,9.68,8.93,9.68,2.86,0,4.75-.88,6.69-2.42.53-.44,1.23-.75,2.02-.75,1.72,0,3.17,1.41,3.17,3.12,0,1.06-.53,1.89-1.14,2.42-2.77,2.42-6.03,3.87-10.96,3.87-9.07,0-15.8-7-15.8-15.84Z"
      />
      <path
        fill="#333"
        d="m189.85,182.04c0-1.85,1.5-3.34,3.34-3.34s3.34,1.5,3.34,3.34v8.8c1.54-1.98,3.52-3.78,6.91-3.78,5.06,0,8.01,3.34,8.01,8.76v12.19c0,1.85-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34v-10.08c0-3.17-1.5-4.8-4.05-4.8s-4.18,1.63-4.18,4.8v10.08c0,1.85-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34v-25.96Z"
      />
      <path
        fill="#333"
        d="m215.23,199.42v-.09c0-6.78,5.46-12.28,12.81-12.28s12.72,5.41,12.72,12.19v.09c0,6.78-5.46,12.28-12.81,12.28s-12.72-5.41-12.72-12.19Zm18.92,0v-.09c0-3.48-2.51-6.51-6.2-6.51s-6.12,2.95-6.12,6.43v.09c0,3.48,2.51,6.51,6.21,6.51s6.12-2.95,6.12-6.43Z"
      />
      <path
        fill="#333"
        d="m244.63,181.86c0-1.94,1.63-3.12,3.74-3.12s3.74,1.19,3.74,3.12v.09c0,1.94-1.63,3.17-3.74,3.17s-3.74-1.23-3.74-3.17v-.09Zm.4,8.71c0-1.85,1.5-3.34,3.34-3.34s3.34,1.5,3.34,3.34v17.43c0,1.85-1.5,3.34-3.34,3.34s-3.34-1.5-3.34-3.34v-17.43Z"
      />
      <path
        fill="#333"
        d="m255.98,199.42v-.09c0-6.73,5.15-12.28,12.37-12.28,3.56,0,6.07.97,8.05,2.64.44.35,1.06,1.14,1.06,2.29,0,1.67-1.36,2.99-3.04,2.99-.88,0-1.54-.35-1.94-.66-1.19-.92-2.42-1.5-4.18-1.5-3.34,0-5.72,2.95-5.72,6.43v.09c0,3.61,2.33,6.51,5.98,6.51,1.76,0,3.12-.62,4.4-1.63.35-.26,1.01-.62,1.8-.62,1.58,0,2.82,1.28,2.82,2.86,0,.88-.4,1.58-.97,2.11-1.98,1.8-4.49,3.04-8.36,3.04-7.08,0-12.28-5.46-12.28-12.19Z"
      />
      <path
        fill="#333"
        d="m291.88,211.61c-7.08,0-12.32-4.97-12.32-12.19v-.09c0-6.73,4.8-12.28,11.66-12.28,7.88,0,11.35,6.47,11.35,11.35,0,1.94-1.36,3.21-3.12,3.21h-13.25c.66,3.04,2.77,4.62,5.76,4.62,1.85,0,3.39-.57,4.75-1.58.48-.35.92-.53,1.63-.53,1.5,0,2.6,1.14,2.6,2.64,0,.88-.4,1.54-.88,1.98-2.07,1.76-4.75,2.86-8.19,2.86Zm4.31-14.17c-.4-2.99-2.16-5.02-4.97-5.02s-4.58,1.98-5.1,5.02h10.08Z"
      />
      <path
        fill="#333"
        d="m306.18,208.84c-.7-.44-1.28-1.23-1.28-2.29,0-1.5,1.14-2.64,2.64-2.64.48,0,.97.13,1.32.35,2.24,1.41,4.49,2.11,6.38,2.11s2.82-.71,2.82-1.76v-.09c0-1.45-2.29-1.94-4.88-2.73-3.3-.97-7.04-2.51-7.04-7.09v-.09c0-4.8,3.87-7.48,8.63-7.48,2.46,0,5.02.66,7.3,1.8.97.48,1.58,1.36,1.58,2.51,0,1.5-1.19,2.64-2.68,2.64-.48,0-.79-.09-1.23-.31-1.89-.92-3.7-1.5-5.11-1.5-1.63,0-2.46.7-2.46,1.63v.09c0,1.32,2.24,1.94,4.8,2.82,3.3,1.1,7.13,2.68,7.13,7v.09c0,5.24-3.92,7.61-9.02,7.61-2.9,0-6.03-.84-8.89-2.68Z"
      />
    </g>
    <path
      fill="none"
      d="m126.82,61.66c-18.78,0-34,15.22-34,34s15.22,34,34,34c17.62,0,32.11-13.41,33.83-30.58-.06-1.13-.1-2.27-.1-3.42s.04-2.29.1-3.42c-1.71-17.17-16.2-30.58-33.83-30.58Z"
    />
    <path
      fill="#f7931e"
      d="m186.68,99.07c.06-1.13.1-2.27.1-3.42s-.04-2.29-.1-3.42c-.11,1.12-.17,2.26-.17,3.42s.06,2.29.17,3.42Z"
    />
    <path
      fill="url(#linear-gradient)"
      d="m126.82,129.65c-18.78,0-34-15.22-34-34s15.22-34,34-34c17.62,0,32.11,13.41,33.83,30.58.72-12.83,5.48-24.57,13.02-34-10.99-13.74-27.88-22.55-46.84-22.55-33.12,0-59.96,26.85-59.96,59.96s26.85,59.96,59.96,59.96c18.96,0,35.85-8.81,46.84-22.55-7.54-9.43-12.3-21.17-13.02-34-1.71,17.17-16.2,30.58-33.83,30.58Z"
    />
    <path
      fill="#f15a24"
      d="m160.64,99.07c.11-1.12.17-2.26.17-3.42s-.06-2.29-.17-3.42c-.06,1.13-.1,2.27-.1,3.42s.04,2.29.1,3.42Z"
    />
    <g>
      <path
        fill="#ec5b53"
        d="m220.5,61.66c5.96,0,11.56,1.53,16.43,4.23,5.08,2.81,11.39,1.95,15.5-2.15h0c6.07-6.07,4.71-16.32-2.8-20.5-8.62-4.8-18.56-7.54-29.13-7.54-18.96,0-35.85,8.81-46.84,22.55,7.54,9.43,12.3,21.17,13.02,34,1.71-17.17,16.2-30.58,33.83-30.58Z"
      />
      <path
        fill="#ec5b53"
        d="m220.5,129.65c-17.62,0-32.11-13.41-33.83-30.58-.72,12.83-5.48,24.57-13.02,34,10.99,13.74,27.88,22.55,46.84,22.55,10.57,0,20.51-2.74,29.13-7.54,7.51-4.18,8.87-14.42,2.8-20.5h0c-4.1-4.1-10.42-4.96-15.5-2.15-4.87,2.69-10.47,4.23-16.43,4.23Z"
      />
      <path
        fill="#c1272d"
        d="m186.51,95.65c0-1.15.06-2.29.17-3.42-.72-12.83-5.48-24.57-13.02-34-7.54,9.43-12.3,21.17-13.02,34,.11,1.12.17,2.26.17,3.42s-.06,2.29-.17,3.42c.72,12.83,5.48,24.57,13.02,34,7.54-9.43,12.3-21.17,13.02-34-.11-1.12-.17-2.26-.17-3.42Z"
      />
    </g>
    <path
      fillRule="evenodd"
      fill="#c1272d"
      d="m264.65,84.54l6.91,6.91c2.32,2.32,2.32,6.07,0,8.39l-6.91,6.91c-4.78,4.78-4.78,12.52,0,17.3h0c4.78,4.78,12.52,4.78,17.3,0l19.76-19.76c4.78-4.78,4.78-12.52,0-17.3l-19.76-19.76c-4.78-4.78-12.52-4.78-17.3,0h0c-4.78,4.78-4.78,12.52,0,17.3Z"
    />
  </svg>
);

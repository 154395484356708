import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';

import { Collection } from '../../data';
import { useCollectionsManager } from '../useCollectionsManager';

export const useRemoveCollection = (collection: Collection, callback?: () => void) => {
  const { removeCollection: removeCollectionMutation } = useCollectionsManager();

  const removeCollection = async () => {
    await removeCollectionMutation(collection.id);
    callback?.();
  };

  const openModal = () =>
    modals.openConfirmModal({
      title: 'Warning: This cannot be undone!',
      children: <Text size="sm">Please confirm that you want to delete this stream/meeting forever.</Text>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: removeCollection,
    });

  return openModal;
};

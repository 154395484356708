import { Group } from '@mantine/core';
import { FC, useCallback, useMemo } from 'react';

import { useIsMyStream } from '../../hooks';
import { Filters } from '../Filters';
import { SearchInput } from '../SearchInput';
import { Sorting } from '../Sorting';
import { FILTER_LABELS } from './const';
import classNames from './FilterControl.module.css';
import { FilterControlProps, FilterTypes } from './types';

export const FilterControl: FC<FilterControlProps> = ({ filters, onChangeFilter, enableFilters = false }) => {
  const { sortField, sortOrder, searchQuery, filter: appliedFilter } = filters;

  const isMyStream = useIsMyStream();

  const toggleFilter = (filter: FilterTypes) => {
    if (appliedFilter === filter) {
      onChangeFilter({ filter: null });
      return;
    }

    onChangeFilter({ filter });
  };

  const handleChangeSearchQuery = useCallback(
    (value: string) => {
      onChangeFilter({ searchQuery: value });
    },
    [onChangeFilter]
  );

  const isFilterActive = appliedFilter != null;

  const filterOptions = useMemo(
    () =>
      isMyStream
        ? Object.values(FilterTypes)
        : Object.values(FilterTypes).filter((value) => value !== FilterTypes.Snoozed),
    [isMyStream]
  );

  return (
    <Group gap="sm" align="center">
      <Sorting sortField={sortField} sortOrder={sortOrder} onChangeFilter={onChangeFilter} />
      <Group gap="0" align="center">
        {enableFilters && (
          <Filters
            filterOptions={filterOptions}
            appliedFilter={appliedFilter}
            isFilterActive={isFilterActive}
            toggleFilter={toggleFilter}
            filterLabels={FILTER_LABELS}
          />
        )}

        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={handleChangeSearchQuery}
          classNames={{ input: enableFilters ? classNames.searchInput : '' }}
          w={200}
        />
      </Group>
    </Group>
  );
};

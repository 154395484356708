import { User } from '../../../data';

export const getSortedUsers = (users: User[]) =>
  users.sort((a, b) => {
    // sort by name
    if (a.fullname < b.fullname) {
      return -1;
    }
    if (a.fullname > b.fullname) {
      return 1;
    }
    return 0;
  });

import { Table, Text } from '@mantine/core';
import { FC } from 'react';

import { UserAvatar } from '../../../../components/UserAvatar';
import { UsersTableProps } from './types';

export const UsersTable: FC<UsersTableProps> = ({ users }) => (
  <Table striped highlightOnHover>
    <Table.Thead>
      <Table.Tr>
        <Table.Th>Name</Table.Th>
        <Table.Th>Email</Table.Th>
        <Table.Th>About</Table.Th>
      </Table.Tr>
    </Table.Thead>
    <Table.Tbody>
      {users.map((user) => (
        <Table.Tr key={user.id}>
          <Table.Td>
            <UserAvatar user_id={user.id} />
          </Table.Td>
          <Table.Td>
            <Text>{user.email}</Text>
          </Table.Td>
          <Table.Td>
            <Text>{user.description}</Text>
          </Table.Td>
        </Table.Tr>
      ))}
    </Table.Tbody>
  </Table>
);

import { Button, Group, Modal, Select, Stack } from '@mantine/core';
import { FC, useCallback, useState } from 'react';

import { CollectionType } from '../../../../data';
import { useCardCollections, useCardsManager, useCollectionsManager } from '../../../../hooks';
import { AddToStreamModalProps } from './types';
import { formatOptions } from './utils';

export const AddToStreamModal: FC<AddToStreamModalProps> = ({ card, isOpened, onClose }) => {
  const { addCollectionCard } = useCardsManager();
  const { collections } = useCollectionsManager();

  const cardCollections = useCardCollections({ card, collectionType: CollectionType.Stream });

  const [newStream, setNewStream] = useState<null | string>('');
  const streamSet = formatOptions(collections, cardCollections);

  const handleAddToStream = useCallback(async () => {
    if (newStream !== null) {
      await addCollectionCard({ collectionId: newStream, cardId: card.id });
    }
    onClose();
  }, [newStream, addCollectionCard, card.id, onClose]);

  return (
    <Modal
      onClick={(e) => {
        e.stopPropagation();
      }}
      opened={isOpened}
      onClose={onClose}
      title="Select Stream"
      zIndex={500}
    >
      <Stack>
        <Select data={streamSet} value={newStream} onChange={(e) => setNewStream(e)} comboboxProps={{ zIndex: 501 }} />
        <Group>
          <Button disabled={newStream === null || newStream === ''} onClick={handleAddToStream}>
            Add to Stream
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

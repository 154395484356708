import { Button, Popover, Stack, Text } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { FC, useCallback, useState } from 'react';

import { getFormattedDate } from '../../utils';
import { OpenCalendarBtn, OpenSnoozeBtn, Shortcuts } from './components';
import { DatePickerProps } from './types';

export const DatePicker: FC<DatePickerProps> = ({ title, onConfirm, label, isSnooze }) => {
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const [opened, setOpened] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const setDate = (date: Date) => {
    setDateValue(date);
  };

  const handleConfirm = useCallback(() => {
    if (dateValue) {
      onConfirm(dateValue);
      setIsConfirmed(true);
      setOpened(false);
    }
  }, [dateValue, onConfirm]);

  const handleReset = useCallback(() => {
    if (isConfirmed) {
      setIsConfirmed(false);
      setOpened(false);
      return;
    }
    setDateValue(null);
    setOpened(false);
  }, [isConfirmed]);

  const formattedDate = getFormattedDate(dateValue);

  return (
    <>
      <Popover opened={opened} onClose={handleReset} width={300} position="bottom" trapFocus withArrow shadow="md">
        <Popover.Target>
          <Stack>
            {isSnooze && <OpenSnoozeBtn onClick={() => setOpened(true)} />}
            {!isSnooze && <OpenCalendarBtn onClick={() => setOpened(true)} />}
          </Stack>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack>
            <Text c="gray" ta="center">
              {title}
            </Text>
            <Shortcuts setDate={setDate} />
            <DateTimePicker
              highlightToday
              clearable
              minDate={new Date()}
              placeholder="Pick a date"
              valueFormat="MM/DD/YYYY hh:mm A"
              value={dateValue}
              popoverProps={{ withinPortal: false }}
              onChange={(date) => {
                setDate(date as Date);
              }}
            />
            <Button variant="light" onClick={handleConfirm} disabled={dateValue === null}>
              {label}
            </Button>
          </Stack>
        </Popover.Dropdown>
      </Popover>
      {!isSnooze && formattedDate && <Text>{formattedDate}</Text>}
    </>
  );
};

import { Box } from '@mantine/core';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Template } from './components';

export const TemplatePage: FC = () => {
  const { templateId } = useParams();

  if (templateId === undefined) {
    throw new Error('TemplatePage: template_id is undefined');
  }

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <Box p="sm">
        <Template templateId={templateId} />
      </Box>
    </div>
  );
};

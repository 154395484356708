import { Tooltip } from '@mantine/core';
import { RichTextEditorLabels, useRichTextEditorContext } from '@mantine/tiptap';
import { forwardRef } from 'react';

import { RichTextEditorControlBase, RichTextEditorControlBaseProps } from './components/RichTextEditorControlBase';

export interface CreateControlProps {
  label: keyof RichTextEditorLabels;
  icon: React.FC<{ style: React.CSSProperties }>;
  isActive?: { name: string; attributes?: Record<string, any> | string };
  isDisabled?: (editor: any) => boolean;
  operation: { name: string; attributes?: Record<string, any> | string };
}

export function createControl({ label, isActive, operation, icon, isDisabled }: CreateControlProps) {
  const Control = forwardRef<HTMLButtonElement, RichTextEditorControlBaseProps>((props, ref) => {
    const { editor, labels } = useRichTextEditorContext();
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
    const _label = label ? (labels[label] as string) : '';

    return (
      <Tooltip label={_label} withArrow>
        <RichTextEditorControlBase
          {...props}
          aria-label={_label}
          active={isActive?.name ? editor?.isActive(isActive.name, isActive.attributes) : false}
          ref={ref}
          onClick={() => (editor as any)?.chain().focus()[operation.name](operation.attributes).run()}
          icon={props.icon || icon}
          disabled={isDisabled?.(editor) || false}
        />
      </Tooltip>
    );
  });

  Control.displayName = `@mantine/tiptap/${label}`;

  return Control;
}

import { Button, Popover, px, Text, TextInput } from '@mantine/core';
import { IconSend } from '@tabler/icons-react';
import { FC, useState } from 'react';

import { SendBtnProps } from './types';

export const SendBtn: FC<SendBtnProps> = ({ cardDescription, isOpen, isDisabled, handleOpen, handleSubmit }) => {
  const [updatedCardDescription, setUpdatedCardDescription] = useState<string>(cardDescription);

  return (
    <Popover trapFocus opened={isOpen} onChange={handleOpen} position="left" offset={8}>
      <Popover.Target>
        <Button
          disabled={isDisabled}
          leftSection={<IconSend size={px('1rem')} />}
          onClick={() => handleSubmit(updatedCardDescription)}
        >
          Send
        </Button>
      </Popover.Target>

      <Popover.Dropdown w={500}>
        <Text size="xs" mb={8}>
          Your title is a question. Would you like to rephrase it as a statement?
          <br />
          <br />
          This makes it easier for approvers to approve your card.
          <br />
          <br />
          <strong>Example:</strong> &nbsp; Should we do X or Y? &nbsp;&nbsp; ➔ &nbsp;&nbsp; Do X on Tuesday
        </Text>
        <TextInput
          value={updatedCardDescription}
          onChange={(event) => setUpdatedCardDescription(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleSubmit(updatedCardDescription);
            }
          }}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export enum RouteNames {
  Login = '/login',
  Index = '/',
  Home = '/:accountId',
  Stream = '/:accountId/stream/:streamId?',
  Meeting = '/:accountId/meeting/:meetingId?',
  Favorites = '/:accountId/favorites',
  Vault = '/:accountId/vault',
  Card = '/:accountId/card/:cardId',
  Integrations = '/:accountId/integrations',
  Profile = '/:accountId/profile',
  Users = '/:accountId/users',
  Templates = '/:accountId/templates',
  Template = '/:accountId/templates/:templateId?',
}

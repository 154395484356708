import { Avatar, Group, px, Text, Tooltip } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { FC } from 'react';

import { OpenRequestsListProps } from './types';

export const OpenRequestsList: FC<OpenRequestsListProps> = ({ openRequests }) => {
  const openRequestsCount = openRequests.length;

  if (!openRequestsCount) {
    return null;
  }

  return (
    <Group>
      <IconAlertCircle size={px('1.5rem')} color="#EC5B53" />
      <Text>
        {openRequestsCount} open request{openRequestsCount === 1 ? '' : 's'} will be withdrawn:
      </Text>
      <Avatar.Group>
        {openRequests.map(({ user, title }) => (
          <Tooltip key={user?.id} label={`${user?.fullname}: ${title}`} multiline withArrow maw={320}>
            <Avatar
              size="sm"
              radius="xl"
              src={user?.avatar}
              alt={user?.displayname}
              style={{ boxSizing: 'content-box' }}
            />
          </Tooltip>
        ))}
      </Avatar.Group>
    </Group>
  );
};

import { Center, Group, px, Select, Stack, Text, Tooltip } from '@mantine/core';
import { IconSum } from '@tabler/icons-react';
import { FC, useMemo } from 'react';

import { AddCardForm } from '../../../../components/AddCardForm';
import { BackgroundImage } from '../../../../components/BackgroundImage';
import { FilterControl } from '../../../../components/FilterControl';
import { PageHeader } from '../../../../components/PageHeader';
import { STREAM_DEFAULT_POSTER } from '../../../../constants/backgroundImage';
import { CollectionType } from '../../../../data';
import { LayoutTypes } from '../../types';
import { LayoutSelect, StreamMenu } from './components';
import { useCollectionTitle } from './hooks';
import { StreamPageHeaderProps } from './types';

export const StreamPageHeader: FC<StreamPageHeaderProps> = ({
  collection,
  groupField,
  setGroupField,
  layoutField,
  setLayoutField,
  onFilterChanged,
  filters,
}) => {
  const headerImage = useMemo(() => collection?.header || STREAM_DEFAULT_POSTER, [collection?.header]);

  const title = useCollectionTitle({ collection });

  const canEditStream = collection && collection.type !== CollectionType.Favorites;

  return (
    <BackgroundImage src={headerImage}>
      <Stack>
        <Group grow justify="apart">
          <PageHeader>{canEditStream && <StreamMenu collection={collection} />}</PageHeader>
        </Group>
        <Stack gap="0px">
          <Center>
            <Text fz="50px" mt="-10px" pt="0" mb="0" pb="0" style={{ textShadow: '0px 0px 12px white' }}>
              {title}
            </Text>
          </Center>
          {collection?.description && (
            <Center>
              <Text
                style={{
                  background: 'rgb(255 255 255 / 30%)',
                  backdropFilter: 'blur(10px) saturate(100%)',
                  padding: '8px 10px',
                  borderRadius: '8px',
                }}
              >
                {collection.description}
              </Text>
            </Center>
          )}
        </Stack>
      </Stack>
      <Group align="center" justify="space-between" pt="sm" pl="sm" pr="sm" pb="sm">
        {collection && <AddCardForm collection_id={collection.id} />}
        <Group>
          <LayoutSelect setLayoutField={setLayoutField} />
          <Tooltip label="Card Grouping" withArrow>
            <Select
              style={{ width: '150px' }}
              disabled={layoutField === LayoutTypes.Stream}
              leftSection={<IconSum size={px('1.125rem')} />}
              value={groupField}
              onChange={(value) => {
                setGroupField(value?.toString() || 'state');
              }}
              data={[
                { value: 'state', label: 'State' },
                { value: 'creator', label: 'Creator' },
              ]}
            />
          </Tooltip>
        </Group>
        <FilterControl enableFilters onChangeFilter={onFilterChanged} filters={filters} />
      </Group>
    </BackgroundImage>
  );
};

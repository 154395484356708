import { ActionIcon, Indicator, Menu, px, Tooltip } from '@mantine/core';
import { IconCheck, IconFilter } from '@tabler/icons-react';
import { FC } from 'react';

import classNames from './Filters.module.css';
import { FiltersProps } from './types';

export const Filters: FC<FiltersProps> = ({
  filterOptions,
  appliedFilter,
  isFilterActive,
  toggleFilter,
  filterLabels,
}) => (
  <Menu shadow="md" width="fit-content" closeOnItemClick={false}>
    <Menu.Target>
      <Indicator color="red" position="top-start" disabled={!isFilterActive}>
        <Tooltip label="Filters" withArrow>
          <ActionIcon variant="filled" size={36} className={classNames.searchFilter}>
            <IconFilter size={px('1.125rem')} />
          </ActionIcon>
        </Tooltip>
      </Indicator>
    </Menu.Target>
    <Menu.Dropdown>
      <Menu.Label>Filter Cards</Menu.Label>
      {filterOptions.map((key) => (
        <Menu.Item
          key={key}
          onClick={() => toggleFilter(key)}
          leftSection={appliedFilter === key ? <IconCheck size={14} /> : null}
        >
          {filterLabels[key]}
        </Menu.Item>
      ))}
    </Menu.Dropdown>
  </Menu>
);

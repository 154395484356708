import { Text } from '@mantine/core';
import { FC } from 'react';
import ReactTimeAgo from 'react-time-ago';

interface TimeAgoProps {
  time: number;
}

export const TimeAgo: FC<TimeAgoProps> = ({ time }) => (
  <Text fz="16px" c="dark.3">
    <ReactTimeAgo date={time} locale="en-US" />
  </Text>
);

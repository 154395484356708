import { Group } from '@mantine/core';
import { FC, useMemo } from 'react';

import { useCardsManager, useRequestsManager, useUsersManager } from '../../../../hooks';
import { isNudged } from '../../../../utils';
import { CompletedStatus, RequestStatuses, SuggestedAction } from './components';
import classNames from './NextBestAction.module.css';
import { getSuggestedAction } from './utils';

interface NextBestActionProps {
  cardId: string;
}

export const NextBestAction: FC<NextBestActionProps> = ({ cardId }) => {
  const { currentUser } = useUsersManager();
  const { getRequestsForCard } = useRequestsManager();
  const { getCard, nudgedCards } = useCardsManager();

  const requests = getRequestsForCard(cardId);
  const card = getCard(cardId);
  const hasNudge = useMemo(
    () => requests.some((request) => isNudged({ card, request, nudgedCards, currentUserId: currentUser?.id })),
    [card, currentUser?.id, nudgedCards, requests]
  );

  const isCompleted = card?.state === 'completed';
  const suggestedAction = getSuggestedAction({ currentUser, requests, card });

  if (isCompleted) {
    return <CompletedStatus card={card} />;
  }

  return (
    <Group classNames={{ root: classNames.nextBestActionContainer }} gap={8}>
      <RequestStatuses requests={requests} currentUser={currentUser} hasNudge={hasNudge} />
      <SuggestedAction suggestedAction={suggestedAction} />
    </Group>
  );
};

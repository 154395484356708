import { RichTextEditor } from '@mantine/tiptap';
import { BubbleMenu } from '@tiptap/react';
import { FC } from 'react';

import {
  ControlButtons,
  ImageUpload,
  RichTextEditorLinkControl,
  TableControl,
  TemplatesControl,
  TextAlignControl,
} from './components';
import {
  BoldControl,
  BulletListControl,
  H3Control,
  HighlightControl,
  HrControl,
  ItalicControl,
  OrderedListControl,
  TaskListControl,
  UnderlineControl,
} from './controls';
import { EditorProps } from './types';

export const Editor: FC<EditorProps> = ({ editor, isDisabled, onClear, onShowHistory, onSave, isLocked = false }) => (
  <RichTextEditor
    editor={editor}
    labels={{
      h3ControlLabel: 'Header',
    }}
  >
    <RichTextEditor.Toolbar sticky>
      {!isLocked && (
        <>
          <RichTextEditor.ControlsGroup>
            <H3Control />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <BoldControl />
            <ItalicControl />
            <UnderlineControl />
            <HighlightControl />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <OrderedListControl />
            <BulletListControl />
            <TaskListControl />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <ImageUpload editor={editor} />
            <RichTextEditorLinkControl />
            <HrControl />
          </RichTextEditor.ControlsGroup>

          <TextAlignControl />
          <RichTextEditor.ControlsGroup>
            <TableControl editor={editor} />
            <TemplatesControl editor={editor} />
          </RichTextEditor.ControlsGroup>
        </>
      )}

      {onSave && (
        <ControlButtons onClear={onClear} isDisabled={isDisabled} onShowHistory={onShowHistory} onSave={onSave} />
      )}
    </RichTextEditor.Toolbar>
    <div>
      {editor && (
        <BubbleMenu editor={editor}>
          <RichTextEditor.ControlsGroup>
            <BoldControl />
            <ItalicControl />
            <UnderlineControl />
            <HighlightControl />
          </RichTextEditor.ControlsGroup>
        </BubbleMenu>
      )}

      <RichTextEditor.Content />
    </div>
  </RichTextEditor>
);

import { Button, Group, Stack } from '@mantine/core';
import { FC, useCallback } from 'react';

import { useAddToMeetingContext, useAddToStreamContext } from '../../../../../../../../../../../../contexts';
import { CollectionType } from '../../../../../../../../../../../../data';
import { useCardCollections } from '../../../../../../../../../../../../hooks';
import { Subtitle } from '../../../Subtitle';
import { StreamItem } from './components';
import { CollectionListProps } from './types';

export const CollectionList: FC<CollectionListProps> = ({ card, subtitle, collectionType }) => {
  const { openAddToStreamModal } = useAddToStreamContext();
  const { openAddToMeetingModal } = useAddToMeetingContext();

  const cardCollections = useCardCollections({ card, collectionType });

  const handleAddToStream = useCallback(() => {
    switch (collectionType) {
      case CollectionType.Stream:
        openAddToStreamModal(card);
        break;
      case CollectionType.Meeting:
        openAddToMeetingModal(card);
        break;
      default:
        break;
    }
  }, [collectionType, openAddToStreamModal, card, openAddToMeetingModal]);

  const addNewLabel = collectionType === CollectionType.Meeting ? 'Add to meeting' : 'Add to stream';

  return (
    <Stack gap="12px" pl="2px" pb="12px">
      <Group justify="space-between" align="center">
        <Subtitle>{subtitle}</Subtitle>
        <Button variant="outline" size="xs" onClick={handleAddToStream}>
          {addNewLabel}
        </Button>
      </Group>
      <Stack gap="6px" style={{ overflowY: 'auto' }}>
        {cardCollections.map((collection) => (
          <StreamItem key={collection.id} card={card} collection={collection} />
        ))}
      </Stack>
    </Stack>
  );
};

import { Stack } from '@mantine/core';
import { FC } from 'react';

import { StreamCategory } from './components';
import { StreamListProps } from './types';

export const StreamList: FC<StreamListProps> = ({ streamCategories, id }) => (
  <Stack gap="md">
    {streamCategories.map((streamCategory) => (
      <StreamCategory key={streamCategory.id} streamCategory={streamCategory} collectionId={id} />
    ))}
  </Stack>
);

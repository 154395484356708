import { Button, Divider, Flex, Group, px, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconArrowNarrowRight, IconX } from '@tabler/icons-react';
import { FC } from 'react';

import { RequestTypes } from '../../../../data';
import { useCardsManager, useRequestsManager, useUsersManager } from '../../../../hooks';
import { isQuestion } from '../../../../utils';
import { DatePicker } from '../../../DatePicker';
import { UserAvatar } from '../../../UserAvatar';
import { UsersSelect } from '../../../UsersSelect';
import { RequestTypeSelect, SendBtn } from './components';
import { FormValues, SendRequestFormProps } from './types';

export const SendRequestForm: FC<SendRequestFormProps> = ({ card, onHide }) => {
  const { id: cardId, description: cardDescription } = card;

  const { addRequest } = useRequestsManager();
  const { updateCard } = useCardsManager();
  const { currentUser, users } = useUsersManager();

  const [openedPrompt, { open, close }] = useDisclosure(false);

  const form = useForm<FormValues>({
    initialValues: {
      requestType: RequestTypes.Comment,
      requestText: '',
      requestTarget: '',
      deadline: null,
    },
    validate: {
      requestText: (value) => (value.length < 1 ? 'Invalid request' : null),
      requestTarget: (value) => (value === '' ? 'Invalid target' : null),
    },
  });

  const handleDiscard = () => {
    form.reset();
    close();
    onHide?.();
  };

  const handleSubmit = async (updatedCardDescription: string) => {
    const { requestType, requestText, requestTarget, deadline } = form.getValues();
    const shouldOpenDescriptionPrompt =
      !openedPrompt && requestType === RequestTypes.Approval && isQuestion(cardDescription);

    if (shouldOpenDescriptionPrompt) {
      open();
      return;
    }

    if (updatedCardDescription !== cardDescription) {
      await updateCard({ ...card, description: updatedCardDescription });
    }

    addRequest({ cardId, requestType, requestText, requestTarget, deadline });
    handleDiscard();
  };

  const isInvalid = !form.isValid();
  const { requestTarget, requestType } = form.getValues();

  return (
    <Stack
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ cursor: 'default' }}
    >
      <Divider label={<span style={{ fontSize: 'larger' }}>Send a request</span>} labelPosition="left" />

      <Group justify="space-between" align="flex-end">
        <Stack>
          <Group>
            <UserAvatar piconly user_id={currentUser?.id} />
            <IconArrowNarrowRight />
            <UsersSelect
              users={users}
              currentRequestTarget={requestTarget}
              setCurrentRequestTarget={(value) => form.setFieldValue('requestTarget', value)}
              width={350}
            />
            <RequestTypeSelect value={requestType} onChange={(value) => form.setFieldValue('requestType', value)} />
          </Group>
          <Group wrap="nowrap">
            <Text c="gray">Request</Text>
            <TextInput pl="5" style={{ width: '100%' }} {...form.getInputProps('requestText')} />
          </Group>
          <Flex direction="row" align="center" gap="8">
            <Text c="gray" mr="10">
              Deadline <span style={{ fontStyle: 'italic', fontSize: 'smaller' }}>(optional)</span>{' '}
            </Text>
            <DatePicker
              title="Set a deadline"
              label="Set Deadline"
              onConfirm={(date) => form.setFieldValue('deadline', date)}
            />
          </Flex>
        </Stack>
      </Group>
      <Group>
        <Group justify="flex-end">
          <SendBtn
            cardDescription={cardDescription}
            isOpen={openedPrompt}
            isDisabled={isInvalid}
            handleOpen={open}
            handleSubmit={handleSubmit}
          />
          <Button leftSection={<IconX size={px('1rem')} />} variant="transparent" onClick={handleDiscard}>
            Discard
          </Button>
        </Group>
      </Group>
    </Stack>
  );
};

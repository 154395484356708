import { isNonOpenRequest } from '../../../../../../../utils';
import { SortableCard, SortByPriorityParams } from '../types';
import { compareByDeadlineOrModified } from './compareByDeadlineOrModified';

export const sortCardsByResponseTime = ({
  cards,
  cardRequests,
  userId,
}: Pick<SortByPriorityParams, 'cards' | 'cardRequests' | 'userId'>): SortableCard[] =>
  cards
    .map((card) => {
      const { id } = card;
      const requests = cardRequests[id] ?? [];

      const tlatestModified = requests.reduce((acc, req) => {
        if (req.requester_id === userId && isNonOpenRequest(req) && req.tmodified && req.tmodified > acc) {
          return req.tmodified;
        }
        return acc;
      }, card.tmodified ?? card.tcreated);

      return { ...card, tlatestModified };
    })
    .sort(compareByDeadlineOrModified);

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { REFETCH_INTERVAL } from '../constants/query';
import { useAuthContext } from '../contexts/AuthContext';
import { CollectionType } from '../data';
import { collectionsService } from '../services';

export const useCollectionsManager = () => {
  const { isAuthenticated } = useAuthContext();
  const queryClient = useQueryClient();

  const { data: collections, isLoading } = useQuery({
    queryKey: ['collections'],
    queryFn: collectionsService.getCollections,
    enabled: !!isAuthenticated,
    initialData: [],
    retry: false,
    refetchInterval: REFETCH_INTERVAL,
  });

  const addCollectionMutation = useMutation({
    mutationFn: collectionsService.createCollection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['collections'] });
    },
  });
  const updateCollectionMutation = useMutation({
    mutationFn: collectionsService.updateCollection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['collections'] });
    },
  });
  const removeCollectionMutation = useMutation({
    mutationFn: collectionsService.removeCollection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['collections'] });
    },
  });

  const getCollection = useCallback(
    (id: string) => collections.find((collection) => collection.id === id),
    [collections]
  );

  const getUserCollection = useCallback(
    (userId: string) =>
      collections.find((collection) => collection.type === CollectionType.User && collection.creator_id === userId),
    [collections]
  );

  const getUserFavoritesCollection = useCallback(
    (userId: string) =>
      collections.find(
        (collection) => collection.type === CollectionType.Favorites && collection.creator_id === userId
      ),
    [collections]
  );

  return {
    collections,
    getCollection,
    getUserCollection,
    getUserFavoritesCollection,
    addCollection: addCollectionMutation.mutateAsync,
    updateCollection: updateCollectionMutation.mutateAsync,
    removeCollection: removeCollectionMutation.mutateAsync,
    isLoading,
  };
};

import { Stack, Title } from '@mantine/core';
import { FC } from 'react';

import { BasicCard } from '../../../BasicCard/BasicCard';
import { StreamCategoryProps } from './types';

export const StreamCategory: FC<StreamCategoryProps> = ({ streamCategory, collectionId }) => {
  const { title, cards } = streamCategory;

  if (cards.length === 0) {
    return null;
  }

  return (
    <>
      {title && (
        <Title order={4} mt={4} c="dark.4">
          {title}
        </Title>
      )}
      {cards.map((card) => (
        <Stack key={card.id}>
          <BasicCard collectionId={collectionId} cardId={card.id} />
        </Stack>
      ))}
    </>
  );
};

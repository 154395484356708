import { ActionIcon, Menu, px, Tooltip } from '@mantine/core';
import { IconPhoto, IconSettings, IconTrash, IconUsersGroup } from '@tabler/icons-react';
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCollectionModalContext } from '../../../../../../contexts';
import { CollectionType } from '../../../../../../data';
import { useCollectionsManager, useRemoveCollection } from '../../../../../../hooks';
import { getHomeRoute, getNewRandomImage } from '../../../../../../utils';
import { StreamMenuProps } from './types';

export const StreamMenu: FC<StreamMenuProps> = ({ collection }) => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const { openCollectionModal } = useCollectionModalContext();
  const { updateCollection } = useCollectionsManager();

  const onDeleteStream = useCallback(() => {
    if (!accountId) {
      return;
    }
    navigate(getHomeRoute(accountId), { replace: true });
  }, [navigate, accountId]);

  const handleRemoveStream = useRemoveCollection(collection, onDeleteStream);

  const handleEditStream = useCallback(() => {
    openCollectionModal(CollectionType.Stream, collection);
  }, [collection, openCollectionModal]);

  const handleNewRandomImage = useCallback(() => {
    if (collection !== undefined && collection.id !== '') {
      const tmp = {
        ...collection,
        header: getNewRandomImage(),
      };
      updateCollection(tmp);
    }
  }, [collection, updateCollection]);

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Tooltip label="Stream Config" withArrow>
          <ActionIcon variant="filled" color="gray">
            <IconSettings size={px('1rem')} />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Stream Configuration</Menu.Label>
        <Menu.Item leftSection={<IconSettings size={14} />} onClick={handleEditStream}>
          Edit stream
        </Menu.Item>
        <Menu.Item
          leftSection={<IconUsersGroup size={14} />}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          View members
        </Menu.Item>
        <Menu.Item leftSection={<IconPhoto size={14} />} onClick={handleNewRandomImage}>
          New random image
        </Menu.Item>
        <Menu.Item leftSection={<IconTrash size={14} />} onClick={handleRemoveStream}>
          Delete stream
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

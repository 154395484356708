import {
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconBold,
  IconHeading,
  IconHighlight,
  IconItalic,
  IconLineDashed,
  IconList,
  IconListCheck,
  IconListNumbers,
  IconUnderline,
} from '@tabler/icons-react';

import { createControl } from './utils';

export const H3Control = createControl({
  label: 'h3ControlLabel',
  icon: (props) => <IconHeading {...props} />,
  isActive: { name: 'heading', attributes: { level: 3 } },
  operation: { name: 'toggleHeading', attributes: { level: 3 } },
});

export const BoldControl = createControl({
  label: 'boldControlLabel',
  icon: (props) => <IconBold {...props} />,
  isActive: { name: 'bold' },
  operation: { name: 'toggleBold' },
});

export const ItalicControl = createControl({
  label: 'italicControlLabel',
  icon: (props) => <IconItalic {...props} />,
  isActive: { name: 'italic' },
  operation: { name: 'toggleItalic' },
});

export const UnderlineControl = createControl({
  label: 'underlineControlLabel',
  icon: (props) => <IconUnderline {...props} />,
  isActive: { name: 'underline' },
  operation: { name: 'toggleUnderline' },
});

export const HighlightControl = createControl({
  label: 'highlightControlLabel',
  icon: (props) => <IconHighlight {...props} />,
  isActive: { name: 'highlight' },
  operation: { name: 'toggleHighlight' },
});

export const BulletListControl = createControl({
  label: 'bulletListControlLabel',
  icon: (props) => <IconList {...props} />,
  isActive: { name: 'bulletList' },
  operation: { name: 'toggleBulletList' },
});

export const OrderedListControl = createControl({
  label: 'orderedListControlLabel',
  icon: (props) => <IconListNumbers {...props} />,
  isActive: { name: 'orderedList' },
  operation: { name: 'toggleOrderedList' },
});

export const TaskListControl = createControl({
  label: 'tasksControlLabel',
  icon: (props) => <IconListCheck {...props} />,
  isActive: { name: 'taskList' },
  operation: { name: 'toggleTaskList' },
});

export const HrControl = createControl({
  label: 'hrControlLabel',
  icon: (props) => <IconLineDashed {...props} />,
  operation: { name: 'setHorizontalRule' },
});

export const AlignLeftControl = createControl({
  label: 'alignLeftControlLabel',
  icon: (props) => <IconAlignLeft {...props} />,
  operation: { name: 'setTextAlign', attributes: 'left' },
});

export const AlignRightControl = createControl({
  label: 'alignRightControlLabel',
  icon: (props) => <IconAlignRight {...props} />,
  operation: { name: 'setTextAlign', attributes: 'right' },
});

export const AlignCenterControl = createControl({
  label: 'alignCenterControlLabel',
  icon: (props) => <IconAlignCenter {...props} />,
  operation: { name: 'setTextAlign', attributes: 'center' },
});

import { Group, Stack } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { FC, PropsWithChildren } from 'react';
import { Link, useParams } from 'react-router-dom';

import { LabeledIcon } from '../../../components/LabeledIcon';
import { LogoIcon } from '../../../components/LogoIcon';
import { UserAvatar } from '../../../components/UserAvatar';
import { useAuthContext } from '../../../contexts';
import { useUsersManager } from '../../../hooks';
import { getHomeRoute } from '../../../utils';
import { SIDEBAR_GROUPS_WIDTH } from '../../const';
import { NavGroup } from '../../types';
import { NavigationLink, ToolsMenu } from './components';

interface MainNavbarProps extends PropsWithChildren {
  navigationGroups: NavGroup[];
  onLinkClick(path: string): void;
}

export const MainNavbar: FC<MainNavbarProps> = ({ navigationGroups, onLinkClick, children }) => {
  const { accountId } = useParams();
  const { handleLogout } = useAuthContext();
  const { currentUser } = useUsersManager();

  if (!currentUser || !accountId) {
    return null;
  }

  return (
    <Group wrap="nowrap" h="100%" gap="0">
      <Stack
        justify="space-between"
        gap="0"
        h="100%"
        w={SIDEBAR_GROUPS_WIDTH}
        style={{ maxWidth: SIDEBAR_GROUPS_WIDTH - 1 }}
      >
        <Stack gap="xs">
          <Link to={getHomeRoute(accountId)} onClick={() => onLinkClick(getHomeRoute(accountId))}>
            <LogoIcon />
          </Link>

          <NavigationLink
            link={getHomeRoute(accountId)}
            onClick={() => onLinkClick(getHomeRoute(accountId))}
            label="My Stream"
          >
            <UserAvatar user_id={currentUser.id} piconly />
          </NavigationLink>

          {navigationGroups.map(({ link, label, icon }) => (
            <NavigationLink key={link} link={link} label={label} onClick={() => onLinkClick(link ?? '')}>
              <LabeledIcon icon={icon} stroke="1.5" />
            </NavigationLink>
          ))}
        </Stack>
        <Stack gap="0" justify="flex-end" pb="md">
          <ToolsMenu />

          <NavigationLink label="Logout" onClick={handleLogout}>
            <IconLogout stroke="1.5" />
          </NavigationLink>
        </Stack>
      </Stack>

      {children}
    </Group>
  );
};

import { Stack } from '@mantine/core';
import { FC, PropsWithChildren, useMemo } from 'react';

import { isIntermediateResponse } from '../../../../../../../../../../../../utils';
import classNames from './ResponderCard.module.css';
import { ResoponderCardProps } from './types';
import { isDeclinedResponse } from './utils';

export const ResponderCard: FC<PropsWithChildren<ResoponderCardProps>> = ({ request, children }) => {
  const isIntermediate = isIntermediateResponse(request);
  const isDeclined = isDeclinedResponse(request.response);

  const className = useMemo(() => {
    if (isDeclined || request.withdrawn) {
      return classNames.declinedResponderCard;
    }

    if (isIntermediate) {
      return classNames.intermediateResponderCard;
    }

    return classNames.responderCard;
  }, [isDeclined, request.withdrawn, isIntermediate]);

  return (
    <Stack gap="6px" className={className}>
      {children}
    </Stack>
  );
};

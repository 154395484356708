import { CollectionCard } from '../../../../../../../data';
import { COMPLETED_CARD_TTL } from './constants';
import { isModifiedMoreThan } from './isModifiedMoreThan';
import { GetSourceCardsParams } from './types';

export const getStreamCards = ({ cards, collectionCards }: Pick<GetSourceCardsParams, 'cards' | 'collectionCards'>) => {
  const cardMap: Record<string, CollectionCard> = {};

  collectionCards.forEach((collectionCard) => {
    cardMap[collectionCard.card_id] = collectionCard;
  });

  return cards.filter(
    (card) =>
      cardMap[card.id] &&
      !((card.state === 'completed' || card.state === 'archived') && isModifiedMoreThan(card, COMPLETED_CARD_TTL))
  );
};

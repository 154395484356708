import { useDisclosure } from '@mantine/hooks';
import { FC, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';

import { Card } from '../../data';
import { AddToMeetingModal } from './components';
import { AddToMeetingContext } from './context';

export const AddToMeetingContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [card, setCard] = useState<Card>();
  const callback = useRef<() => void>();

  const openAddToMeetingModal = useCallback(
    (cardToOpen: Card, onSuccess?: () => void) => {
      setCard(cardToOpen);

      open();
      callback.current = onSuccess;
    },
    [open]
  );

  const value = useMemo(
    () => ({
      openAddToMeetingModal,
    }),
    [openAddToMeetingModal]
  );

  return (
    <>
      <AddToMeetingContext.Provider value={value}>{children}</AddToMeetingContext.Provider>

      {card && <AddToMeetingModal card={card} isOpened={opened} onClose={close} onAddToMeeting={callback.current} />}
    </>
  );
};

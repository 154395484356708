import { Request, RequestResponseTypes } from '../data';
import { api } from './api';

export const requestsService = {
  getRequests: async () => {
    const response = await api.get<Request[]>('/requests');

    return response.data;
  },
  createRequest: async (request: CreateRequestParams) => {
    const response = await api.post<Request>('/requests', request);

    return response.data;
  },
  updateRequest: async ({ id, ...request }: UpdateRequestParams) => {
    const response = await api.put<Request>(`/requests/${id}`, request);

    return response.data;
  },
  deleteRequest: async (id: string) => {
    await api.delete(`/requests/${id}`);
  },
};

export interface CreateRequestParams {
  card_id: string;
  request_type: string;
  requester_id: string;
  responder_id: string;
  title: string;
  content: string;
  choices: string;
  response: RequestResponseTypes;
  comment: string;
  withdrawn: boolean;
  tdeadline: number | null;
}

export interface UpdateRequestParams extends CreateRequestParams {
  id: string;
}

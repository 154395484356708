import { getTaskListExtension, Link } from '@mantine/tiptap';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TaskItem from '@tiptap/extension-task-item';
import TipTapTaskList from '@tiptap/extension-task-list';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor as useTiptapEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useCallback, useEffect, useState } from 'react';
import ImageResize from 'tiptap-extension-resize-image';

import { TableCell } from '../../components/Editor/components/TableControl/components';
import { useUsersManager } from '../useUsersManager';
import { useCollaboration, useFileUpload } from './hooks';
import { UseAdvancedEditorProps, UseAdvancedEditorResponse } from './types';

export const useAdvancedEditor = ({
  useVersioning,
  content,
  options,
}: UseAdvancedEditorProps): UseAdvancedEditorResponse => {
  const { currentUser } = useUsersManager();
  const [dirtyBody, setDirtyBody] = useState(false);

  const { provider, plugins: collaborationPlugins } = useCollaboration({ useVersioning });
  const { plugins: fileUploadPlugins } = useFileUpload();

  const editor = useTiptapEditor({
    extensions: [
      Table.configure({
        resizable: true,
      }),
      TableCell,
      TableHeader,
      TableRow,
      ImageResize.configure({
        HTMLAttributes: {
          width: '200px',
        },
      }),
      Underline,
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: 'https',
        validate: (href) => /^https?:\/\//.test(href),
      }),
      getTaskListExtension(TipTapTaskList),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: 'test-item',
        },
      }),
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      StarterKit.configure({
        history: false,
      }),
      ...collaborationPlugins,
      ...fileUploadPlugins,
    ],
    content: useVersioning ? null : content,
    ...options,
  });

  useEffect(() => {
    if (editor) {
      editor.on('update', () => {
        const currentContent = editor.getHTML();
        setDirtyBody(content !== currentContent);
      });
    }

    return () => {
      editor?.off('update');
    };
  }, [editor, content]);

  const onClear = useCallback(() => {
    editor?.commands.setContent(content ?? '');
    setDirtyBody(false);
  }, [editor, content]);

  const onSubmit = useCallback(() => {
    if (useVersioning) {
      editor?.commands.saveVersion(currentUser?.id);
    }
    setDirtyBody(false);
  }, [useVersioning, editor?.commands, currentUser?.id]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    if (editor?.storage?.collabHistory?.versioningEnabled) {
      editor.commands.toggleVersioning();
    }
  }, [editor, provider]);

  useEffect(() => {
    if (!provider) {
      return () => {};
    }

    provider.on('synced', () => {
      editor?.commands.setContent(content ?? '');
    });

    return () => {
      provider.off('synced');
    };
  }, [provider, editor, content]);

  return {
    editor,
    dirtyBody,
    onClear,
    onSubmit,
    provider,
    isSynced: provider?.isSynced ?? true,
  };
};

import { FilterTypes } from '../../../../../../components/FilterControl/types';
import { applyFavoritesFilter, applySearchFilter } from '../../../../../../utils';
import { FilterCardsParams } from './types';
import { applyNewInLast24HoursFilter, applyNoActivityFor3DaysFilter, applyRequestFilter } from './utils';

export const filterCards = ({
  sourceCards,
  searchQuery,
  filter,
  cardRequests,
  userId,
  favoriteCards,
}: FilterCardsParams) => {
  const filteredBySearch = applySearchFilter(sourceCards, searchQuery);

  switch (filter) {
    case FilterTypes.OpenRequestsFromMe:
    case FilterTypes.OpenRequestsToMe:
      return applyRequestFilter(filteredBySearch, filter, cardRequests, userId);
    case FilterTypes.NewInLast24Hours:
      return applyNewInLast24HoursFilter(filteredBySearch);
    case FilterTypes.NoActivityFor3Days:
      return applyNoActivityFor3DaysFilter(filteredBySearch);
    case FilterTypes.Favorites:
      return applyFavoritesFilter(sourceCards, favoriteCards);
    default:
      return filteredBySearch;
  }
};

import { ActionIcon, Anchor, Avatar, Blockquote, Group, Modal, px, Space, Text, Title, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconHelp, IconX } from '@tabler/icons-react';
import { FC } from 'react';

export const HelpButton: FC = () => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal opened={opened} onClose={close} radius="md" size="lg" withCloseButton={false}>
        <Group justify="space-between">
          <Title>Need some help?</Title>

          <ActionIcon onClick={close} variant="transparent" color="black">
            <IconX />
          </ActionIcon>
        </Group>

        <Group align="center" gap="sm" wrap="nowrap" mt={8}>
          <Avatar src="/img/andrew-avatar.png" alt="Andrew" color="red" size="xl">
            AO
          </Avatar>
          <Blockquote color="blue" cite="– Andrew" mt="sm" p={16}>
            <p>
              We&apos;re eager to help you with anything: getting started, adding new users, adding templates,
              troubleshooting, brainstorming, bug reports, feedback... or movie recommendations.
            </p>

            <p> Reach out anytime for help, or just to chat.</p>
          </Blockquote>
        </Group>

        <Space h="sm" />

        <Text ta="center">
          <strong>Fastest & Easiest:</strong>
        </Text>
        <Text ta="center">
          <Anchor href="tel:+15104327393">+1 510-432-7393</Anchor> (text or call)
        </Text>

        <Text mt={8} ta="center">
          <strong>Also Good:</strong>
        </Text>
        <Text ta="center">
          <Anchor href="mailto:ao@onlychoices.net">ao@onlychoices.net</Anchor>
        </Text>
        <Text ta="center">
          <Anchor href="https://calendly.com/onlychoices/ao30" target="_blank">
            Book a meeting with Andrew
          </Anchor>
        </Text>
      </Modal>

      <Tooltip label="Help" withArrow>
        <ActionIcon onClick={open} variant="filled" color="gray">
          <IconHelp size={px('1rem')} />
        </ActionIcon>
      </Tooltip>
    </>
  );
};

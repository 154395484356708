import { Divider, NavLink } from '@mantine/core';
import { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { LabeledIcon } from '../../../../../components/LabeledIcon';
import { getAllCardsStreamRoute } from '../../../../../utils';

export const AllCardsLink: FC = () => {
  const { accountId } = useParams();
  const { pathname } = useLocation();

  if (!accountId) {
    return null;
  }

  return (
    <>
      <NavLink
        component={Link}
        to={getAllCardsStreamRoute(accountId)}
        active={pathname === getAllCardsStreamRoute(accountId)}
        label="All Cards"
        leftSection={<LabeledIcon icon="stream" stroke="1.5" />}
      />
      <Divider />
    </>
  );
};

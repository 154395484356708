import { Card } from '../../../../../../../data';

export const applyNewInLast24HoursFilter = (cards: Card[]) => {
  return cards.filter(({ tcreated }) => {
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setDate(twentyFourHoursAgo.getDate() - 1);

    return new Date(tcreated) > twentyFourHoursAgo;
  });
};

import { RouteNames } from '../types';

export const getHomeRoute = (accountId: string) => RouteNames.Home.replace(':accountId', accountId);
export const getStreamRoute = (accountId: string, streamId?: string) =>
  RouteNames.Stream.replace(':accountId', accountId).replace(':streamId?', streamId || '');
export const getAllCardsStreamRoute = (accountId: string) =>
  RouteNames.Stream.replace(':accountId', accountId).replace(':streamId?', 'all');
export const getMeetingRoute = (accountId: string, meetingId?: string) =>
  RouteNames.Meeting.replace(':accountId', accountId).replace(':meetingId?', meetingId || '');
export const getFavoritesRoute = (accountId: string) => RouteNames.Favorites.replace(':accountId', accountId);
export const getVaultRoute = (accountId: string) => RouteNames.Vault.replace(':accountId', accountId);
export const getCardRoute = (accountId: string, cardId: string) =>
  RouteNames.Card.replace(':accountId', accountId).replace(':cardId', cardId);
export const getIntegrationsRoute = (accountId: string) => RouteNames.Integrations.replace(':accountId', accountId);
export const getProfileRoute = (accountId: string) => RouteNames.Profile.replace(':accountId', accountId);
export const getUsersRoute = (accountId: string) => RouteNames.Users.replace(':accountId', accountId);
export const getTemplatesRoute = (accountId: string) => RouteNames.Templates.replace(':accountId', accountId);
export const getTemplateRoute = (accountId: string, templateId?: string) =>
  RouteNames.Template.replace(':accountId', accountId).replace(':templateId?', templateId || '');

import { Group, Paper, Stack, Text } from '@mantine/core';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCardsManager, useIsMyStream } from '../../hooks';
import { getCardRoute } from '../../utils';
import classNames from './BasicCard.module.css';
import { CardHeader, NextBestAction, RequestActionsComponent } from './components';
import { BasicCardProps } from './types';

export const BasicCard: FC<BasicCardProps> = ({ cardId, collectionId }) => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const { getCard } = useCardsManager();

  const card = getCard(cardId);
  const isMyStream = useIsMyStream();

  if (!card || card?.id === '') {
    return <Text>Loading...</Text>;
  }

  const onOpenCard = () => {
    if (!accountId) {
      return;
    }
    navigate(getCardRoute(accountId, card.id));
  };

  return (
    <Paper shadow="xs" p="md" className={classNames.card}>
      <Stack
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          onOpenCard();
        }}
        gap="xs"
      >
        <CardHeader card={card} isMyStream={isMyStream} collectionId={collectionId} onOpenCard={onOpenCard} />

        <Group gap="8px">
          <Text>{card.description}</Text>
        </Group>

        <NextBestAction cardId={cardId} />

        <RequestActionsComponent card={card} isMyStream={isMyStream} />
      </Stack>
    </Paper>
  );
};

import { rem } from '@mantine/core';
import { RichTextEditorControl, RichTextEditorControlProps } from '@mantine/tiptap';
import { forwardRef } from 'react';

export interface RichTextEditorControlBaseProps extends RichTextEditorControlProps {
  icon?: React.FC<{ style: React.CSSProperties }>;
}

export const RichTextEditorControlBase = forwardRef<HTMLButtonElement, RichTextEditorControlBaseProps>(
  ({ className, icon: Icon, ...others }: any, ref) => (
    <RichTextEditorControl ref={ref} {...others}>
      <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
    </RichTextEditorControl>
  )
);

import { Card, Request, SnoozedCard } from '../data';
import { isWaitingForRequest } from './isWaitingForRequest';

interface IsNudgePossibleProps {
  card?: Card;
  request: Request;
  nudgedCards: SnoozedCard[];
  currentUserId?: string | null;
}

export const isNudged = ({ card, request, nudgedCards, currentUserId }: IsNudgePossibleProps) => {
  if (!currentUserId || !card) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { responder_id } = request;

  return (
    currentUserId === responder_id &&
    nudgedCards.some((nudgedCard) => nudgedCard.card_id === card.id && nudgedCard.user_id === currentUserId) &&
    isWaitingForRequest(request)
  );
};

import { api } from './api';

export const profileService = {
  uploadAvatar: async (params: UploadAvatarParams) => {
    const { file } = params;

    const formData = new FormData();
    formData.append('file', file);

    await api.post('/profile/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export interface UploadAvatarParams {
  file: File;
}

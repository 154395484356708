import { Card, CollectionCard, Request } from '../../../../../../../data';
import { getCardOwner } from '../../../../../../../utils';

interface GetAllStreamCardsParams {
  cards: Card[];
  cardRequests: Record<string, Request[]>;
  collectionCards: CollectionCard[];
}

export const getAllStreamCards = ({ cards, cardRequests, collectionCards }: GetAllStreamCardsParams) =>
  cards.reduce<Card[]>((result, card) => {
    if (card.state === 'archived' || card.state === 'completed') {
      return result;
    }

    if (card.state === 'execution' || card.state === 'submitted') {
      return [...result, card];
    }

    if (card.state === 'open') {
      const cardOwner = getCardOwner(card);
      const hasRequests = cardRequests[card.id].some((request) => request.responder_id !== cardOwner);

      if (hasRequests) {
        return [...result, card];
      }

      const hasCollectionCard = collectionCards.filter((collectionCard) => collectionCard.card_id === card.id);
      if (hasCollectionCard.length > 1) {
        return [...result, card];
      }
    }

    return result;
  }, []);

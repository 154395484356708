import { ActionIcon, Drawer, FocusTrap, px, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDots } from '@tabler/icons-react';
import { FC } from 'react';

import { CardActions, CardDetails } from './components';
import { RightDrawerProps } from './interface';
import classNames from './RightDrawer.module.css';

export const RightDrawer: FC<RightDrawerProps> = ({ card }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Tooltip label="More" withArrow>
        <ActionIcon onClick={open}>
          <IconDots size={px('1rem')} />
        </ActionIcon>
      </Tooltip>
      <Drawer
        overlayProps={{ backgroundOpacity: 0.15 }}
        position="right"
        opened={opened}
        onClose={close}
        withCloseButton={false}
        size="400px"
        classNames={{
          body: classNames.body,
        }}
      >
        <FocusTrap.InitialFocus />
        <CardDetails card={card} />
        <CardActions card={card} />
      </Drawer>
    </>
  );
};

import { SegmentedControl } from '@mantine/core';
import { FC, useMemo } from 'react';

import { RequestTypes } from '../../../../../../data';
import { RequestTypeItem } from './components';

interface RequestTypeSelectProps {
  value: RequestTypes;
  onChange(value: RequestTypes): void;
}

export const RequestTypeSelect: FC<RequestTypeSelectProps> = ({ value, onChange }) => {
  const handleChange = (val: string) => {
    onChange(val as RequestTypes);
  };

  const data = useMemo(
    () =>
      Object.values(RequestTypes).map((requestType) => ({
        value: requestType,
        label: <RequestTypeItem requestType={requestType} />,
      })),
    []
  );

  return <SegmentedControl value={value} onChange={handleChange} data={data} />;
};

import { Combobox, Tooltip, useCombobox } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { IconTemplate } from '@tabler/icons-react';
import { Content } from '@tiptap/react';
import { FC, useCallback, useMemo, useState } from 'react';

import { useTemplatesManager } from '../../../../hooks';
import { TemplatesControlProps } from './types';
import { formatTemplates } from './utils';

export const TemplatesControl: FC<TemplatesControlProps> = ({ editor }) => {
  const templatesManager = useTemplatesManager();
  const [search, setSearch] = useState('');

  const templates = useMemo(
    () => formatTemplates(templatesManager.templates, search),
    [search, templatesManager.templates]
  );

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const handleApplyTemplate = useCallback(
    (template: Content) => {
      if (!template) {
        return;
      }
      editor?.commands.insertContent(template, {
        parseOptions: {
          preserveWhitespace: false,
        },
      });
    },
    [editor]
  );

  return (
    <Combobox
      width="fit-content"
      store={combobox}
      withinPortal
      onOptionSubmit={(val) => {
        // @ts-ignore
        handleApplyTemplate(val.value);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <Tooltip label="Insert Template (@ cursor)" withArrow>
          <RichTextEditor.Control onClick={() => combobox.toggleDropdown()}>
            <IconTemplate stroke={1.5} size="1rem" />
          </RichTextEditor.Control>
        </Tooltip>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search template"
        />
        <Combobox.Options>
          {templates?.map((item) => (
            // @ts-ignore
            <Combobox.Option value={item} key={item.id}>
              {item.label}
            </Combobox.Option>
          ))}
          {!templates?.length && <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

import { SimpleGrid, Stack } from '@mantine/core';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Card } from '../data';
import { useLayout } from '../pages/StreamPage/hooks';
import { LayoutTypes } from '../pages/StreamPage/types';
import { StreamCategory } from '../types';
import { getCardRoute } from '../utils';
import { StateLabel } from './StateLabel';
import { TinyCard } from './TinyCard';
import { UserAvatar } from './UserAvatar';

interface StreamColumnsProps {
  streamCategories: StreamCategory[];
  group: string;
}

export const StreamColumns: FC<StreamColumnsProps> = ({ streamCategories, group }) => {
  const { accountId } = useParams();
  const navigate = useNavigate();

  const onOpenCard = (card: Card) => {
    if (!accountId) {
      return;
    }
    navigate(getCardRoute(accountId, card.id));
  };

  const { columnGroups, columnData } = useLayout({
    streamCategories,
    layoutField: LayoutTypes.Columns,
    group,
  });

  return (
    <SimpleGrid cols={columnGroups.length}>
      {columnGroups.map((columnGroup: string) => (
        <Stack key={columnGroup} gap="md">
          {group === 'creator' ? <UserAvatar user_id={columnGroup} short /> : <StateLabel status={columnGroup} />}
          {columnData[columnGroup].map((card: any) => (
            <TinyCard nouser={group === 'creator'} key={card.id} card={card} onClick={() => onOpenCard(card)} />
          ))}
        </Stack>
      ))}
    </SimpleGrid>
  );
};

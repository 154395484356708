import { useRef } from 'react';

import { Card, Request, RequestResponseTypes, RequestTypes } from '../../../../../../../../../../../../../../../data';
import { useCardsManager, useRequestsManager } from '../../../../../../../../../../../../../../../hooks';

export const useApproval = (request: Request, comment: string, onSetSaveTime: () => void) => {
  const orignalResponse = useRef<RequestResponseTypes>();
  const saveTime = useRef<number>();

  const requestsManager = useRequestsManager();
  const { getCard, updateCard } = useCardsManager();

  const updateRequest = async (response: RequestResponseTypes) => {
    const tmp = { ...request, comment, response };
    await requestsManager.updateRequest(tmp);
  };

  const handleApprove = async () => {
    orignalResponse.current = request.response;
    onSetSaveTime();

    await updateRequest(RequestResponseTypes.Yes);

    const approvalRequests = requestsManager
      .getRequestsForCard(request.card_id)
      .filter(({ request_type }) => request_type === RequestTypes.Approval);
    const isAllApproved = approvalRequests
      .filter(({ id }) => id !== request.id)
      .every(
        ({ request_type, response }) => request_type === RequestTypes.Approval && response === RequestResponseTypes.Yes
      );

    if (isAllApproved) {
      const card = getCard(request.card_id);

      if (!card) {
        return;
      }

      const updatedCard: Card = { ...card, state: 'execution' };
      await updateCard(updatedCard);
    }
  };

  const handleDeny = async () => {
    orignalResponse.current = request.response;
    onSetSaveTime();

    await updateRequest(RequestResponseTypes.No);

    const approvalRequests = requestsManager
      .getRequestsForCard(request.card_id)
      .filter(({ request_type }) => request_type === RequestTypes.Approval);

    if (approvalRequests.length === 1) {
      const card = getCard(request.card_id);

      if (!card) {
        return;
      }

      const updatedCard: Card = { ...card, state: 'open' };
      await updateCard(updatedCard);
    }
  };

  const handleLetsMeet = async () => {
    orignalResponse.current = request.response;
    onSetSaveTime();

    await updateRequest(RequestResponseTypes.LetsMeet);
  };

  const handleUndo = async () => {
    const approvalRequests = requestsManager
      .getRequestsForCard(request.card_id)
      .filter(({ request_type }) => request_type === RequestTypes.Approval);
    const isAllApproved = approvalRequests
      .filter(({ id }) => id !== request.id)
      .every(
        ({ request_type, response }) => request_type === RequestTypes.Approval && response === RequestResponseTypes.Yes
      );

    await updateRequest(orignalResponse.current ?? RequestResponseTypes.Empty);
    saveTime.current = undefined;

    if (isAllApproved) {
      const card = getCard(request.card_id);

      if (!card) {
        return;
      }

      const updatedCard: Card = { ...card, state: 'open' };
      await updateCard(updatedCard);
    }
  };

  return { handleApprove, handleDeny, handleLetsMeet, handleUndo };
};

import { Avatar, Group, Text, Tooltip } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../../../../../../../../hooks';
import { getNearestDeadline } from '../../../../../../../../utils';
import { DeadlineBadge } from '../../../../../../../DeadlineBadge';
import { NudgedBadge } from '../../../../../../../NudgedBadge';
import { RequestsFromProps } from './types';

export const RequestsFrom: FC<RequestsFromProps> = ({ openRequestsToMe, hasNudge }) => {
  const { getUser } = useUsersManager();

  const openRequestsWithUsers = openRequestsToMe.map((request) => ({
    ...request,
    user: getUser(request.requester_id),
  }));
  const nearestDeadline = getNearestDeadline(openRequestsWithUsers);

  if (openRequestsWithUsers.length === 0) {
    return null;
  }

  return (
    <Group gap={8}>
      <Text>Request{openRequestsToMe.length > 1 ? 's' : ''} from: </Text>
      <Tooltip.Group openDelay={300} closeDelay={100}>
        <Avatar.Group>
          {openRequestsWithUsers.map(({ id, user, title }) => (
            <Tooltip key={id} label={`${user?.fullname}: ${title}`} multiline withArrow maw={320}>
              <Avatar size="sm" radius="xl" src={user?.avatar} alt={user?.displayname} />
            </Tooltip>
          ))}
        </Avatar.Group>
      </Tooltip.Group>
      {nearestDeadline && <DeadlineBadge tdeadline={nearestDeadline} isComplete={false} size="xs" />}
      {hasNudge && <NudgedBadge />}
    </Group>
  );
};

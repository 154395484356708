import { Text } from '@mantine/core';
import { FC } from 'react';

import { FullNameProps } from './types';

export const FullName: FC<FullNameProps> = ({ fullname }) => (
  <Text
    style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginTop: '4px',
      fontSize: '24px',
    }}
  >
    {fullname}
  </Text>
);

import { useMemo } from 'react';

import { Card, Collection, CollectionType } from '../../data';
import { useCardsManager } from '../useCardsManager';
import { useCollectionsManager } from '../useCollectionsManager';

interface UseCardCollectionsListProps {
  card: Card;
  collectionType: CollectionType;
}

export const useCardCollections = ({ card, collectionType }: UseCardCollectionsListProps) => {
  const { collectionCards } = useCardsManager();
  const { collections } = useCollectionsManager();

  const cardCollections = useMemo(
    () =>
      collectionCards.reduce<Collection[]>((acc, collectionCard) => {
        if (collectionCard.card_id !== card.id) {
          return acc;
        }

        const collection = collections.find(
          ({ id, type }) => id === collectionCard.collection_id && type === collectionType
        );
        return collection ? [...acc, collection] : acc;
      }, []),
    [card.id, collectionCards, collections, collectionType]
  );

  return cardCollections;
};

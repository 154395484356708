import { FC, useCallback } from 'react';

import { useAuthContext } from '../../../../contexts';
import { useCardsManager } from '../../../../hooks';
import { isSnoozed } from '../../../../utils';
import { DatePicker } from '../../../DatePicker';
import { RequestActions } from '../../../RequestActions';
import { UnsnoozeBtn } from './components';
import { RequestActionsProps } from './types';

export const RequestActionsComponent: FC<RequestActionsProps> = ({ card, isMyStream }) => {
  const { currentUserId } = useAuthContext();
  const { snoozedCards, snoozeCard } = useCardsManager();
  const isSnoozedCard = currentUserId && isSnoozed(card, currentUserId, snoozedCards);

  const handleSnooze = useCallback(
    (dateValue: Date) => {
      if (currentUserId) {
        snoozeCard({ card_id: card.id, tuntil: dateValue.getTime(), user_id: currentUserId });
      }
    },
    [card.id, currentUserId, snoozeCard]
  );

  return (
    <RequestActions card={card}>
      {isMyStream && (
        <>
          {!isSnoozedCard && <DatePicker isSnooze onConfirm={handleSnooze} title="Snooze until" label="Snooze" />}
          {!!isSnoozedCard && <UnsnoozeBtn cardId={card.id} />}
        </>
      )}
    </RequestActions>
  );
};

import { FilterTabsValue } from '../../types';

export const filterTabs: { tab: FilterTabsValue; label: string }[] = [
  {
    tab: FilterTabsValue.All,
    label: 'All Requests',
  },
  {
    tab: FilterTabsValue.WaitingFor,
    label: 'Waiting for',
  },
  {
    tab: FilterTabsValue.Done,
    label: 'Done',
  },
  {
    tab: FilterTabsValue.ToMe,
    label: 'To Me',
  },
];

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { REFETCH_INTERVAL } from '../constants/query';
import { useAuthContext } from '../contexts/AuthContext';
import { templatesService } from '../services';

export const useTemplatesManager = () => {
  const { isAuthenticated } = useAuthContext();
  const queryClient = useQueryClient();

  const { data: templates, isLoading } = useQuery({
    queryKey: ['templates'],
    queryFn: templatesService.getTemplates,
    enabled: !!isAuthenticated,
    initialData: [],
    retry: false,
    refetchInterval: REFETCH_INTERVAL,
  });

  const addTemplateMutation = useMutation({
    mutationFn: templatesService.createTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });

  const updateTemplateMutation = useMutation({
    mutationFn: templatesService.updateTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });

  const deleteTemplateMutation = useMutation({
    mutationFn: templatesService.deleteTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });

  const getTemplate = useCallback((id: string) => templates.find((template) => template.id === id), [templates]);

  return {
    isLoadingTemplates: isLoading,
    templates,
    isLoading,
    getTemplate,
    addTemplate: addTemplateMutation.mutateAsync,
    updateTemplate: updateTemplateMutation.mutateAsync,
    deleteTemplate: deleteTemplateMutation.mutateAsync,
  };
};

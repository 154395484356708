import { Button, Paper, TextInput } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { FC, useMemo } from 'react';

import { useWebhooksManager } from '../../../../../../../../hooks';
import { WebhookFormProps } from './types';

export const WebhookForm: FC<WebhookFormProps> = ({ onClose }) => {
  const webhooksManager = useWebhooksManager();

  const initialValues = useMemo(
    () => ({
      name: '',
      url: '',
    }),
    []
  );

  const form = useForm({
    initialValues,
    validate: {
      name: isNotEmpty('Enter a name'),
      url: isNotEmpty('Enter a url'),
    },
  });

  const handleSubmit = async ({ name, url }: { name: string; url: string }) => {
    await webhooksManager.addWebhook({ name, url, method: 'POST', scope: 'user', data: 'FULLCARDS' });
    onClose();
  };

  return (
    <Paper
      style={{
        position: 'relative',
        backgroundColor: 'var(--mantine-color-body)',
      }}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput mt="md" placeholder="Name" label="Name" {...form.getInputProps('name')} />

        <TextInput mt="md" placeholder="Url" label="Url" {...form.getInputProps('url')} />

        <Button color="blue" type="submit" mt="xl">
          Submit
        </Button>
      </form>
    </Paper>
  );
};

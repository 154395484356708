import { Group, Stack } from '@mantine/core';
import { FC } from 'react';

import { CardHeaderTimeAgo } from '../../../../../../components/CardHeaderTimeAgo';
import { FavoriteBtn } from '../../../../../../components/FavoriteBtn';
import { StateBtn } from '../../../../../../components/StateBtn';
import { UserAvatar } from '../../../../../../components/UserAvatar';
import { Card } from '../../../../../../data';
import { getCardOwner } from '../../../../../../utils';
import { CardCreator, RightDrawer } from './components';

interface FullCardHeaderProps {
  card: Card;
}

export const FullCardHeader: FC<FullCardHeaderProps> = ({ card }) => {
  const owner = getCardOwner(card);
  const creator = card.creator_id;

  return (
    <Group justify="space-between" align="start">
      <Stack>
        <UserAvatar user_id={getCardOwner(card)} />

        {owner !== creator && <CardCreator card={card} />}
      </Stack>
      <Group justify="flex-end" gap={8}>
        <CardHeaderTimeAgo card={card} />
        <FavoriteBtn cardId={card.id} />
        <StateBtn card={card} />
        <RightDrawer card={card} />
      </Group>
    </Group>
  );
};

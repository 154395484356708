import { useEffect, useState } from 'react';

import { getStateLabel } from './utils';

export const useCompleteMessage = (state: string) => {
  const [tooltipLabel, setTooltipLabel] = useState('Complete card');
  const [canComplete, setCanComplete] = useState(state === 'execution');
  const [completeMessage, setCompleteMessage] = useState('');

  const isCompletable = canComplete || state === 'execution';
  const isEarlyComplete = state === 'open' || state === 'submitted';
  const stateLabel = getStateLabel(state);

  useEffect(() => {
    if (isEarlyComplete) {
      setCompleteMessage(
        `You can continue and complete this card now, but note that its in an early stage (${stateLabel} instead of In Execution)`
      );
    } else {
      setCompleteMessage('');
    }
  }, [isEarlyComplete, stateLabel]);

  useEffect(() => {
    if (isCompletable) {
      setTooltipLabel('Complete Card');
    } else if (state === 'open' || state === 'submitted') {
      setTooltipLabel('Complete Card (click to enable early completion)');
    } else {
      setTooltipLabel('Card is completed');
    }
  }, [state, setTooltipLabel, isCompletable]);

  return {
    tooltipLabel,
    isCompletable,
    setCanComplete,
    completeMessage,
  };
};

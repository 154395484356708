export const getStateLabel = (state: string) => {
  switch (state) {
    case 'open':
      return 'Open';
    case 'submitted':
      return 'In Approval';
    default:
      return 'Open';
  }
};

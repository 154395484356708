import { Collection } from '../../../../../data';
import { useIsMyStream } from '../../../../../hooks';
import { useIsFavorites } from '../../../hooks';

interface UseCollectionTitleParams {
  collection?: Collection | null;
}

export const useCollectionTitle = ({ collection }: UseCollectionTitleParams) => {
  const isFavorites = useIsFavorites();
  const isMyStream = useIsMyStream();

  if (isMyStream) {
    return 'My Stream';
  }

  if (isFavorites) {
    return 'Favorites';
  }

  return collection?.title || 'All Cards';
};

import { RequestWithUser } from '../../types';

export const getGroupedRequests = (requests: RequestWithUser[]) =>
  requests.reduce<{
    completedApprovalRequests: RequestWithUser[];
    abortedApprovalRequests: RequestWithUser[];
    pendingApprovalRequests: RequestWithUser[];
  }>(
    (acc, request) => {
      if (request.response === 'Yes') {
        acc.completedApprovalRequests.push(request);
      } else if (request.response === 'No') {
        acc.abortedApprovalRequests.push(request);
      } else {
        acc.pendingApprovalRequests.push(request);
      }
      return acc;
    },
    {
      completedApprovalRequests: [],
      abortedApprovalRequests: [],
      pendingApprovalRequests: [],
    }
  );

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAuthContext } from '../../../../contexts';
import { useCollectionsManager, useFavorites } from '../../../../hooks';
import { useIsMyStream } from '../../../../hooks/useIsMyStream';
import { useIsFavorites } from '../useIsFavorites';

export const useCollection = () => {
  const { getUserCollection, getCollection } = useCollectionsManager();
  const { currentUserId } = useAuthContext();
  const { streamId } = useParams();
  const { userFavoritesCollection } = useFavorites();

  const isFavorites = useIsFavorites();
  const isMyStream = useIsMyStream();

  const collection = useMemo(() => {
    if (currentUserId == null) {
      return null;
    }

    if (isMyStream) {
      return getUserCollection(currentUserId);
    }

    if (isFavorites) {
      return userFavoritesCollection;
    }

    if (streamId == null) {
      return null;
    }

    return getCollection(streamId);
  }, [currentUserId, getCollection, getUserCollection, isFavorites, isMyStream, streamId, userFavoritesCollection]);

  return { collection, isMyStream, isFavorites };
};

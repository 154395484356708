export const textByRequestType: Record<string, string> = {
  approval: 'Approval',
  comment: 'Input',
  action: 'Action',
};

export const colorByRequestType: Record<string, string> = {
  approval: 'grape',
  comment: 'blue',
  action: 'yellow',
};

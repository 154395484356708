import { ActionIcon, px, Tooltip } from '@mantine/core';
import { IconAlarmSnooze } from '@tabler/icons-react';
import { FC } from 'react';

import { OpenSnoozeBtnProps } from './types';

export const OpenSnoozeBtn: FC<OpenSnoozeBtnProps> = ({ onClick }) => (
  <Tooltip
    onClick={(e) => {
      e.stopPropagation();
    }}
    label="Snooze card"
    withArrow
  >
    <ActionIcon onClick={onClick} variant="outline" radius="xl">
      <IconAlarmSnooze size={px('1rem')} />
    </ActionIcon>
  </Tooltip>
);

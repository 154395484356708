import { ActionIcon, Button, Drawer, Group, px, Stack, TextInput, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import { FC, useState } from 'react';

import { useAuthContext } from '../contexts';
import { useAdvancedEditor, useCardsManager } from '../hooks';
import { Editor } from './Editor';

interface AddCardFormProps {
  collection_id: string;
}

export const AddCardForm: FC<AddCardFormProps> = ({ collection_id }) => {
  const { currentUserId } = useAuthContext();
  const { addCard } = useCardsManager();
  const { editor } = useAdvancedEditor({ content: '' });

  const [opened, { open, close }] = useDisclosure(false);

  const [title, setTitle] = useState('');

  const clearFields = () => {
    setTitle('');
    editor?.commands.clearContent();
  };

  const handleAddCard = async (doc: string) => {
    if (!currentUserId) {
      return;
    }

    await addCard(
      {
        description: title,
        tags: '',
        state: 'open',
        document: doc,
      },
      collection_id
    );
  };

  return (
    <>
      <Tooltip label="Add new card" withArrow>
        <ActionIcon onClick={open} variant="filled" color="blue" size={36}>
          <IconPlus size={px('1.125rem')} />
        </ActionIcon>
      </Tooltip>
      <Drawer
        size="lg"
        opened={opened}
        onClose={() => {
          clearFields();
          close();
        }}
        title="Add Card to Stream"
      >
        <Stack>
          <TextInput
            label="Title"
            required
            placeholder="What is your decision point / proposal / idea? (140 char limit)"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={title.length > 140 ? 'title too long' : null}
          />
          <Editor editor={editor} />
          <Group>
            <Button
              variant="filled"
              leftSection={<IconCheck size={px('1.125rem')} />}
              disabled={title.length === 0}
              onClick={() => {
                let doc = editor?.getHTML();
                if (doc === undefined) {
                  doc = '';
                }
                handleAddCard(doc);
                clearFields();
                close();
              }}
            >
              Create Card
            </Button>
          </Group>
        </Stack>
      </Drawer>
    </>
  );
};

import { Text } from '@mantine/core';
import { FC } from 'react';

import { RequestResponseProps } from './types';

export const RequestResponse: FC<RequestResponseProps> = ({ request }) => {
  const { comment, withdrawn } = request;

  const shouldShowComment = comment !== '';

  if (!shouldShowComment || withdrawn) {
    return null;
  }

  return <Text c="dark.7">{request.comment}</Text>;
};

import { ActionIcon, Group, NavLink, px, Stack, Title } from '@mantine/core';
import { IconChevronsLeft } from '@tabler/icons-react';
import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { LabeledIcon } from '../../../components/LabeledIcon';
import { SearchInput } from '../../../components/SearchInput';
import { CollectionType } from '../../../data';
import { SIDEBAR_ITEMS_WIDTH } from '../../const';
import classNames from './AdditionalNavBar.module.css';
import { AllCardsLink, NewCollectionBtn } from './components';
import { AdditionalNavBarProps } from './types';

export const AdditionalNavBar: FC<AdditionalNavBarProps> = ({ currentGroup, onCollapse }) => {
  const { label, links: items, collectionType } = currentGroup;

  const { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredItems = items
    ?.filter(({ label: linkLabel }) => linkLabel.toLowerCase().includes(searchQuery.toLowerCase()))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    setSearchQuery('');
  }, [pathname]);

  return (
    <Stack gap="0" h="100%" miw={SIDEBAR_ITEMS_WIDTH} w={SIDEBAR_ITEMS_WIDTH} className={classNames.container}>
      <Group justify="space-between" align="center" pt={16}>
        <Title order={3} ml={16}>
          {label}
        </Title>
        <Group gap={4} mr={8}>
          {collectionType && <NewCollectionBtn collectionType={collectionType} />}
          <ActionIcon variant="transparent" onClick={onCollapse}>
            <IconChevronsLeft size={px('1.3rem')} color="grey" />
          </ActionIcon>
        </Group>
      </Group>

      <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} mt={16} px={16} />

      <Stack gap="xs" mt={16} style={{ flexGrow: 1 }}>
        {collectionType === CollectionType.Stream && <AllCardsLink />}
        {filteredItems?.map(({ label: linkLabel, link, icon }) => (
          <NavLink
            key={link}
            component={Link}
            to={link}
            active={pathname === link}
            label={linkLabel}
            leftSection={<LabeledIcon icon={icon} stroke="1.5" />}
          />
        ))}
      </Stack>
    </Stack>
  );
};

import { Button, Group } from '@mantine/core';
import { FC } from 'react';

import { ControlButtonsProps } from './types';

export const ControlButtons: FC<ControlButtonsProps> = ({ onSave, onClear, isDisabled, onShowHistory }) => (
  <Group justify="flex-end" ml="auto">
    {onShowHistory && (
      <Button style={{ width: 'fit-content' }} type="button" onClick={onShowHistory}>
        Show history
      </Button>
    )}
    <Button onClick={onClear} disabled={isDisabled}>
      Clear Changes
    </Button>
    <Button onClick={onSave} disabled={isDisabled}>
      Save
    </Button>
  </Group>
);

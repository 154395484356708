import {
  IconColumnInsertRight,
  IconColumnRemove,
  IconRowInsertBottom,
  IconRowRemove,
  IconTableOff,
  IconTablePlus,
  IconTableRow,
} from '@tabler/icons-react';
import { Editor } from '@tiptap/react';

export const controls = [
  {
    title: 'Insert table',
    icon: IconTablePlus,
    action: (editor: Editor | null) =>
      editor?.chain().focus().insertTable({ rows: 1, cols: 3, withHeaderRow: true }).run(),
  },
  {
    title: 'Add row below',
    icon: IconRowInsertBottom,
    action: (editor: Editor | null) => editor?.chain().focus().addRowAfter().run(),
  },
  {
    title: 'Add column right',
    icon: IconColumnInsertRight,
    action: (editor: Editor | null) => editor?.chain().focus().addColumnAfter().run(),
  },
  {
    title: 'Delete row',
    icon: IconRowRemove,
    action: (editor: Editor | null) => editor?.chain().focus().deleteRow().run(),
  },
  {
    title: 'Delete column',
    icon: IconColumnRemove,
    action: (editor: Editor | null) => editor?.chain().focus().deleteColumn().run(),
  },
  {
    title: 'Toggle header cell',
    icon: IconTableRow,
    action: (editor: Editor | null) => editor?.chain().focus().toggleHeaderCell().run(),
  },
  {
    title: 'Delete table',
    icon: IconTableOff,
    action: (editor: Editor | null) => editor?.chain().focus().deleteTable().run(),
  },
];

import { Card, SnoozedCard } from '../data';
import { api } from './api';

export const cardsService = {
  getCards: async () => {
    const response = await api.get<Card[]>('/cards');

    return response.data;
  },
  getSnoozedCards: async () => {
    const response = await api.get<SnoozedCard[]>('/cards/snoozed');

    return response.data;
  },
  snoozeCard: async (params: SnoozeCardParams) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { card_id } = params;

    await api.post(`/cards/${card_id}/snooze`, params);
  },
  unSnoozeCard: async (id: string) => {
    await api.delete(`/cards/${id}/snooze`);
  },
  getNudgedCards: async () => {
    const response = await api.get<SnoozedCard[]>('/cards/nudged');

    return response.data;
  },
  nudgeCard: async (params: NudgeCardParams) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { card_id } = params;

    await api.post(`/cards/${card_id}/nudge`, params);
  },
  createCard: async (card: CreateCardParams) => {
    const response = await api.post<Card>('/cards', card);

    return response.data;
  },
  updateCard: async ({ id, ...card }: UpdateCardParams) => {
    const response = await api.put<Card>(`/cards/${id}`, card);

    return response.data;
  },
  uploadImage: async (params: UploadImageParams) => {
    const { file } = params;

    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post<string>('/assets', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  },

  deleteCard: async (id: string) => {
    await api.delete(`/cards/${id}`);
  },
};

export interface SnoozeCardParams {
  card_id: string;
  user_id: string;
  tuntil: number;
}

export interface UploadImageParams {
  file: File;
}

export interface NudgeCardParams {
  card_id: string;
  user_id: string;
  tuntil: number;
}

export interface CreateCardParams {
  description: string;
  document: string;
  tags: string;
  state: string;
  owner_id?: string;
}

export interface UpdateCardParams extends CreateCardParams {
  id: string;
}

import { BackgroundImage } from '@mantine/core';
import { FC } from 'react';

import { BackgroundImageProps } from './types';

export const BackgroundImageComponent: FC<BackgroundImageProps> = ({ children, src }) => (
  <BackgroundImage
    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '230px' }}
    src={src}
  >
    {children}
  </BackgroundImage>
);

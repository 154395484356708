import { Avatar, Tooltip } from '@mantine/core';
import { FC, useMemo } from 'react';

import { useUsersManager } from '../../../../../../hooks';
import { isWaitingForRequest } from '../../../../../../utils';
import { OpenRequestsProps } from './types';

export const OpenRequests: FC<OpenRequestsProps> = ({ card, cardRequests }) => {
  const { getUser } = useUsersManager();

  const openRequestsWithUsers = useMemo(
    () =>
      cardRequests[card.id]
        .filter(({ response, withdrawn }) => isWaitingForRequest({ response, withdrawn }))
        .map((request) => ({
          ...request,
          user: getUser(request.responder_id),
        })),
    [card.id, cardRequests, getUser]
  );

  return (
    <Tooltip.Group openDelay={300} closeDelay={100}>
      <Avatar.Group>
        {openRequestsWithUsers.map(({ id, user, title }) => (
          <Tooltip key={id} label={`${user?.fullname}: ${title}`} multiline withArrow maw={320}>
            <Avatar size="sm" radius="xl" src={user?.avatar} alt={user?.displayname} style={{ borderWidth: 0 }} />
          </Tooltip>
        ))}
      </Avatar.Group>
    </Tooltip.Group>
  );
};

import { Box, Divider, Stack } from '@mantine/core';
import { FC } from 'react';

import { useAuthContext } from '../../../../../../../../../../contexts';
import { getCardOwner } from '../../../../../../../../../../utils';
import { SectionTitle } from '../SectionTitle';
import { ArchiveCard, DelegateCard, DeleteCard } from './components';
import { CardActionsProps } from './types';

export const CardActions: FC<CardActionsProps> = ({ card }) => {
  const { currentUserId } = useAuthContext();

  const isOwner = getCardOwner(card) === currentUserId;

  if (!isOwner) {
    return null;
  }

  return (
    <Box pr="16px">
      <SectionTitle>Card Actions</SectionTitle>
      <Divider my="8px" />
      <Stack gap="6px">
        <DelegateCard card={card} />
        <ArchiveCard card={card} />
        <DeleteCard card={card} />
      </Stack>
    </Box>
  );
};

export const getDocumentTitle = (isMyStream: boolean, isFavorites: boolean) => {
  if (isMyStream) {
    return 'My Stream';
  }

  if (isFavorites) {
    return 'Favorites';
  }

  return 'Streams';
};

import { Button, Group, px, Text } from '@mantine/core';
import {
  IconCalendarPin,
  IconClock24,
  IconClockHour1,
  IconClockHour3,
  IconNumber48Small,
  IconSunset,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { FC } from 'react';

import classNames from './Shortcuts.module.css';
import { ShortcutsProps } from './types';

export const Shortcuts: FC<ShortcutsProps> = ({ setDate }) => (
  <Group justify="space-between">
    <Group justify="space-between" w="100%">
      <Group gap="0">
        <Button
          className={classNames.button}
          variant="transparent"
          onClick={() => setDate(dayjs().add(1, 'hour').toDate())}
        >
          <IconClockHour1 size={px('1rem')} />
          <Text ml={4} c="gray">
            1h
          </Text>
        </Button>
      </Group>
      <Group gap="0">
        <Button
          className={classNames.button}
          variant="transparent"
          onClick={() => setDate(dayjs().add(3, 'hour').toDate())}
        >
          <IconClockHour3 size={px('1rem')} />
          <Text ml={4} c="gray">
            3h
          </Text>
        </Button>
      </Group>
      <Group gap="0">
        <Button
          className={classNames.button}
          variant="transparent"
          onClick={() => {
            const d = dayjs().hour(18).minute(0);
            setDate(d.toDate());
          }}
        >
          <IconSunset size={px('1rem')} />
          <Text ml={4} c="gray">
            EoD
          </Text>
        </Button>
      </Group>
    </Group>

    <Group justify="space-between" w="100%">
      <Group gap="0">
        <Button
          className={classNames.button}
          variant="transparent"
          onClick={() => setDate(dayjs().add(24, 'hour').toDate())}
        >
          <IconClock24 size={px('1rem')} />
          <Text ml={4} c="gray">
            24h
          </Text>
        </Button>
      </Group>
      <Group gap="0">
        <Button
          className={classNames.button}
          variant="transparent"
          onClick={() => setDate(dayjs().add(48, 'hour').toDate())}
        >
          <IconNumber48Small size={px('1rem')} />
          <Text ml={4} c="gray">
            48h
          </Text>
        </Button>
      </Group>
      <Group gap="0">
        <Button
          className={classNames.button}
          variant="transparent"
          onClick={() => setDate(dayjs().add(7, 'day').toDate())}
        >
          <IconCalendarPin size={px('1rem')} />
          <Text ml={4} c="gray">
            1w
          </Text>
        </Button>
      </Group>
    </Group>
  </Group>
);

import { useMemo } from 'react';

import { Card } from '../../../../data';
import { LayoutTypes } from '../../types';
import { stateOrder } from './const';
import { UseLayoutProps } from './types';

export const useLayout = ({ streamCategories, layoutField, group }: UseLayoutProps) => {
  const streamCards = useMemo(() => streamCategories.flatMap((category) => category.cards), [streamCategories]);

  const columnGroups: string[] = useMemo(() => {
    if (layoutField === LayoutTypes.Stream) {
      return [];
    }

    const groups = streamCards.reduce((acc: string[], card) => {
      if (group === 'state' && acc.indexOf(card.state) < 0) {
        acc.push(card.state);
      }

      if (group === 'creator' && acc.indexOf(card.creator_id) < 0) {
        acc.push(card.creator_id);
      }

      return acc;
    }, []);

    if (group !== 'state') {
      return groups;
    }

    return groups.sort((a, b) => {
      return (stateOrder[a] ?? Infinity) - (stateOrder[b] ?? Infinity);
    });
  }, [layoutField, group, streamCards]);

  const columnData: { [key: string]: Card[] } = useMemo(() => {
    if (layoutField === LayoutTypes.Stream) {
      return {};
    }

    return streamCards.reduce<Record<string, Card[]>>((acc, card) => {
      if (group === 'state') {
        if (acc[card.state] === undefined) {
          acc[card.state] = [];
        }
        acc[card.state].push(card);
      }

      if (group === 'creator') {
        if (acc[card.creator_id] === undefined) {
          acc[card.creator_id] = [];
        }
        acc[card.creator_id].push(card);
      }

      return acc;
    }, {});
  }, [layoutField, streamCards, group]);

  return {
    columnGroups,
    columnData,
  };
};

import { Request } from '../data';

export const getNearestDeadline = (requests: Request[]) =>
  requests.reduce<number | null>((acc, request) => {
    if (!request.tdeadline) {
      return acc;
    }

    if (!acc || request.tdeadline < acc) {
      return request.tdeadline;
    }

    return acc;
  }, null);

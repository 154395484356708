import { Card, Request } from '../data';
import { StreamCategory } from '../types';

interface SortByPriorityParams {
  cards: Card[];
  cardRequests: Record<string, Request[]>;
  sortOrder: boolean;
}

export const sortByDeadline = ({ cards, cardRequests, sortOrder }: SortByPriorityParams): StreamCategory[] => {
  const cardsWithSoonestRequestDeadline = cards.map((card) => {
    const { id } = card;

    const r = cardRequests[id] ?? [];
    // find open request with soonnest deadline
    const [soonestOpenRequest] = r
      .filter((req) => req.response === '' && req.tdeadline != null)
      .sort((req1, req2) => {
        const req1Deadline = req1.tdeadline || 0;
        const req2Deadline = req2.tdeadline || 0;
        return req1Deadline - req2Deadline;
      });

    return {
      ...card,
      tdeadline: soonestOpenRequest?.tdeadline ?? null,
    };
  });

  const sortedCardsByDeadline = cardsWithSoonestRequestDeadline
    .filter((card) => card.tdeadline != null)
    .sort((card1, card2) => {
      const card1Deadline = card1?.tdeadline || 0;
      const card2Deadline = card2?.tdeadline || 0;
      return card2Deadline - card1Deadline;
    });
  const cardsWithoutDeadline = cardsWithSoonestRequestDeadline.filter((card) => card.tdeadline == null);

  const result = sortOrder ? sortedCardsByDeadline.reverse() : sortedCardsByDeadline;
  const mergedCards = result.concat(cardsWithoutDeadline);

  if (mergedCards.length === 0) {
    return [];
  }

  return [{ id: 'deadline', cards: mergedCards }];
};

import { Account } from '../data';
import { api } from './api';
import { AccessTokenResponse } from './types';

export const authService = {
  login: async (body: LoginBody) => {
    const response = await api.post<LoginResponse>('/auth/login', body);

    return response.data;
  },
  changePassword: async (body: LoginBody) => {
    const response = await api.put<PasswordChangeResponse>('/auth/password', body);

    return response.data;
  },
  getAccounts: async () => {
    const response = await api.get<Account[]>('/auth/accounts');

    return response.data;
  },
  getAccessToken: async (accountId: string, userId: string) => {
    const response = await api.post<AccessTokenResponse>(`/auth/accounts/${accountId}/${userId}`);

    return response.data;
  },
  getTiptapToken: async () => {
    const response = await api.get<string>('/auth/generatejwt');

    return response.data;
  },
};

export interface LoginBody {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user_id: string;
}

export interface PasswordChangeResponse {
  status: string;
}

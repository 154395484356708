import { FC } from 'react';

import { Badge } from '../../../../../../../../../../../../components/Badge';
import { colorByResponseType, RESPONSE_TO_LABEL } from './constants';
import { ResponseStatusBadgeProps } from './types';

export const ResponseStatusBadge: FC<ResponseStatusBadgeProps> = ({ request }) => {
  if (request.withdrawn) {
    return <Badge color="red">Withdrawn!</Badge>;
  }

  return <Badge color={colorByResponseType[request.response]}>{RESPONSE_TO_LABEL[request.response]}</Badge>;
};

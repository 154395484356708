import { Button, Paper, SimpleGrid, Text, TextInput } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { useThrottledCallback } from '@mantine/hooks';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LabeledIcon } from '../../../../components/LabeledIcon';
import { Collection, CollectionType } from '../../../../data';
import { useCollectionsManager } from '../../../../hooks/useCollectionsManager';
import { getMeetingRoute, getStreamRoute } from '../../../../utils';
import { ICONS } from './data';
import { CollectionFormProps } from './types';

const THROTTLE_TIME = 1000;

export const CollectionForm: FC<CollectionFormProps> = ({ type, collection, onClose }) => {
  const { accountId } = useParams();
  const { updateCollection, addCollection } = useCollectionsManager();
  const navigate = useNavigate();

  const initialValues = useMemo(
    () => ({
      title: collection?.title ?? '',
      description: collection?.description ?? '',
      icon: collection?.icon ?? 'archive',
    }),
    [collection]
  );

  const form = useForm({
    initialValues,
    validate: {
      title: isNotEmpty('Enter a title'),
      description: isNotEmpty('Enter a description'),
    },
  });

  const navigateToCollection = useCallback(
    (response: Collection) => {
      if (!accountId) {
        return;
      }

      if (type === CollectionType.Meeting) {
        navigate(getMeetingRoute(accountId, response.id));
      } else {
        navigate(getStreamRoute(accountId, response.id));
      }
    },
    [accountId, navigate, type]
  );

  const handleSubmit = useThrottledCallback(
    async ({ title, description, icon }: { title: string; description: string; icon: string }) => {
      if (collection) {
        const { id, type: collectionType, header } = collection;

        await updateCollection({ id, type: collectionType, header, icon, title, description });

        onClose();
      } else {
        const res = await addCollection({
          title,
          description,
          type,
          header: 'https://nmcimages.s3.us-east-2.amazonaws.com/headers/header_81.png',
          icon,
        });

        onClose();
        form.reset();
        navigateToCollection(res);
      }
    },
    THROTTLE_TIME
  );

  return (
    <Paper
      style={{
        position: 'relative',
        backgroundColor: 'var(--mantine-color-body)',
      }}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput mt="md" placeholder="Title" label="Title" {...form.getInputProps('title')} />

        <TextInput mt="md" placeholder="Description" label="Description" {...form.getInputProps('description')} />

        <Text mt="md" size="sm" fw={500}>
          Select icon
        </Text>
        <SimpleGrid mt={4} cols={5}>
          {ICONS.map((icon) => (
            <Button
              key={icon}
              variant="subtle"
              color={form.values.icon === icon ? 'blue' : 'gray'}
              onClick={() => form.setFieldValue('icon', icon)}
            >
              <LabeledIcon icon={icon} stroke="1.5" />
            </Button>
          ))}
        </SimpleGrid>

        <Button color="blue" type="submit" mt="xl">
          Submit
        </Button>
      </form>
    </Paper>
  );
};

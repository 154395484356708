import { Editor } from '@tiptap/react';
import { useMemo, useState } from 'react';

export interface UseVersionsProps {
  editor: Editor | null;
}

export const useVersions = ({ editor }: UseVersionsProps) => {
  const [currentVersionId, setCurrentVersionId] = useState<number | null>(null);

  const versions = editor?.storage?.collabHistory?.versions?.slice().reverse();

  const versionData = useMemo(() => {
    if (!versions.length) {
      return null;
    }

    return versions.find((v: any) => v.version === currentVersionId);
  }, [currentVersionId, versions]);

  const lastVersion = versions[0]?.version;
  const isLastVersion = versions && versions.length > 0 ? currentVersionId === lastVersion : false;

  return {
    currentVersionId,
    setCurrentVersionId,
    versions,
    versionData,
    lastVersion,
    isLastVersion,
  };
};

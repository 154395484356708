import { SortableCard, SortByPriorityParams } from '../types';
import { compareByDeadlineOrModified } from './compareByDeadlineOrModified';

export const sortCardsByTime = ({
  cards,
  cardRequests,
  userId,
}: Pick<SortByPriorityParams, 'cards' | 'cardRequests' | 'userId'>): SortableCard[] =>
  cards
    .map((card) => {
      const { id } = card;
      const requests = cardRequests[id] ?? [];

      const tdeadline =
        requests.length > 0
          ? requests.reduce((acc, req) => {
              if (req.responder_id === userId && req.tdeadline && req.tdeadline < acc && !req.response) {
                return req.tdeadline;
              }
              return acc;
            }, Infinity)
          : undefined;

      const tlatestModified = requests.reduce((acc, req) => {
        if (req.tmodified && req.tmodified > acc) {
          return req.tmodified;
        }
        return acc;
      }, card.tmodified ?? card.tcreated);

      return { ...card, tdeadline, tlatestModified };
    })
    .sort(compareByDeadlineOrModified);

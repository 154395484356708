import { Stack, Tabs, Text } from '@mantine/core';
import { FC } from 'react';

import { filterTabs } from './constants';
import { FilterTabsProps } from './types';

export const FilterTabs: FC<FilterTabsProps> = ({ activeTab, handleTabChange, filteredRequestsCount }) => (
  <Tabs variant="pills" value={activeTab} onChange={handleTabChange}>
    <Tabs.List>
      {filterTabs.map(({ tab, label }) => (
        <Tabs.Tab key={tab} value={tab}>
          <Stack gap="4px" miw={102}>
            <Text fz="13px">{label}</Text>
            <Text fz="20px">{filteredRequestsCount[tab]}</Text>
          </Stack>
        </Tabs.Tab>
      ))}
    </Tabs.List>
  </Tabs>
);

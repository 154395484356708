import { Request, RequestResponseTypes } from '../data';

export const isCompletedRequest = ({ response, withdrawn }: Pick<Request, 'response' | 'withdrawn'>) =>
  !withdrawn &&
  ![
    RequestResponseTypes.Empty,
    RequestResponseTypes.InProgress,
    RequestResponseTypes.LetsMeet,
    RequestResponseTypes.Unable,
  ].includes(response);

import { Box } from '@mantine/core';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAuthContext } from '../contexts';
import { RouteNames } from '../types';
import { getHomeRoute } from '../utils';

export const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { accountId } = useParams();

  const { account, isAuthenticated, isAuthStateBeingInitialized } = useAuthContext();

  useEffect(() => {
    if (isAuthStateBeingInitialized) {
      return;
    }

    if (!isAuthenticated && pathname !== RouteNames.Login) {
      navigate(RouteNames.Login);
    }
    if (isAuthenticated && account && (pathname === RouteNames.Login || pathname === RouteNames.Index)) {
      navigate(getHomeRoute(account));
    }
    if (isAuthenticated && account && account !== accountId) {
      navigate(getHomeRoute(account));
    }
  }, [isAuthenticated, isAuthStateBeingInitialized, navigate, pathname, account, accountId]);

  return <Box>{children}</Box>;
};

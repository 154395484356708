import { ActionIcon, Button, Drawer, Group, px, Stack, TextInput, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import { FC, useCallback, useState } from 'react';

import { Editor } from '../../../../components/Editor';
import { useAdvancedEditor, useTemplatesManager } from '../../../../hooks';

export const AddTemplateForm: FC = () => {
  const { addTemplate } = useTemplatesManager();

  const [opened, { open, close }] = useDisclosure(false);
  const [title, setTitle] = useState('');

  const { editor } = useAdvancedEditor({ content: '' });

  const clearFields = () => {
    setTitle('');
    editor?.commands.clearContent();
  };

  const handleAddTemplate = useCallback(
    async (doc: string) => {
      await addTemplate({
        name: title,
        content: doc,
      });
    },
    [addTemplate, title]
  );

  return (
    <>
      <Tooltip label="Add new template" withArrow>
        <ActionIcon onClick={open} variant="filled" color="blue" size={36}>
          <IconPlus size={px('1.125rem')} />
        </ActionIcon>
      </Tooltip>
      <Drawer
        size="lg"
        opened={opened}
        onClose={() => {
          clearFields();
          close();
        }}
        title="Add Template"
      >
        <Stack>
          <TextInput
            label="Name"
            required
            placeholder="What is the name of your template?"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={title.length > 140 ? 'title too long' : null}
          />

          <Editor editor={editor} />

          <Group>
            <Button
              variant="filled"
              leftSection={<IconCheck size={px('1.125rem')} />}
              disabled={title.length === 0}
              onClick={() => {
                let doc = editor?.getHTML();
                if (doc === undefined) {
                  doc = '';
                }
                handleAddTemplate(doc);
                clearFields();
                close();
              }}
            >
              Create Template
            </Button>
          </Group>
        </Stack>
      </Drawer>
    </>
  );
};

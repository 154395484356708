import { User } from '../../../data';

export const filterUsers = (users: User[], search: string) => {
  if (search === '') {
    return users;
  }

  const searchLower = search.toLowerCase().trim();

  if (searchLower.startsWith('@')) {
    const displayname = searchLower.substring(1);
    return users.filter((user) => user.displayname.toLowerCase().startsWith(displayname));
  }

  return users.filter(
    (user) => user.displayname.toLowerCase().includes(searchLower) || user.fullname.toLowerCase().includes(searchLower)
  );
};

import { Box, Divider } from '@mantine/core';
import { FC } from 'react';

import { CollectionType } from '../../../../../../../../../../data';
import { SectionTitle } from '../SectionTitle';
import { Approvals, CollectionList } from './components';
import { CardDetailsProps } from './types';

export const CardDetails: FC<CardDetailsProps> = ({ card }) => (
  <Box flex={1} pr="16px" pt="16px" style={{ overflowY: 'auto' }}>
    <SectionTitle>Card Details</SectionTitle>
    <Divider my="8px" />
    <Approvals card={card} />
    <CollectionList card={card} subtitle="In These Streams:" collectionType={CollectionType.Stream} />
    <CollectionList card={card} subtitle="In These Meetings:" collectionType={CollectionType.Meeting} />
  </Box>
);

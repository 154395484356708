import { RequestResponseTypes } from '../../../../../../../../../../../../data';

export const colorByResponseType: Record<RequestResponseTypes, string> = {
  [RequestResponseTypes.Yes]: 'green',
  [RequestResponseTypes.No]: 'red',
  [RequestResponseTypes.Done]: 'green',
  [RequestResponseTypes.Unable]: 'orange',
  [RequestResponseTypes.LetsMeet]: 'orange',
  [RequestResponseTypes.Empty]: 'blue',
  [RequestResponseTypes.InProgress]: 'orange',
};

export const RESPONSE_TO_LABEL: Record<RequestResponseTypes, string> = {
  [RequestResponseTypes.Yes]: 'Approved',
  [RequestResponseTypes.No]: 'Declined',
  [RequestResponseTypes.Done]: 'Done',
  [RequestResponseTypes.Unable]: 'Unable to do',
  [RequestResponseTypes.LetsMeet]: "Let's meet",
  [RequestResponseTypes.Empty]: 'Waiting for response',
  [RequestResponseTypes.InProgress]: 'In progress',
};

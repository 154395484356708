import { Badge, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import { FC } from 'react';
import ReactTimeAgo from 'react-time-ago';

import { DeadlineBadgeProps } from './types';

export const DeadlineBadge: FC<DeadlineBadgeProps> = ({ isComplete, tdeadline, size }) => {
  if (!tdeadline) {
    return null;
  }

  const isOverdue = tdeadline < new Date().getTime();
  const stringDeadline = dayjs(tdeadline).format('MMMM D, YYYY h:mm A');

  if (isComplete && !isOverdue) {
    return null;
  }

  if (isComplete && isOverdue) {
    return (
      <Tooltip label={stringDeadline}>
        <Badge variant="outline" color="red" size={size}>
          Done Late
        </Badge>
      </Tooltip>
    );
  }

  if (isOverdue) {
    return (
      <Tooltip label={stringDeadline}>
        <Badge variant="outline" color="red" size={size}>
          OVERDUE
        </Badge>
      </Tooltip>
    );
  }

  return (
    <Tooltip label={stringDeadline}>
      <Badge variant="outline" color="gray" size={size}>
        Due <ReactTimeAgo date={tdeadline} locale="en-US" />
      </Badge>
    </Tooltip>
  );
};

import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Collection, CollectionType } from '../../data';
import { CollectionForm } from './components/CollectionForm';
import { CollectionModalContext } from './context';
import { getTitle } from './utils';

export const CollectionModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [type, setType] = useState<CollectionType>(CollectionType.Meeting);
  const [collection, setCollection] = useState<Collection>();

  const openCollectionModal = useCallback(
    (collectionType: CollectionType, targetCollection?: Collection) => {
      setType(collectionType);
      setCollection(targetCollection);

      open();
    },
    [open]
  );

  const value = useMemo(
    () => ({
      openCollectionModal,
    }),
    [openCollectionModal]
  );

  const isEdit = Boolean(collection);
  const title = getTitle(type, isEdit);

  return (
    <>
      <CollectionModalContext.Provider value={value}>{children}</CollectionModalContext.Provider>

      <Modal opened={opened} onClose={close} title={title}>
        <CollectionForm type={type} collection={collection} onClose={close} />
      </Modal>
    </>
  );
};

import { ActionIcon, px } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';
import { FC } from 'react';

import { OpenCalendarBtnProps } from './types';

export const OpenCalendarBtn: FC<OpenCalendarBtnProps> = ({ onClick }) => (
  <ActionIcon onClick={onClick}>
    <IconCalendar size={px('1rem')} />
  </ActionIcon>
);

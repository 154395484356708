import { Avatar, Group, Text } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../hooks';

interface UserAvatarProps {
  user_id: string | null | undefined;
  short?: boolean;
  tiny?: boolean;
  piconly?: boolean;
  handleonly?: boolean;
  displaynameonly?: boolean;
}

export const UserAvatar: FC<UserAvatarProps> = ({ user_id, tiny, short, piconly, handleonly, displaynameonly }) => {
  const { getUser } = useUsersManager();

  if (user_id == null) {
    return (
      <Group gap="xs">
        <Avatar size="sm" radius="xl" alt="Anonymous" data-testid="user-avatar" />
        <Text>Anonymous</Text>
      </Group>
    );
  }
  const user = getUser(user_id);

  if (!user) {
    return null;
  }

  // Only show the picture, not the name. (tiny size)
  if (tiny) {
    return <Avatar src={user.avatar} size="xs" radius="xl" alt={user.displayname} data-testid="user-avatar" />;
  }

  // Only show the picture, not the name. (normal size)
  if (piconly) {
    return <Avatar src={user.avatar} size="sm" radius="xl" alt={user.displayname} data-testid="user-avatar" />;
  }
  if (handleonly) {
    return (
      <Group gap="xs">
        <Avatar src={user.avatar} size="sm" radius="xl" alt={user.displayname} data-testid="user-avatar" />
        <Text c="gray" fz="sm">
          @{user.displayname}
        </Text>
      </Group>
    );
  }

  if (displaynameonly) {
    return (
      <Group gap="xs">
        <Avatar src={user.avatar} size="sm" radius="xl" alt={user.displayname} data-testid="user-avatar" />
        <Text fz="md">{user.displayname}</Text>
      </Group>
    );
  }

  return (
    <Group gap="xs" style={{ flexWrap: 'nowrap' }}>
      <Avatar src={user.avatar} size="sm" radius="xl" alt={user.displayname} />
      <Text
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {user.fullname}
      </Text>
      {!short ? (
        <Text color="gray" fz="sm">
          @{user.displayname}
        </Text>
      ) : null}
    </Group>
  );
};

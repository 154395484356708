import FileHandler from '@tiptap-pro/extension-file-handler';
import { useMemo } from 'react';

import { useCardsManager } from '../../../useCardsManager';
import { allowedMimeTypes } from './constants';

export const useFileUpload = () => {
  const { uploadImage } = useCardsManager();

  const plugins = useMemo(
    () => [
      FileHandler.configure({
        allowedMimeTypes,
        onDrop: (currentEditor, files, pos) => {
          files.forEach(async (file) => {
            const src = await uploadImage({ file });

            currentEditor
              .chain()
              .insertContentAt(pos, {
                type: 'image',
                attrs: {
                  src,
                },
              })
              .focus()
              .run();
          });
        },
        onPaste: (currentEditor, files, htmlContent) => {
          files.forEach(async (file) => {
            if (htmlContent) {
              // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
              // you could extract the pasted file from this url string and upload it to a server for example
              console.log(htmlContent); // eslint-disable-line no-console
              return false;
            }

            const src = await uploadImage({ file });

            currentEditor
              .chain()
              .insertContentAt(currentEditor.state.selection.anchor, {
                type: 'image',
                attrs: {
                  src,
                },
              })
              .focus()
              .run();

            return true;
          });
        },
      }),
    ],
    [uploadImage]
  );

  return { plugins };
};

import { ActionIcon, Avatar, Box } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { FC, useCallback } from 'react';

import { Upload } from '../../../../../../components/Upload';
import { useUsersManager } from '../../../../../../hooks';
import { ProfileAvatarProps } from './types';

export const ProfileAvatar: FC<ProfileAvatarProps> = ({ currentUser }) => {
  const { avatar, displayname } = currentUser;
  const { updateProfileAvatar } = useUsersManager();

  const handleFileChange = useCallback(
    async (file: File) => {
      try {
        await updateProfileAvatar({ file });
      } catch (error) {
        //
      }
    },
    [updateProfileAvatar]
  );

  return (
    <Box pos="relative" style={{ width: '240px', height: '240px' }}>
      <Avatar src={avatar} size="240px" radius="200px" alt={displayname} />
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '100%',
          transform: 'translate(50%, 50%)',
          marginLeft: '-54px',
          marginBottom: '42px',
          cursor: 'pointer',
        }}
      >
        <ActionIcon variant="white" radius="xl" size="lg" pos="relative">
          <IconPencil size="22px" />
          <Upload onChange={handleFileChange} />
        </ActionIcon>
      </div>
    </Box>
  );
};

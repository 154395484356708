import { SortTypes } from './types';

export const SORT_LABELS: Record<SortTypes, string> = {
  [SortTypes.Priority]: 'Priority',
  [SortTypes.Created]: 'Created',
  [SortTypes.Modified]: 'Modified',
  [SortTypes.Title]: 'Title',
  [SortTypes.Owner]: 'Owner',
  [SortTypes.NextDeadline]: 'Next Deadline',
};

import { SortTypes } from '../components/Sorting/types';
import { Card } from '../data';
import { StreamCategory } from '../types';

interface SortCardsParams {
  cards: Card[];
  sortField: string;
  sortOrder: boolean;
}

export const sortCards = ({ cards, sortField, sortOrder }: SortCardsParams): StreamCategory[] => {
  const sortedCards = cards.sort((a, b) => {
    switch (sortField) {
      case SortTypes.Modified:
        return (a.tmodified || a.tcreated) - (b.tmodified || b.tcreated);
      case SortTypes.Title:
        return a.description.localeCompare(b.description);
      case SortTypes.Owner:
        return (a.creator?.fullname || '').localeCompare(b.creator?.fullname || '');
      default:
        return a.tcreated - b.tcreated;
    }
  });

  return [{ id: 'sortedCards', cards: sortOrder ? sortedCards.reverse() : sortedCards }];
};

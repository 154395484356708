import { Divider, Group, Paper, Stack, Text } from '@mantine/core';
import { FC } from 'react';
import ReactTimeAgo from 'react-time-ago';

import { useRequestsManager } from '../../hooks';
import { getCardOwner } from '../../utils';
import { UserAvatar } from '../UserAvatar';
import { TinyCardProps } from './types';

export const TinyCard: FC<TinyCardProps> = ({ card, onClick, nouser }) => {
  const { getRequestsForCard } = useRequestsManager();

  const requests = getRequestsForCard(card.id).filter((request) => request.response === '');
  const responders = requests.map((request) => request.responder_id);

  return (
    <Paper shadow="xs" p="md">
      <Stack>
        <Group justify="space-between">
          {nouser === undefined || nouser === false ? <UserAvatar handleonly user_id={getCardOwner(card)} /> : null}
          <Text fz="sm" color="grey">
            <ReactTimeAgo date={card.tmodified !== null ? card.tmodified : card.tcreated} locale="en-US" />
          </Text>
        </Group>
        <Text
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {card.description}
        </Text>
        {requests.length > 0 && (
          <Stack>
            <Divider />
            <Group>
              <Text size="sm" color="gray">
                {requests.length === 1 ? 'Open request' : `${requests.length} Open requests`}
              </Text>
              <Group>
                {responders.map((responder_id) => (
                  <UserAvatar key={responder_id} tiny user_id={responder_id} />
                ))}
              </Group>
            </Group>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

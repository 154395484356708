import {
  Icon360,
  IconAbacus,
  IconActivity,
  IconAlien,
  IconAmbulance,
  IconAperture,
  IconApps,
  IconArchive,
  IconArrowBounce,
  IconArrowsShuffle,
  IconBlender,
  IconCalendar,
  IconCardsFilled,
  IconCat,
  IconChartBubble,
  IconCircleNumber1,
  IconCircleNumber2,
  IconCircleNumber3,
  IconDeviceComputerCamera,
  IconDog,
  IconFeather,
  IconGraph,
  IconHelicopter,
  IconMicroscope,
  IconNetwork,
  IconNotebook,
  IconProps,
  IconRelationOneToOne,
  IconRoute,
  IconRouteAltLeft,
  IconStar,
  IconTelescope,
  IconUsersGroup,
  IconVirus,
  IconZeppelin,
} from '@tabler/icons-react';
import { FC } from 'react';

export const iconMap: Record<string, FC<IconProps>> = {
  usersgroup: IconUsersGroup,
  calendar: IconCalendar,
  onetoone: IconRelationOneToOne,
  notebook: IconNotebook,
  network: IconNetwork,
  route: IconRoute,
  routeleft: IconRouteAltLeft,
  shuffle: IconArrowsShuffle,
  '360': Icon360,
  arrowbounce: IconArrowBounce,
  graph: IconGraph,
  bubble: IconChartBubble,
  abacus: IconAbacus,
  activity: IconActivity,
  apps: IconApps,
  microscope: IconMicroscope,
  telescope: IconTelescope,
  blender: IconBlender,
  ambulance: IconAmbulance,
  helicopter: IconHelicopter,
  circle1: IconCircleNumber1,
  circle2: IconCircleNumber2,
  circle3: IconCircleNumber3,
  cat: IconCat,
  dog: IconDog,
  virus: IconVirus,
  alien: IconAlien,
  feather: IconFeather,
  camera: IconDeviceComputerCamera,
  aperture: IconAperture,
  zeppelin: IconZeppelin,
  archive: IconArchive,
  stream: IconCardsFilled,
  star: IconStar,
};

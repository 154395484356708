import { FilterTypes } from '../../../../../../../components/FilterControl/types';
import { Card, Request, RequestResponseTypes } from '../../../../../../../data';
import { isOpenRequestToMe } from '../../../../../../../utils';

export const applyRequestFilter = (
  cards: Card[],
  filter: FilterTypes,
  cardRequests: Record<string, Request[]>,
  userId: string
) => {
  return cards.filter(({ id }) => {
    const currentCardRequests = cardRequests[id];

    if (!currentCardRequests) {
      return false;
    }

    if (filter === FilterTypes.OpenRequestsFromMe) {
      return currentCardRequests.some(
        ({ requester_id, response, withdrawn }) =>
          requester_id === userId && response === RequestResponseTypes.Empty && !withdrawn
      );
    }

    if (filter === FilterTypes.OpenRequestsToMe) {
      return currentCardRequests.some(({ responder_id, response, withdrawn }) =>
        isOpenRequestToMe({ responder_id, response, withdrawn }, userId)
      );
    }

    return false;
  });
};

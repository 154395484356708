import { Stack } from '@mantine/core';
import { FC, useMemo } from 'react';

import { ApprovalsSummary } from '../../../../../../../../../../../../components/ApprovalsSummary';
import { useApprovalRequests, useCardsManager, useRequestsManager } from '../../../../../../../../../../../../hooks';
import { getCardRequestsMap } from '../../../../../../../../../../../../utils';
import { Subtitle } from '../../../Subtitle';
import { SummaryProps } from './types';

export const Approvals: FC<SummaryProps> = ({ card }) => {
  const { requests } = useRequestsManager();
  const { cards } = useCardsManager();

  const cardRequests = useMemo(() => getCardRequestsMap(requests, cards), [requests, cards]);

  const approvalRequests = useApprovalRequests({ card, cardRequests });

  if (approvalRequests.length === 0) {
    return null;
  }

  return (
    <Stack gap="12px" mb="12px">
      <Subtitle>Card Approvals</Subtitle>
      <ApprovalsSummary isVertical card={card} cardRequests={cardRequests} />
    </Stack>
  );
};

import { Text } from '@mantine/core';

import { RequestTypes } from '../../../../../../../../data';

export const labels: Record<RequestTypes, string> = {
  comment: 'Input',
  approval: 'Approval',
  action: 'Action',
};

export const messages = {
  comment: (
    <>
      <Text size="sm">Ask them to chime in on the decision with their data, context, perspective, etc.</Text>
      <Text size="sm" mt="6px">
        <em>Responses:</em> Comment
      </Text>
    </>
  ),
  approval: (
    <>
      <Text size="sm">Ask them to approve the decision in its current state.</Text>
      <Text size="sm" mt="6px">
        <em>Responses:</em> Yes • No • Let&apos;s Meet <em>(plus comments)</em>
      </Text>
    </>
  ),
  action: (
    <>
      <Text size="sm">Ask them to take an action to execute the decision.</Text>
      <Text size="sm" mt="6px">
        <em>Responses:</em> Done • In Progress • Unable to Do <em>(plus comments)</em>
      </Text>
    </>
  ),
};

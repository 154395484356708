import { Group } from '@mantine/core';
import { IconCaretUpDown, IconChevronsRight, IconStepInto } from '@tabler/icons-react';
import { FC, useMemo } from 'react';

import { Badge } from '../../../../../../../../../../../../components/Badge';
import { colorByRequestType, textByRequestType } from './constants';
import { RequestTypeBadgeProps } from './types';

export const RequestTypeBadge: FC<RequestTypeBadgeProps> = ({ request }) => {
  const icon = useMemo(() => {
    switch (request.request_type) {
      case 'approval':
        return <IconCaretUpDown size={16} />;
      case 'comment':
        return <IconStepInto size={16} />;
      case 'action':
        return <IconChevronsRight size={16} />;
      default:
        return null;
    }
  }, [request.request_type]);

  return (
    <Badge color={colorByRequestType[request.request_type]}>
      <Group gap="2px">
        {icon} {textByRequestType[request.request_type]} request
      </Group>
    </Badge>
  );
};

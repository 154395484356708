import { SortableCard } from '../types';

export const compareByDeadlineOrModified = (a: SortableCard, b: SortableCard) => {
  if (a.tdeadline && b.tdeadline) {
    return a.tdeadline - b.tdeadline;
  }

  const at = a.tlatestModified || a.tcreated;
  const bt = b.tlatestModified || b.tcreated;
  return bt - at;
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { REFETCH_INTERVAL } from '../constants/query';
import { useAuthContext } from '../contexts/AuthContext';
import { RequestResponseTypes, RequestTypes } from '../data';
import { requestsService } from '../services';
import { CreateRequestParams } from '../services/requestsService';
import { useCardsManager } from './useCardsManager';

export interface AddRequestParams {
  cardId: string;
  requestType: RequestTypes;
  requestText: string;
  requestTarget: string;
  deadline: Date | null;
}

export const useRequestsManager = () => {
  const { isAuthenticated, currentUserId } = useAuthContext();
  const { getCard, updateCard } = useCardsManager();
  const queryClient = useQueryClient();

  const { data: requests, isLoading } = useQuery({
    queryKey: ['requests'],
    queryFn: requestsService.getRequests,
    enabled: !!isAuthenticated,
    initialData: [],
    retry: false,
    refetchInterval: REFETCH_INTERVAL,
  });
  const createRequestMutation = useMutation({
    mutationFn: requestsService.createRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
  const updateRequestMutation = useMutation({
    mutationFn: requestsService.updateRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
  const deleteRequestMutation = useMutation({
    mutationFn: requestsService.deleteRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });

  const getRequest = useCallback((id: string) => requests.find((request) => request.id === id), [requests]);

  const addRequest = useCallback(
    async ({ cardId, requestType, requestText, requestTarget, deadline }: AddRequestParams) => {
      if (!currentUserId) {
        return;
      }

      let time = null;
      if (deadline !== null) {
        time = deadline.getTime();
      }

      const params: CreateRequestParams = {
        card_id: cardId,
        requester_id: currentUserId,
        responder_id: requestTarget,
        request_type: requestType,
        title: requestText,
        content: '',
        choices: '',
        response: RequestResponseTypes.Empty,
        comment: '',
        tdeadline: time,
        withdrawn: false,
      };

      await createRequestMutation.mutate(params);

      const existingCard = getCard(cardId);
      if (existingCard && requestType === 'approval' && existingCard.state === 'open') {
        await updateCard({ ...existingCard, state: 'submitted' });
      }
    },
    [getCard, updateCard, createRequestMutation, currentUserId]
  );

  const getRequestsForCard = useCallback(
    (id: string) => requests.filter((request) => request.card_id === id).sort((a, b) => b.tcreated - a.tcreated),
    [requests]
  );

  return {
    requests,
    getRequest,
    getRequestsForCard,
    addRequest,
    updateRequest: updateRequestMutation.mutateAsync,
    removeRequest: deleteRequestMutation.mutateAsync,
    isLoading,
  };
};

export enum SortTypes {
  Priority = 'priority',
  Created = 'tcreated',
  Modified = 'tmodified',
  Title = 'content',
  Owner = 'creator',
  NextDeadline = 'next_deadline',
}

export interface SortingProps {
  onChangeFilter: (filters: any) => void;
  sortField: SortTypes;
  sortOrder: boolean;
}

import { Center, Text } from '@mantine/core';
import { FC, PropsWithChildren } from 'react';

export const Error: FC<PropsWithChildren> = ({ children }) => (
  <Center mt={16}>
    <Text size="md" c="red.6">
      {children}
    </Text>
  </Center>
);

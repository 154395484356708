import { api } from '../../services/api';

export const setAuthHeaders = (token?: string) => {
  api.interceptors.request.clear();

  if (!token) {
    return;
  }

  api.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
};

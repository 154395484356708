import { TiptapCollabProvider } from '@hocuspocus/provider';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationHistory from '@tiptap-pro/extension-collaboration-history';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as Y from 'yjs';

import { useAuthContext } from '../../../../contexts/AuthContext';
import { useUsersManager } from '../../../useUsersManager';
import { UseCollaborationProps, UseCollaborationResponse } from './types';

export const useCollaboration = ({ useVersioning = false }: UseCollaborationProps): UseCollaborationResponse => {
  const { currentUser } = useUsersManager();
  const { tipTapToken } = useAuthContext();
  const { accountId, cardId } = useParams();

  const doc = useMemo(() => (useVersioning ? new Y.Doc() : null), [useVersioning]);

  const provider = useMemo(
    () =>
      doc
        ? new TiptapCollabProvider({
            name: `${accountId}/${cardId}`,
            appId: process.env.REACT_APP_TIP_TAP_APP_ID!,
            token: tipTapToken!,
            document: doc,
            user: currentUser?.id,
          })
        : null,
    [accountId, cardId, currentUser?.id, doc, tipTapToken]
  );

  const plugins = useMemo(
    () =>
      doc
        ? [
            Collaboration.configure({
              document: doc,
            }),
            CollaborationHistory.configure({
              provider,
            }),
          ]
        : [],
    [doc, provider]
  );

  return {
    provider,
    plugins,
  };
};

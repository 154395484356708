import { Box, ScrollArea } from '@mantine/core';
import { FC } from 'react';

import { useSetDocumentTitle } from '../../hooks';
import { Header, WebhooksTable } from './components';

export const IntegrationsPage: FC = () => {
  useSetDocumentTitle('Integrations');

  return (
    <div style={{ paddingBottom: '16px', height: '100%', overflow: 'auto' }}>
      <ScrollArea.Autosize w="100%" type="auto">
        <Header />
        <Box pl="sm" pt="sm" pr="sm">
          <WebhooksTable />
        </Box>
      </ScrollArea.Autosize>
    </div>
  );
};

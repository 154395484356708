import { Webhook } from '../data';
import { api } from './api';

export const webhooksService = {
  createWebhook: async (params: CreateWebhookParams) => {
    const response = await api.post<Webhook>('/webhooks', { ...params, scope: 'account' });

    return response.data;
  },
  getWebhooks: async () => {
    const response = await api.get<Webhook[]>('/webhooks');

    return response.data;
  },
  getWebhook: async (webhookId: string) => {
    const response = await api.get<Webhook>(`/webhooks/${webhookId}`);

    return response.data;
  },
  deleteWebhook: async (webhookId: string) => {
    await api.delete(`/webhooks/${webhookId}`);
  },
};

export interface CreateWebhookParams {
  name: string;
  scope: string;
  method: string;
  url: string;
  data: 'CARDS' | 'REQUESTS' | 'FULLCARDS';
}

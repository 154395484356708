import { Button, Flex, Modal, Stack, Text } from '@mantine/core';
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCardsManager } from '../../../../../../hooks';
import { getHomeRoute } from '../../../../../../utils';
import { DeleteCardModalProps } from './types';

export const DeleteCardModal: FC<DeleteCardModalProps> = ({ card, isOpened, onClose }) => {
  const { removeCard, updateCard } = useCardsManager();
  const { accountId } = useParams();
  const navigate = useNavigate();

  const handleRemoveCard = useCallback(async () => {
    if (!accountId) {
      return;
    }

    await removeCard(card.id);
    navigate(getHomeRoute(accountId), { replace: true });
  }, [accountId, card.id, navigate, removeCard]);

  const handleArchiveCard = useCallback(async () => {
    await updateCard({
      ...card,
      state: 'archived',
    });
    onClose();
  }, [card, updateCard, onClose]);

  return (
    <Modal
      onClick={(e) => {
        e.stopPropagation();
      }}
      opened={isOpened}
      onClose={onClose}
      title="Warning: This cannot be undone!"
    >
      <Stack>
        <Text size="sm">
          Instead of deleting this card, consider archiving it. If you really want to delete it, please confirm that you
          want to delete this card forever.
        </Text>
        <Flex justify="flex-end" gap="12">
          <Button onClick={onClose} color="grey" c="black" variant="outline">
            Cancel
          </Button>
          <Button onClick={handleArchiveCard} color="gray" c="black" variant="outline">
            Archive
          </Button>
          <Button onClick={handleRemoveCard} color="blue" variant="filled">
            Delete forever
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
};

import { createContext, useContext } from 'react';

import { AuthContextStateType, LoginMutation, PasswordChangeMutation } from './types';

export const AuthContext = createContext<AuthContextStateType>({
  isAuthenticated: false,
  isAuthStateBeingInitialized: true,
  account: null,
  currentUserId: null,
  loginMutation: {} as LoginMutation,
  handleLogin: () => {},
  handleSelectAccount: async () => {},
  handleLogout: () => {},
  changePasswordMutation: {} as PasswordChangeMutation,
});

export const useAuthContext = () => useContext(AuthContext);

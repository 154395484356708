import { useCallback } from 'react';
import { unstable_useBlocker as useBlocker, useBeforeUnload } from 'react-router-dom';

const message = 'You have unsaved changes. Are you sure you want to leave and discard your changes?';

export const useLeaveConfirmation = (shouldShowPrompt: boolean): void => {
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent): void => {
      if (!shouldShowPrompt) {
        return;
      }

      event.preventDefault();
      event.returnValue = message;
    },
    [shouldShowPrompt]
  );

  useBeforeUnload(handleBeforeUnload);

  useBlocker(() => {
    if (shouldShowPrompt) {
      // eslint-disable-next-line no-alert
      return !window.confirm(message);
    }
    return false;
  });
};

import { useState } from 'react';

import { useAuthContext } from '../../contexts/AuthContext';
import { Card } from '../../data';
import { useCardsManager } from '../useCardsManager';
import { useRequestsManager } from '../useRequestsManager';
import { useOpenRequests } from './hooks';

export const useCompleteCard = (card: Card, onSave?: () => Promise<Card | null>) => {
  const { id: cardId } = card;

  const { currentUserId } = useAuthContext();

  const { updateRequest } = useRequestsManager();
  const { updateCard, snoozeCard } = useCardsManager();

  const [snoozeDate, setSnoozeDate] = useState<Date | null>(null);

  const openRequests = useOpenRequests(cardId);

  const onSubmit = async () => {
    let tempCard: Card | null = card;

    if (onSave) {
      tempCard = await onSave?.();
    }

    if (!tempCard) {
      return;
    }

    await updateCard({ ...tempCard, state: 'completed' });

    await Promise.all(openRequests.map((request) => updateRequest({ ...request, withdrawn: true })));

    if (snoozeDate && currentUserId) {
      await snoozeCard({
        card_id: cardId,
        tuntil: snoozeDate.getTime(),
        user_id: currentUserId,
      });
    }
  };

  return {
    openRequests,
    snoozeDate,
    setSnoozeDate,
    onSubmit,
  };
};

import { Text } from '@mantine/core';
import { FC } from 'react';
import ReactTimeAgo from 'react-time-ago';

import { LastActivityProps } from './types';

export const LastActivity: FC<LastActivityProps> = ({ card }) => {
  const latestActivity = card.tmodified ?? card.tcreated;

  if (!latestActivity) {
    return null;
  }

  return (
    <Text fz="sm" c="grey">
      <ReactTimeAgo date={latestActivity} locale="en-US" />
    </Text>
  );
};

/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChangeEvent, CSSProperties, FC, useCallback } from 'react';

import { ALLOWED_IMAGE_FORMATS } from '../../constants/allowedFormats';
import { UploadProps } from './types';

const style: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  opacity: 0,
};

export const Upload: FC<UploadProps> = ({ onChange }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        onChange(file);
      }
    },
    [onChange]
  );

  return (
    <label style={style}>
      <input type="file" onChange={handleChange} accept={ALLOWED_IMAGE_FORMATS} style={style} id="avatar" />
    </label>
  );
};

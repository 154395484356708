import { Group } from '@mantine/core';
import { FC, useCallback } from 'react';

import { Filters } from '../../../../components/Filters';
import { SearchInput } from '../../../../components/SearchInput';
import { Sorting } from '../../../../components/Sorting';
import { FILTER_LABELS } from './const';
import classNames from './FilterControl.module.css';
import { FilterControlProps, FilterTypes } from './types';

export const FilterControl: FC<FilterControlProps> = ({ filters, onChangeFilter }) => {
  const { sortField, sortOrder, searchQuery, filter: appliedFilter } = filters;

  const toggleFilter = (filter: FilterTypes) => {
    if (appliedFilter === filter) {
      onChangeFilter({ filter: null });
      return;
    }

    onChangeFilter({ filter });
  };

  const handleChangeSearchQuery = useCallback(
    (value: string) => {
      onChangeFilter({ searchQuery: value });
    },
    [onChangeFilter]
  );

  const isFilterActive = appliedFilter != null;
  const filterOptions = Object.values(FilterTypes);

  return (
    <Group gap="sm" align="center">
      <Sorting sortField={sortField} sortOrder={sortOrder} onChangeFilter={onChangeFilter} />
      <Group gap="0" align="center">
        <Filters
          filterOptions={filterOptions}
          appliedFilter={appliedFilter}
          isFilterActive={isFilterActive}
          toggleFilter={toggleFilter}
          filterLabels={FILTER_LABELS}
        />
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={handleChangeSearchQuery}
          classNames={{ input: classNames.searchInput }}
          w={200}
        />
      </Group>
    </Group>
  );
};

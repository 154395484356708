import { Group, Text } from '@mantine/core';
import { FC } from 'react';

import { useAuthContext } from '../../../../../../../../../../contexts';
import { shouldDisplayActionPanel } from '../../utils';
import { TimelineItem } from '../TimelineItem';
import { RequestActions, RequestTypeBadge } from './components';
import { RequesterItemProps } from './types';

export const RequesterItem: FC<RequesterItemProps> = ({ card, request, user }) => {
  const { title, tcreated } = request;
  const { currentUserId } = useAuthContext();

  const isMe = user?.id === currentUserId;
  const shouldShowActionPanel = shouldDisplayActionPanel({ card, request, currentUserId });

  return (
    <TimelineItem
      isMe={isMe}
      user={user}
      time={tcreated}
      rightSection={
        <Group gap="8px" align="center" flex={1} justify="flex-end">
          {shouldShowActionPanel && <RequestActions card={card} request={request} />}
          <RequestTypeBadge request={request} />
        </Group>
      }
    >
      <Text c="dark.7">{title}</Text>
    </TimelineItem>
  );
};

import { Avatar } from '@mantine/core';
import { FC } from 'react';

import { RequestAvatarProps } from './types';

export const RequestAvatar: FC<RequestAvatarProps> = ({ isActive, user }) => (
  <Avatar
    src={user?.avatar}
    size="md"
    radius="xl"
    alt={user?.displayname}
    style={{ border: isActive ? '3px solid #228be6' : 'none' }}
  />
);

import { Group } from '@mantine/core';
import { FC, useCallback } from 'react';

import { useCardsManager } from '../../../../hooks';
import { getCardOwner } from '../../../../utils';
import { CardHeaderTimeAgo } from '../../../CardHeaderTimeAgo';
import { FavoriteBtn } from '../../../FavoriteBtn';
import { StateBtn } from '../../../StateBtn';
import { UserAvatar } from '../../../UserAvatar';
import { CardActions } from './components';
import { CardHeaderProps } from './types';

export const CardHeader: FC<CardHeaderProps> = ({ card, isMyStream, collectionId, onOpenCard }) => {
  const { updateCard, removeCollectionCard } = useCardsManager();

  const setCardState = useCallback(
    async (state: string) => {
      const tmp = { ...card, state };
      await updateCard(tmp);
    },
    [card, updateCard]
  );

  return (
    <Group justify="apart" grow>
      <Group>
        <UserAvatar user_id={getCardOwner(card)} />
      </Group>
      <Group justify="flex-end">
        <Group gap={8}>
          <CardHeaderTimeAgo card={card} />
          <FavoriteBtn cardId={card.id} />
          <StateBtn card={card} />
          <CardActions
            card={card}
            collectionId={collectionId}
            isRemovalDisabled={isMyStream || !collectionId}
            onOpenCard={onOpenCard}
            onArchiveCard={() => setCardState('archived')}
            onUnarchiveCard={() => setCardState('open')}
            onRemoveFromStream={() => collectionId && removeCollectionCard({ collectionId, cardId: card.id })}
          />
        </Group>
      </Group>
    </Group>
  );
};

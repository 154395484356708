import { Request, RequestResponseTypes, RequestTypes } from '../../../../../../../data';

export const isReadyForAction = (requests: Request[]) => {
  const openRequests = requests.filter(
    ({ response, withdrawn }) => response === RequestResponseTypes.Empty && !withdrawn
  );
  const letsMeetRequests = requests.filter(
    ({ request_type, response }) => request_type === RequestTypes.Approval && response === RequestResponseTypes.LetsMeet
  );

  return requests.length > 0 && openRequests.length === 0 && letsMeetRequests.length === 0;
};

import { Collection } from '../../data';
import { useCollectionsManager } from '../useCollectionsManager';

export const useCollection = (collectionId?: string): Collection | undefined => {
  const { getCollection } = useCollectionsManager();

  const collection = collectionId ? getCollection(collectionId) : undefined;

  return collection;
};

import { Card, CollectionCard, Request } from '../data';
import { FilterTypes } from '../pages/ArchivePage/components/FilterControl/types';
import { applyFavoritesFilter } from './applyFavoritesFilter';
import { getCardOwner } from './getCardOwner';

export const applyArchiveFilter = (
  cards: Card[],
  filter: string,
  currentUserId: string | null,
  getRequestsForCard?: (cardId: string) => Request[],
  favoriteCards?: CollectionCard[]
) => {
  switch (filter) {
    case FilterTypes.ArchivedCards:
      return cards.filter((card) => card.state === 'archived');
    case FilterTypes.CompletedCards:
      return cards.filter((card) => card.state === 'completed');
    case FilterTypes.CardsIOwn:
      return cards.filter((card) => {
        const cardOwner = getCardOwner(card);
        return cardOwner === currentUserId;
      });
    case FilterTypes.CardsIContributedTo:
      return cards.filter((card) => {
        const cardOwner = getCardOwner(card);
        const requests = getRequestsForCard?.(card.id);

        const isCardOwner = cardOwner === currentUserId;
        const isCardCreator = card.creator_id === currentUserId;

        if (requests?.length) {
          return (
            isCardOwner ||
            isCardCreator ||
            requests.some((request) => request.requester_id === currentUserId || request.responder_id === currentUserId)
          );
        }

        return isCardOwner || isCardCreator;
      });
    case FilterTypes.Favorites:
      return applyFavoritesFilter(cards, favoriteCards);
    default:
      return cards;
  }
};

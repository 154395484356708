import { Avatar, Group, Text, Tooltip } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../../../../../../../../hooks';
import { getNearestDeadline } from '../../../../../../../../utils';
import { DeadlineBadge } from '../../../../../../../DeadlineBadge';
import { WaitingForProps } from './types';

export const WaitingFor: FC<WaitingForProps> = ({ waitingForRequests }) => {
  const { getUser } = useUsersManager();

  const waitingForRequestsUsers = waitingForRequests.map((request) => ({
    ...request,
    requester: getUser(request.requester_id),
    responder: getUser(request.responder_id),
  }));
  const nearestDeadline = getNearestDeadline(waitingForRequestsUsers);

  if (waitingForRequestsUsers.length === 0) {
    return null;
  }

  return (
    <Group gap={8}>
      <Text>Waiting for: </Text>
      <Tooltip.Group openDelay={300} closeDelay={100}>
        <Avatar.Group>
          {waitingForRequestsUsers.map(({ id, requester, responder, title }) => (
            <Tooltip
              key={id}
              label={`${requester?.fullname} -> ${responder?.fullname}: ${title}`}
              multiline
              withArrow
              maw={320}
            >
              <Avatar size="sm" radius="xl" src={responder?.avatar} alt={responder?.displayname} />
            </Tooltip>
          ))}
        </Avatar.Group>
      </Tooltip.Group>
      {nearestDeadline && <DeadlineBadge tdeadline={nearestDeadline} isComplete={false} size="xs" />}
    </Group>
  );
};

import { Center, Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FC } from 'react';

import { labels, messages } from './constants';
import { RequestTypeItemProps } from './types';

export const RequestTypeItem: FC<RequestTypeItemProps> = ({ requestType }) => {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover position="top" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        <Center onMouseEnter={open} onMouseLeave={close} style={{ gap: 10 }}>
          <span>{labels[requestType]}</span>
        </Center>
      </Popover.Target>
      <Popover.Dropdown style={{ pointerEvents: 'none' }}>{messages[requestType]}</Popover.Dropdown>
    </Popover>
  );
};

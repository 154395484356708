import dayjs from 'dayjs';

import { SortTypes } from '../../../../../components/Sorting/types';
import { Card } from '../../../../../data';
import { applyArchiveFilter, applyCombinedSearchFilter } from '../../../../../utils';
import { GetArchiveCardsProps } from './types';

const isCardInRange = ({ tcreated }: Card, range: string) => {
  const now = dayjs();

  const yearCreated = dayjs(tcreated).year();
  const yearNow = now.year();
  const monthCreated = dayjs(tcreated).month();
  const monthNow = now.month();

  switch (range) {
    case 'lastyear':
      return yearCreated === yearNow - 1;
    case 'thisyear':
      return yearCreated === yearNow;
    case 'lastmonth':
      return (
        (monthNow === 0 && yearCreated === yearNow - 1 && monthCreated === 11) ||
        (yearCreated === yearNow && monthCreated === monthNow - 1)
      );
    case 'thismonth':
      return yearCreated === yearNow && monthCreated === monthNow;
    default:
      return true;
  }
};

const sortCompletedCards = (cards: Card[], sortField: string, sortOrder: boolean) => {
  let sortedCards = cards.sort((a, b) => {
    if (sortField === SortTypes.Modified) {
      return (a.tmodified || a.tcreated) - (b.tmodified || b.tcreated);
    }
    if (sortField === SortTypes.Title) {
      return a.description.localeCompare(b.description);
    }
    if (sortField === SortTypes.Owner) {
      return (a.creator?.fullname || '').localeCompare(b.creator?.fullname || '');
    }
    return a.tcreated - b.tcreated;
  });

  // Reverse the sorted card set if desired
  if (sortOrder) {
    sortedCards = sortedCards.reverse();
  }

  return sortedCards;
};

export const getArchiveCards = ({
  cards,
  range,
  filters,
  getUser,
  currentUserId,
  favoriteCards,
  getRequestsForCard,
}: GetArchiveCardsProps) => {
  const { sortField, sortOrder, searchQuery, filter } = filters;

  const completedCards = cards.reduce<Card[]>((acc, card) => {
    if ((card.state !== 'completed' && card.state !== 'archived') || !isCardInRange(card, range)) {
      return acc;
    }

    return [...acc, card];
  }, []);

  const sortedCards = sortCompletedCards(completedCards, sortField, sortOrder);

  if (searchQuery !== '') {
    return applyCombinedSearchFilter(sortedCards, searchQuery, getUser);
  }

  if (filter) {
    return applyArchiveFilter(sortedCards, filter, currentUserId, getRequestsForCard, favoriteCards);
  }

  return sortedCards;
};

import { FC } from 'react';

import { AuthGuard } from '../../guards';
import { useSetDocumentTitle } from '../../hooks';

export const EmptyPage: FC = () => {
  useSetDocumentTitle('Only Choices');

  return <AuthGuard />;
};

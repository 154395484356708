export const getVersionName = (version: any) => {
  if (version.name) {
    return version.name;
  }

  if (version.version === 0) {
    return 'Initial version';
  }

  return `Version ${version.version}`;
};

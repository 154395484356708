import { Group, Text } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../../../../../../../../hooks';
import { AvatarRequestsGroup } from '../../../../../../../AvatarRequestsGroup';
import { InProgressRequestsProps } from './types';

export const InProgressRequests: FC<InProgressRequestsProps> = ({ inProgressRequests }) => {
  const { getUser } = useUsersManager();

  const inProgressRequestsWithUsers = inProgressRequests.map((request) => ({
    ...request,
    user: getUser(request.responder_id),
  }));

  if (inProgressRequestsWithUsers.length === 0) {
    return null;
  }

  return (
    <Group gap={8}>
      <Text>In Progress </Text>
      <AvatarRequestsGroup requests={inProgressRequestsWithUsers} />
    </Group>
  );
};

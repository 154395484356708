import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import { AUTH_STATE_KEY } from './constants/localStorage';
import { setAuthHeaders } from './contexts/AuthContext/utils';
import reportWebVitals from './reportWebVitals';
import { authService } from './services';
import { api } from './services/api';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Catch 401 error and recreate the token
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const authState = localStorage.getItem(AUTH_STATE_KEY);

      if (!authState) {
        return Promise.reject(error);
      }

      const { refreshToken, account, currentUserId } = JSON.parse(authState);

      if (!refreshToken || !account || !currentUserId) {
        return Promise.reject(error);
      }

      setAuthHeaders(refreshToken);

      const { token: accessToken } = await authService.getAccessToken(account, currentUserId);
      localStorage.setItem(AUTH_STATE_KEY, JSON.stringify({ refreshToken, account, currentUserId, accessToken }));

      setAuthHeaders(accessToken);

      return api.request(error.config);
    }

    return Promise.reject(error);
  }
);

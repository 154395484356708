import { useLocation, useParams } from 'react-router-dom';

import { getHomeRoute } from '../../utils';

export const useIsMyStream = () => {
  const { accountId } = useParams();
  const { pathname } = useLocation();

  const isMyStream = Boolean(accountId && getHomeRoute(accountId) === pathname);

  return isMyStream;
};

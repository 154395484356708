import { SortableCard, SortByPriorityParams } from '../types';
import { compareByDeadlineOrModified } from './compareByDeadlineOrModified';

export const sortCardsByNudge = ({
  cards,
  userId,
  nudged,
}: Pick<SortByPriorityParams, 'cards' | 'userId' | 'nudged'>): SortableCard[] =>
  cards
    .map((card) => {
      const { id } = card;

      const nudge = nudged.find(
        ({ card_id, user_id, tuntil }) => card_id === id && user_id === userId && tuntil > Date.now()
      );

      return { ...card, tlatestModified: nudge?.tcreated ?? Infinity };
    })
    .sort(compareByDeadlineOrModified);

import { Text, Tooltip } from '@mantine/core';
import { FC } from 'react';
import ReactTimeAgo from 'react-time-ago';

import { Card } from '../../data';
import { formatDate } from './utils';

interface CardHeaderTimeAgoProps {
  card: Card;
}

export const CardHeaderTimeAgo: FC<CardHeaderTimeAgoProps> = ({ card }) => {
  const tooltip = `
    Created: ${formatDate(card.tcreated)}
    ${card.tmodified !== null ? `Modified: ${formatDate(card.tmodified)}` : ''}
  `;

  return (
    <Tooltip label={tooltip} multiline withArrow maw={280}>
      <Text fz="sm" c="grey">
        <ReactTimeAgo tooltip={false} date={card.tmodified !== null ? card.tmodified : card.tcreated} locale="en-US" />
      </Text>
    </Tooltip>
  );
};

import { Card, Request } from '../../../../../../../../../data';
import { isNudgePossible } from './isNudgePossible';
import { isWithdrawPossible } from './isWithdrawPossible';

interface ShouldDisplayActionPanelProps {
  card: Card;
  request: Request;
  currentUserId: string | null;
}

export const shouldDisplayActionPanel = ({ card, request, currentUserId }: ShouldDisplayActionPanelProps) =>
  isWithdrawPossible({ request, currentUserId }) || isNudgePossible({ card, request, currentUserId });

import { Button, ButtonProps, PolymorphicComponentProps } from '@mantine/core';
import cs from 'classnames';
import { FC } from 'react';

import classNames from './CustomButton.module.css';

export const CustomButton: FC<PolymorphicComponentProps<'button', ButtonProps>> = ({
  children,
  className,
  ...props
}) => (
  <Button {...props} className={cs(classNames.customButton, className)}>
    {children}
  </Button>
);

import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';

import { Card, Collection, CollectionType } from '../../data';
import { useCardsManager } from '../useCardsManager';

export const useRemoveFromCollection = (card: Card, collection: Collection) => {
  const { removeCollectionCard } = useCardsManager();

  const removeFromCollection = async () => {
    await removeCollectionCard({ cardId: card.id, collectionId: collection.id });
  };

  const shortTitle = collection.title.length > 20 ? `${collection.title.slice(0, 20)}...` : collection.title;
  const type = collection.type === CollectionType.Stream ? 'stream' : 'meeting';

  const openModal = () =>
    modals.openConfirmModal({
      title: 'Warning: This cannot be undone!',
      children: (
        <Text size="sm">
          Please confirm that you want to delete this card from the &apos;{shortTitle}&apos; {type}.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: removeFromCollection,
    });

  return openModal;
};

import { defaultVariantColorsResolver, parseThemeColor, VariantColorsResolver } from '@mantine/core';

export const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  });

  if (!parsedColor.isThemeColor) {
    return defaultResolvedColors;
  }

  switch (parsedColor.color) {
    case 'blue':
      return {
        ...defaultResolvedColors,
        background: 'var(--mantine-color-blue-0)',
        color: '#004BAF',
      };
    case 'grape':
      return {
        ...defaultResolvedColors,
        background: 'var(--mantine-color-grape-0)',
        color: '#4E3B9B',
      };
    case 'yellow':
      return {
        ...defaultResolvedColors,
        background: 'var(--mantine-color-yellow-0)',
        color: 'var(--mantine-color-orange-9)',
      };
    case 'green':
      return {
        ...defaultResolvedColors,
        background: '#6FCF97',
        color: '#E9FFF2',
      };
    case 'orange':
      return {
        ...defaultResolvedColors,
        background: '#FE923B',
        color: '#F9F4F0',
      };
    case 'red':
      return {
        ...defaultResolvedColors,
        background: '#FFECE8',
        color: '#9B001C',
      };
    default:
      return defaultResolvedColors;
  }
};

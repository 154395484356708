import { Modal } from '@mantine/core';
import { FC } from 'react';

import { WebhookForm } from './components';
import { AddWebhookModalProps } from './types';

export const AddWebhookModal: FC<AddWebhookModalProps> = ({ isOpened, onClose }) => (
  <Modal opened={isOpened} onClose={onClose} title="Add Teams Webhook" size="md">
    <WebhookForm onClose={onClose} />
  </Modal>
);

import { Template } from '../data';
import { api } from './api';

export const templatesService = {
  getTemplates: async () => {
    const response = await api.get<Template[]>('/templates');

    return response.data;
  },
  createTemplate: async (template: Partial<Template>) => {
    const response = await api.post<Template>('/templates', template);

    return response.data;
  },
  updateTemplate: async ({ id, ...template }: Partial<Template>) => {
    const response = await api.put<Template>(`/templates/${id}`, template);

    return response.data;
  },

  deleteTemplate: async (id: string) => {
    await api.delete(`/templates/${id}`);
  },
};

import { Badge as MantineBadge, BadgeProps, MantineProvider, PolymorphicComponentProps } from '@mantine/core';
import { FC } from 'react';

import classNames from './Badge.module.css';
import { variantColorResolver } from './utils';

export const Badge: FC<PolymorphicComponentProps<'div', BadgeProps>> = ({ children, ...props }) => (
  <MantineProvider theme={{ variantColorResolver }}>
    <MantineBadge variant="light" className={classNames.badge} {...props}>
      {children}
    </MantineBadge>
  </MantineProvider>
);

import { Card, User } from '../data';
import { getCardOwner } from './getCardOwner';

export const applyCombinedSearchFilter = (
  cards: Card[],
  searchQuery: string,
  getUser: (id: string) => User | undefined
) => {
  if (searchQuery === '') {
    return cards;
  }

  const lowerQuery = searchQuery.toLowerCase();
  return cards.filter((card) => {
    const matchesDescription = card.description.toLowerCase().includes(lowerQuery);
    if (matchesDescription) {
      return true;
    }

    const cardOwner = getCardOwner(card);
    const user = getUser(cardOwner);
    return user?.fullname.toLowerCase().includes(lowerQuery);
  });
};

import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';

import { useWebhooksManager } from '../../../../../../hooks';

export const useRemoveWebhook = () => {
  const webhooksManager = useWebhooksManager();

  const removeWebhook = async (webhookId: string) => {
    await webhooksManager.removeWebhook(webhookId);
  };

  const openModal = (webhookId: string) =>
    modals.openConfirmModal({
      title: 'Warning: This cannot be undone!',
      children: <Text size="sm">Please confirm that you want to delete this webhook forever.</Text>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => removeWebhook(webhookId),
    });

  return openModal;
};

import { Avatar, Badge, Group, Tooltip } from '@mantine/core';
import { FC } from 'react';

import { AvatarsListProps } from './types';

export const AvatarsList: FC<AvatarsListProps> = ({ color, requests, children }) => (
  <Badge
    size="xl"
    variant="gradient"
    gradient={{ from: `${color}.4`, to: `${color}.6`, deg: 135 }}
    px={4}
    style={{
      boxShadow: '0px 4px 5px -2px #0000002B',
      cursor: 'pointer',
    }}
  >
    <Group gap={6}>
      {children}
      <Tooltip.Group openDelay={300} closeDelay={100}>
        <Avatar.Group spacing="xs">
          {requests.map(({ id, user, comment }) => (
            <Tooltip
              key={id}
              label={comment ? `${user?.fullname}: ${comment}` : user?.fullname}
              multiline
              withArrow
              maw={320}
            >
              <Avatar size="sm" src={user?.avatar} alt={user?.displayname} style={{ borderWidth: 0 }} />
            </Tooltip>
          ))}
        </Avatar.Group>
      </Tooltip.Group>
    </Group>
  </Badge>
);

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { CollectionType } from '../../../data';
import { useCollectionsManager } from '../../../hooks';
import { generateNavGroups } from './utils';

export const useNavigationGroups = () => {
  const { accountId } = useParams();
  const { collections } = useCollectionsManager();

  const navigationGroups = useMemo(() => {
    if (!accountId) {
      return [];
    }

    const streams = collections?.filter((c) => c.type === CollectionType.Stream);
    const meetings = collections?.filter((c) => c.type === CollectionType.Meeting);

    return generateNavGroups(accountId, streams, meetings);
  }, [accountId, collections]);

  return { isLoading: collections == null, navigationGroups };
};

import { Collection, CollectionCard } from '../data';
import { api } from './api';

export const collectionsService = {
  getCollections: async () => {
    const response = await api.get<Collection[]>('/collections');

    return response.data;
  },
  getCollectionCards: async () => {
    const response = await api.get<CollectionCard[]>('/collections/cards');

    return response.data;
  },
  createCollection: async (collection: CreateCollectionParams) => {
    const response = await api.post<Collection>('/collections', collection);

    return response.data;
  },
  updateCollection: async ({ id, ...collection }: UpdateCollectionParams) => {
    const response = await api.put<Collection>(`/collections/${id}`, collection);

    return response.data;
  },
  removeCollection: async (id: string) => {
    await api.delete(`/collections/${id}`);
  },
  addCardToCollection: async ({ collectionId, cardId }: AddCardToCollectionParams) => {
    const response = await api.post<CollectionCard>(`/collections/${collectionId}/${cardId}`);

    return response.data;
  },
  removeCardFromCollection: async ({ collectionId, cardId }: RemoveCardFromCollectionParams) => {
    await api.delete(`/collections/${collectionId}/${cardId}`);
  },
};

export interface CreateCollectionParams {
  title: string;
  description: string;
  type: string;
  header: string;
  icon: string;
}

export interface UpdateCollectionParams extends CreateCollectionParams {
  id: string;
}

export interface AddCardToCollectionParams {
  collectionId: string;
  cardId: string;
}

export interface RemoveCardFromCollectionParams {
  collectionId: string;
  cardId: string;
}

import { Stack, Text, UnstyledButton } from '@mantine/core';
import { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classes from './NavigationLink.module.css';
import { NavigationLinkProps } from './types';

export const NavigationLink = forwardRef<HTMLDivElement, NavigationLinkProps>(
  ({ link, label, onClick, children }, ref) => {
    const { pathname } = useLocation();

    const baseProps = { className: classes.navigationLink, onClick };

    if (!link) {
      return (
        <Stack gap="4px" align="center" justify="center" ref={ref}>
          <UnstyledButton {...baseProps}>{children}</UnstyledButton>
          {label && (
            <Text size="xs" ta="center">
              {label}
            </Text>
          )}
        </Stack>
      );
    }

    return (
      <Stack gap="4px" align="center" justify="center" ref={ref}>
        <UnstyledButton component={Link} to={link} data-active={link === pathname || undefined} {...baseProps}>
          {children}
        </UnstyledButton>
        {label && (
          <Text component={Link} to={link ?? ''} size="xs" ta="center">
            {label}
          </Text>
        )}
      </Stack>
    );
  }
);

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { REFETCH_INTERVAL } from '../constants/query';
import { useAuthContext } from '../contexts/AuthContext';
import { usersService } from '../services';
import { profileService } from '../services/profileService';

export const useUsersManager = () => {
  const { currentUserId, isAuthenticated } = useAuthContext();
  const queryClient = useQueryClient();

  const { data: users, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: usersService.getUsers,
    enabled: !!isAuthenticated,
    initialData: [],
    retry: false,
    refetchInterval: REFETCH_INTERVAL,
  });

  const updateProfileAvatarMutation = useMutation({
    mutationFn: profileService.uploadAvatar,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  const currentUser = useMemo(() => users.find((u) => u.id === currentUserId), [users, currentUserId]);
  const getUser = useCallback((id: string) => users.find((u) => u.id === id), [users]);

  return {
    currentUser,
    users,
    getUser,
    isLoading,
    updateProfileAvatar: updateProfileAvatarMutation.mutateAsync,
  };
};

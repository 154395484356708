import { Button, Group, Modal, Stack, useCombobox } from '@mantine/core';
import { FC, useMemo, useState } from 'react';

import { UsersSelect } from '../../../../components/UsersSelect';
import { useCardsManager, useUsersManager } from '../../../../hooks';
import { DelegateCardModalProps } from './types';

export const DelegateCardModal: FC<DelegateCardModalProps> = ({ card, isOpened, onClose }) => {
  const { updateCard } = useCardsManager();
  const { users } = useUsersManager();

  const [value, setValue] = useState<string>('');

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const handleDelegateCard = async () => {
    if (value) {
      await updateCard({ ...card, owner_id: value, id: card.id });
    }
    setValue('');
    onClose();
  };

  const filteredUsers = useMemo(() => users.filter((user) => user.id !== card.owner_id), [users, card.owner_id]);

  return (
    <Modal opened={isOpened} onClose={onClose} title="Select User">
      <Stack>
        <UsersSelect
          users={filteredUsers}
          currentRequestTarget={value}
          setCurrentRequestTarget={setValue}
          placeholder="Delegate To"
        />
        <Group>
          <Button disabled={value === null || value === ''} onClick={handleDelegateCard}>
            Delegate the card
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAuthContext } from '../../../../../contexts';
import { authService } from '../../../../../services';

export const useAccounts = () => {
  const { isAuthenticated, currentUserId, handleSelectAccount } = useAuthContext();

  const { data: accounts, error } = useQuery({
    queryKey: ['accounts'],
    queryFn: authService.getAccounts,
    enabled: Boolean(currentUserId) && !isAuthenticated,
    initialData: null,
    retry: false,
  });

  const filteredAccounts = useMemo(() => accounts?.filter((account) => !account.is_deleted), [accounts]);

  return { accounts: filteredAccounts, error, handleSelectAccount };
};

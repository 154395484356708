import { ActionIcon, px } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { FC } from 'react';

import { useCollectionModalContext } from '../../../../../contexts';
import { NewCollectionBtnProps } from './types';

export const NewCollectionBtn: FC<NewCollectionBtnProps> = ({ collectionType }) => {
  const { openCollectionModal } = useCollectionModalContext();

  return (
    <ActionIcon variant="transparent" onClick={() => openCollectionModal(collectionType)}>
      <IconPlus size={px('1.3rem')} color="grey" />
    </ActionIcon>
  );
};

import { Container, LoadingOverlay, Paper, ScrollArea, Stack, Text, Title } from '@mantine/core';
import { FC, useCallback, useState } from 'react';

import { Error } from '../Error';
import { useAccounts } from './hooks';

export const AccountsList: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectingError, setIsSelectingError] = useState(true);

  const { accounts, handleSelectAccount, error } = useAccounts();

  const onSelectAccount = useCallback(
    async (accountId: string) => {
      setIsLoading(true);
      setIsSelectingError(false);

      try {
        await handleSelectAccount(accountId);
      } catch (e) {
        setIsSelectingError(true);
      }

      setIsLoading(false);
    },
    [handleSelectAccount]
  );

  return (
    <Container pb={16} h="100%" style={{ overflow: 'auto' }}>
      <ScrollArea.Autosize w="100%" type="auto">
        <Title ta="center" mb="md">
          Select an account
        </Title>
        <Stack>
          <LoadingOverlay visible={isLoading} loaderProps={{ children: 'Loading...' }} />

          {!!isSelectingError && <Error>There was an error while selecting the account. Please try again.</Error>}

          {accounts?.map((account) => (
            <Paper
              key={account.id}
              style={{ cursor: 'pointer' }}
              withBorder
              p="md"
              onClick={() => {
                onSelectAccount(account.id);
              }}
            >
              <Stack>
                <Title size="md">{account.name}</Title>
                <Text>{account.description}</Text>
              </Stack>
            </Paper>
          ))}

          {!!error && <Error>{error.message}</Error>}
        </Stack>
      </ScrollArea.Autosize>
    </Container>
  );
};

import { Menu } from '@mantine/core';
import { IconPlugConnected, IconTemplate, IconTools, IconUser, IconUsersGroup } from '@tabler/icons-react';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import { getIntegrationsRoute, getProfileRoute, getTemplatesRoute, getUsersRoute } from '../../../../../utils';
import { NavigationLink } from '../NavigationLink';

export const ToolsMenu: FC = () => {
  const { accountId } = useParams();

  if (!accountId) {
    return null;
  }

  return (
    <Menu shadow="md" position="right" width={200} offset={0} withArrow arrowPosition="center">
      <Menu.Target>
        <NavigationLink label="Tools">
          <IconTools stroke="1.5" />
        </NavigationLink>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item component={Link} to={getProfileRoute(accountId)} leftSection={<IconUser stroke="1.5" />} py={8}>
          Profile
        </Menu.Item>
        <Menu.Item component={Link} to={getUsersRoute(accountId)} leftSection={<IconUsersGroup stroke="1.5" />} py={8}>
          Users
        </Menu.Item>
        <Menu.Item
          component={Link}
          to={getIntegrationsRoute(accountId)}
          leftSection={<IconPlugConnected stroke="1.5" />}
          py={8}
        >
          Integrations
        </Menu.Item>
        <Menu.Item
          component={Link}
          to={getTemplatesRoute(accountId)}
          leftSection={<IconTemplate stroke="1.5" />}
          py={8}
        >
          Templates
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

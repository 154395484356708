import { Group, Text } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../../../../../../../../hooks';
import { AvatarRequestsGroup } from '../../../../../../../AvatarRequestsGroup';
import { CompletedRequestsProps } from './types';

export const CompletedRequests: FC<CompletedRequestsProps> = ({ completedRequests }) => {
  const { getUser } = useUsersManager();

  const completedRequestsWithUsers = completedRequests.map((request) => ({
    ...request,
    user: getUser(request.responder_id),
  }));

  if (completedRequestsWithUsers.length === 0) {
    return null;
  }

  return (
    <Group gap={8}>
      {completedRequestsWithUsers.length === 1 && (
        <Group>
          <Text>Request done </Text>
        </Group>
      )}
      {completedRequestsWithUsers.length > 1 && <Text>Requests done </Text>}
      <AvatarRequestsGroup requests={completedRequestsWithUsers} />
    </Group>
  );
};

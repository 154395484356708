import { ActionIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { IconStar, IconStarFilled } from '@tabler/icons-react';
import { FC, MouseEvent, useCallback } from 'react';

import { useFavorites } from '../../hooks';
import { FavoriteBtnProps } from './types';

export const FavoriteBtn: FC<FavoriteBtnProps> = ({ cardId }) => {
  const theme = useMantineTheme();
  const { isCardFavorite, handleToggleFavoriteCard } = useFavorites();

  const isFavorite = isCardFavorite(cardId);

  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      handleToggleFavoriteCard(cardId);
    },
    [cardId, handleToggleFavoriteCard]
  );

  return (
    <Tooltip label="Favorite" withArrow>
      <ActionIcon variant="transparent" radius="xl" onClick={onClick}>
        {isFavorite && <IconStarFilled color={theme.colors.yellow[4]} size="20px" />}
        {!isFavorite && <IconStar color={theme.colors.gray[6]} size="20px" />}
      </ActionIcon>
    </Tooltip>
  );
};

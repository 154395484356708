import { StreamCategory } from '../../../../../../types';
import { getCardOwner, isNonOpenRequest, isWaitingForRequest } from '../../../../../../utils';
import { FIFTEEEN_MINUTES } from './constants';
import { SortByPriorityParams, SortByPriorityResult } from './types';
import { sortCardsByNudge, sortCardsByResponseTime, sortCardsByTime } from './utils';

export const sortByPriority = ({ cards, nudged, cardRequests, userId }: SortByPriorityParams): StreamCategory[] => {
  const { newCards, nudgedCards, cardsWithOpenRequests, cardsWithResponseToMe, myCards, otherCards } =
    cards.reduce<SortByPriorityResult>(
      (acc, card) => {
        const { id } = card;
        const requests = cardRequests[id];

        const isMyNewCard = getCardOwner(card) === userId && card.tcreated > Date.now() - FIFTEEEN_MINUTES;

        if (isMyNewCard) {
          acc.newCards.push(card);
          return acc;
        }

        const isNudged = nudged.some(
          ({ card_id, user_id, tuntil }) => card_id === id && user_id === userId && tuntil > Date.now()
        );
        const isCardWithOpenRequest = requests.some((req) => req.responder_id === userId && isWaitingForRequest(req));

        if (isNudged && isCardWithOpenRequest) {
          acc.nudgedCards.push(card);
          return acc;
        }
        if (isCardWithOpenRequest) {
          acc.cardsWithOpenRequests.push(card);
          return acc;
        }

        const isCardWithResponseToMe = requests.some((req) => req.requester_id === userId && isNonOpenRequest(req));
        if (isCardWithResponseToMe) {
          acc.cardsWithResponseToMe.push(card);
          return acc;
        }

        if (getCardOwner(card) === userId) {
          acc.myCards.push(card);
          return acc;
        }

        acc.otherCards.push(card);

        return acc;
      },
      {
        newCards: [],
        nudgedCards: [],
        cardsWithOpenRequests: [],
        cardsWithResponseToMe: [],
        myCards: [],
        otherCards: [],
      }
    );

  return [
    { id: 'newCards', title: 'Newly created cards', cards: sortCardsByTime({ cards: newCards, cardRequests, userId }) },
    { id: 'nudgedCards', title: "I've been nudged", cards: sortCardsByNudge({ cards: nudgedCards, userId, nudged }) },
    {
      id: 'cardsWithOpenRequests',
      title: 'Open Requests to Me',
      cards: sortCardsByTime({ cards: cardsWithOpenRequests, cardRequests, userId }),
    },
    {
      id: 'cardsWithResponseToMe',
      title: 'Responded to My Request',
      cards: sortCardsByResponseTime({ cards: cardsWithResponseToMe, cardRequests, userId }),
    },
    { id: 'myCards', title: 'Other Cards I Own', cards: sortCardsByTime({ cards: myCards, cardRequests, userId }) },
    { id: 'otherCards', title: 'All Other Cards', cards: sortCardsByTime({ cards: otherCards, cardRequests, userId }) },
  ];
};

import { Combobox, Input, InputBase, useCombobox } from '@mantine/core';
import { FC, useMemo, useState } from 'react';

import { UserAvatar } from '../UserAvatar';
import { UsersSelectProps } from './types';
import { filterUsers, getSortedUsers } from './utils';

export const UsersSelect: FC<UsersSelectProps> = ({
  users,
  currentRequestTarget,
  setCurrentRequestTarget,
  width,
  placeholder = 'To',
}) => {
  const [search, setSearch] = useState('');
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const sortedUsers = useMemo(() => getSortedUsers(users), [users]);
  const filteredOptions = useMemo(() => filterUsers(sortedUsers, search), [sortedUsers, search]);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        setCurrentRequestTarget(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          style={{ width: width ?? '100%' }}
          component="button"
          pointer
          rightSection={<Combobox.Chevron />}
          onClick={() => combobox.toggleDropdown()}
        >
          {currentRequestTarget !== '' ? (
            <UserAvatar user_id={currentRequestTarget} />
          ) : (
            <Input.Placeholder>{placeholder}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search users"
        />
        <Combobox.Options>
          {filteredOptions.length > 0 &&
            filteredOptions.map(({ id }) => (
              <Combobox.Option value={id} key={id}>
                <UserAvatar user_id={id} short />
              </Combobox.Option>
            ))}
          {filteredOptions.length === 0 && <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

import { Box } from '@mantine/core';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { FullCard } from './components';

export const CardPage: FC = () => {
  const { cardId } = useParams();

  if (cardId === undefined) {
    throw new Error('CardPage: card_id is undefined');
  }

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <Box p="sm">
        <FullCard cardId={cardId} />
      </Box>
    </div>
  );
};

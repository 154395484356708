import { MantineStyleProp } from '@mantine/core';

export const controlSx: MantineStyleProp = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0px 8px 0',
  gap: '4px',
  borderRadius: '4px',
  borderWidth: '1px',
};

import { Avatar, Group, Text, Tooltip } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../../../../../../../../hooks';
import { LetsMeetRequestsProps } from './types';

export const LetsMeetRequests: FC<LetsMeetRequestsProps> = ({ letsMeetRequests }) => {
  const { getUser } = useUsersManager();

  const requestsWithUsers = letsMeetRequests.map((request) => ({
    ...request,
    requester: getUser(request.requester_id),
    responder: getUser(request.responder_id),
  }));

  if (requestsWithUsers.length === 0) {
    return null;
  }

  return (
    <>
      {requestsWithUsers.map(({ id, requester, responder, title, comment }) => (
        <Group gap={8} key={id}>
          <Text>Let&apos;s Meet for: </Text>

          <Tooltip.Group>
            <Avatar.Group>
              <Tooltip
                label={title ? `${requester?.fullname}: ${title}` : requester?.fullname}
                multiline
                withArrow
                maw={320}
              >
                <Avatar size="sm" radius="xl" src={requester?.avatar} alt={requester?.displayname} />
              </Tooltip>
              <Tooltip
                label={comment ? `${responder?.fullname}: ${comment}` : responder?.fullname}
                multiline
                withArrow
                maw={320}
              >
                <Avatar size="sm" radius="xl" src={responder?.avatar} alt={responder?.displayname} />
              </Tooltip>
            </Avatar.Group>
          </Tooltip.Group>
        </Group>
      ))}
    </>
  );
};

import { useMemo } from 'react';

import { Card, Request, RequestTypes } from '../data';
import { RequestWithUser } from '../types';
import { useUsersManager } from './useUsersManager';

interface Props {
  card: Card;
  cardRequests: Record<string, Request[]>;
}

export const useApprovalRequests = ({ card, cardRequests }: Props): RequestWithUser[] => {
  const { getUser } = useUsersManager();

  const approvalRequests = useMemo(
    () =>
      cardRequests[card.id]
        .filter(({ request_type }) => request_type === RequestTypes.Approval)
        .map((request) => ({
          ...request,
          user: getUser(request.responder_id),
        })),
    [card.id, cardRequests, getUser]
  );

  return approvalRequests;
};

import { Button, Center, Group, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FC } from 'react';

import { BackgroundImage } from '../../../../components/BackgroundImage';
import { PageHeader } from '../../../../components/PageHeader';
import { INTEGRATIONS_DEFAULT_POSTER } from '../../../../constants/backgroundImage';
import { AddWebhookModal } from './components';

export const Header: FC = () => {
  const [isCreateWebhookOpened, createWebhookHandlers] = useDisclosure(false);

  return (
    <BackgroundImage src={INTEGRATIONS_DEFAULT_POSTER}>
      <PageHeader />
      <Stack>
        <Center>
          <Text fz="50px" pt="0" mt="0" mb="0" pb="0" style={{ textShadow: '0px 0px 12px white' }}>
            Integrations
          </Text>
        </Center>
      </Stack>
      <Group align="center" justify="space-between" mt="lg" pl="sm" pr="sm" pb="sm">
        <Group>
          <Button onClick={createWebhookHandlers.open}>Add Teams Integration</Button>
        </Group>
      </Group>
      <AddWebhookModal isOpened={isCreateWebhookOpened} onClose={createWebhookHandlers.close} />
    </BackgroundImage>
  );
};

import { useMemo } from 'react';

import { Collection, CollectionCard } from '../../../data';
import { useCardsManager } from '../../../hooks';
import { StreamCategory } from '../../../types';

export const useMeetingCategories = (collection?: Collection): StreamCategory[] => {
  const { cards, getCardsForCollection } = useCardsManager();

  return useMemo(() => {
    const collectionCards = collection ? getCardsForCollection(collection.id) : [];

    const cardMap: { [key: string]: CollectionCard } = {};
    collectionCards.forEach((collectionCard) => {
      cardMap[collectionCard.card_id] = collectionCard;
    });

    if (cards.length === 0) {
      return [];
    }

    return [{ id: 'meetingCards', cards: cards.filter((card) => cardMap[card.id]) }];
  }, [cards, collection, getCardsForCollection]);
};

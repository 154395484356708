import { Box, ScrollArea } from '@mantine/core';
import { FC, useMemo, useRef, useState } from 'react';

import { EmptyStream } from '../../components/EmptyStream';
import { StreamColumns } from '../../components/StreamColumns';
import { StreamList } from '../../components/StreamList';
import { useAuthContext } from '../../contexts';
import { useCardsManager, useFilters, useRequestsManager, useSetDocumentTitle } from '../../hooks';
import { getCardRequestsMap } from '../../utils';
import { StreamPageHeader, StreamReport } from './components';
import { useCollection, useStreamCategories } from './hooks';
import { LayoutTypes } from './types';
import { getDocumentTitle } from './utils';

export const StreamPage: FC = () => {
  const { requests } = useRequestsManager();
  const { cards } = useCardsManager();
  const { currentUserId } = useAuthContext();

  const [groupField, setGroupField] = useState('state');
  const [layoutField, setLayoutField] = useState(LayoutTypes.Stream);

  const { collection, isMyStream, isFavorites } = useCollection();

  const { filters, handleFilterChange } = useFilters();

  useSetDocumentTitle(getDocumentTitle(isMyStream, isFavorites));

  const viewport = useRef<HTMLDivElement>(null);

  const cardRequests = useMemo(() => getCardRequestsMap(requests, cards), [requests, cards]);
  const streamCategories = useStreamCategories({
    cardRequests,
    collection,
    filters,
  });

  if (!currentUserId) {
    return null;
  }

  return (
    <div style={{ paddingBottom: '16px', height: '100%', overflow: 'auto' }}>
      <ScrollArea.Autosize w="100%" type="auto" viewportRef={viewport}>
        <StreamPageHeader
          collection={collection}
          groupField={groupField}
          setGroupField={setGroupField}
          layoutField={layoutField}
          setLayoutField={setLayoutField}
          filters={filters}
          onFilterChanged={handleFilterChange}
        />
        <Box pl="sm" pt="sm" pr="sm">
          {layoutField === LayoutTypes.Stream && <StreamList id={collection?.id} streamCategories={streamCategories} />}
          {layoutField === LayoutTypes.Table && (
            <StreamReport cardRequests={cardRequests} group={groupField} streamCategories={streamCategories} />
          )}
          {layoutField === LayoutTypes.Columns && (
            <StreamColumns group={groupField} streamCategories={streamCategories} />
          )}
          {streamCategories.length === 0 && <EmptyStream />}
        </Box>
      </ScrollArea.Autosize>
    </div>
  );
};

import { User } from '../data';
import { api } from './api';

export const usersService = {
  getUsers: async () => {
    const response = await api.get<User[]>('/users');

    return response.data;
  },
};

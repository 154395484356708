import { Stack } from '@mantine/core';
import { FC } from 'react';

import { useSetDocumentTitle, useUsersManager } from '../../hooks';
import { PasswordChangeForm, UserDetails } from './components';

export const ProfilePage: FC = () => {
  const { currentUser } = useUsersManager();

  useSetDocumentTitle('Profile');

  if (!currentUser) {
    return null;
  }

  return (
    <div style={{ padding: '16px', height: '100%', overflow: 'auto' }}>
      <Stack gap="32px" align="center">
        <UserDetails />
        <PasswordChangeForm />
      </Stack>
    </div>
  );
};

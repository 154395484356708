import { BackgroundImage, Center, Stack, Text } from '@mantine/core';
import { FC } from 'react';

import { PageHeader } from '../../../../components/PageHeader';
import { USERS_DEFAULT_POSTER } from '../../../../constants/backgroundImage';

export const Header: FC = () => (
  <BackgroundImage mih={230} src={USERS_DEFAULT_POSTER}>
    <Stack>
      <PageHeader />
      <Stack pb="48px">
        <Center>
          <Text fz="50px" pt="0" mt="xl" mb="0" pb="0" style={{ textShadow: '0px 0px 12px white' }}>
            Users
          </Text>
        </Center>
      </Stack>
    </Stack>
  </BackgroundImage>
);

import { ActionIcon, px, Tooltip } from '@mantine/core';
import { IconZzzOff } from '@tabler/icons-react';
import { FC, useCallback } from 'react';

import { useCardsManager } from '../../../../../../hooks';
import { UnsnoozeBtnProps } from './types';

export const UnsnoozeBtn: FC<UnsnoozeBtnProps> = ({ cardId }) => {
  const { unSnoozeCard } = useCardsManager();

  const handleUnSnooze = useCallback(() => {
    unSnoozeCard(cardId);
  }, [unSnoozeCard, cardId]);

  return (
    <Tooltip label="Unsnooze card" withArrow>
      <ActionIcon variant="outline" radius="xl" onClick={handleUnSnooze}>
        <IconZzzOff size={px('1rem')} />
      </ActionIcon>
    </Tooltip>
  );
};

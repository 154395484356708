import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { REFETCH_INTERVAL } from '../constants/query';
import { useAuthContext } from '../contexts/AuthContext';
import { webhooksService } from '../services';

export const useWebhooksManager = () => {
  const { isAuthenticated } = useAuthContext();
  const queryClient = useQueryClient();

  const { data: webhooks, isLoading } = useQuery({
    queryKey: ['webhooks'],
    queryFn: webhooksService.getWebhooks,
    enabled: !!isAuthenticated,
    initialData: [],
    retry: false,
    refetchInterval: REFETCH_INTERVAL,
  });

  const addWebhookMutation = useMutation({
    mutationFn: webhooksService.createWebhook,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['webhooks'] });
    },
  });
  const removeWebhookMutation = useMutation({
    mutationFn: webhooksService.deleteWebhook,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['webhooks'] });
    },
  });

  return {
    webhooks,
    addWebhook: addWebhookMutation.mutateAsync,
    removeWebhook: removeWebhookMutation.mutateAsync,
    isLoading,
  };
};

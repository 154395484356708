import { useState } from 'react';

import { SortTypes } from '../../../../components/Sorting/types';
import { Filters } from '../../components/FilterControl/types';

interface Props {
  defaultSortField?: SortTypes;
}

export const useFilters = ({ defaultSortField = SortTypes.Created }: Props) => {
  const [filters, setFilters] = useState<Filters>({
    sortField: defaultSortField,
    sortOrder: true,
    searchQuery: '',
    filter: null,
  });

  const handleFilterChange = (updatedFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...updatedFilters }));
  };

  return { filters, handleFilterChange };
};

import { Group, Text } from '@mantine/core';
import { FC } from 'react';

import { SuggestedActionProps } from './types';

export const SuggestedAction: FC<SuggestedActionProps> = ({ suggestedAction }) => {
  if (!suggestedAction) {
    return null;
  }

  return (
    <Group gap={8}>
      <Text>{suggestedAction}</Text>
    </Group>
  );
};

import { AppShell } from '@mantine/core';
import { FC } from 'react';

import { useAuthContext } from '../../contexts';
import { AuthGuard } from '../../guards';
import { useSetDocumentTitle } from '../../hooks';
import { AccountsList, LoginForm } from './components';

export const LoginPage: FC = () => {
  const { currentUserId } = useAuthContext();
  useSetDocumentTitle('Login');

  return (
    <AuthGuard>
      <AppShell p="0px">
        <AppShell.Main>
          <div
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              height: '100%',
              backgroundPosition: 'center',
              backgroundImage: "URL('/img/desert_background.jpg')",
              backgroundSize: 'cover',
            }}
          >
            {currentUserId && <AccountsList />}
            {!currentUserId && <LoginForm />}
          </div>
        </AppShell.Main>
      </AppShell>
    </AuthGuard>
  );
};

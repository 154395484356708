import { ActionIcon, Avatar, Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import { LabeledIcon } from '../../../../../../../../../../../../../../components/LabeledIcon';
import { useRemoveFromCollection, useUsersManager } from '../../../../../../../../../../../../../../hooks';
import { getStreamRoute } from '../../../../../../../../../../../../../../utils';
import { StreamItemProps } from './types';

export const StreamItem: FC<StreamItemProps> = ({ card, collection }) => {
  const { accountId } = useParams();
  const { getUser } = useUsersManager();

  const creator = getUser(collection.creator_id);

  const handleRemove = useRemoveFromCollection(card, collection);

  if (!accountId) {
    return null;
  }

  return (
    <Group gap="8px" justify="space-between">
      <UnstyledButton component={Link} to={getStreamRoute(accountId, collection.id)}>
        <Group gap="8px" align="center">
          <Avatar size="sm">
            <LabeledIcon icon={collection.icon} stroke="1.5" size="18px" />
          </Avatar>
          <Stack gap="0">
            <Text size="sm">{collection.title}</Text>
            <Text size="xs" c="gray.5">
              {creator?.fullname}
            </Text>
          </Stack>
        </Group>
      </UnstyledButton>

      <ActionIcon onClick={handleRemove} variant="transparent" color="dark.5">
        <IconX size={14} />
      </ActionIcon>
    </Group>
  );
};

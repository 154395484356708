import { useCallback, useEffect, useState } from 'react';

import { AUTH_STATE_KEY } from '../../../../constants/localStorage';
import { AuthLocalStorageState } from '../../types';
import { setAuthHeaders } from '../../utils';

export const useAuthStateInStorage = (shouldRememberMe: boolean) => {
  const [authState, setAuthState] = useState<Partial<AuthLocalStorageState> | null>();
  const [isAuthStateBeingInitialized, setIsAuthStateBeingInitialized] = useState(true);

  const setAuthStateInStorage = useCallback(
    (newValue: Partial<AuthLocalStorageState> | null) => {
      if (!shouldRememberMe) {
        localStorage.removeItem(AUTH_STATE_KEY);
        setAuthState(newValue);
        return;
      }

      if (newValue === null) {
        localStorage.removeItem(AUTH_STATE_KEY);
      } else {
        localStorage.setItem(AUTH_STATE_KEY, JSON.stringify(newValue));
      }
      setAuthState(newValue);
    },
    [shouldRememberMe]
  );

  const readLocalStorage = useCallback(
    (value: string | null) => {
      if (!value) {
        return;
      }

      const state = JSON.parse(value);
      setAuthState(state);

      if (state.accessToken) {
        setAuthHeaders(state.accessToken);
        return;
      }
      if (state.refreshToken) {
        setAuthHeaders(state.refreshToken);
      }
    },
    [setAuthState]
  );

  useEffect(() => {
    const localAuthState = localStorage.getItem(AUTH_STATE_KEY);

    readLocalStorage(localAuthState);
    setIsAuthStateBeingInitialized(false);
  }, [readLocalStorage]);

  return {
    isAuthStateBeingInitialized,
    authState,
    setAuthState,
    setAuthStateInStorage,
  };
};

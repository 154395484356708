import { useLocation, useParams } from 'react-router-dom';

import { getAllCardsStreamRoute } from '../../../../utils';

export const useIsAllCards = () => {
  const { accountId } = useParams();
  const { pathname } = useLocation();

  const isAllCards = Boolean(accountId && getAllCardsStreamRoute(accountId) === pathname);

  return isAllCards;
};

import { Badge } from '@mantine/core';
import { FC } from 'react';

import { getStatusColor, getStatusLabel } from './utils';

interface StateLabelProps {
  status: string;
}

export const StateLabel: FC<StateLabelProps> = ({ status }) => {
  const color = getStatusColor(status);
  const label = getStatusLabel(status);

  // Open, Submitted for Approval, In Execution, or Complete')
  return (
    <Badge variant="filled" color={color} size="xs">
      {label}
    </Badge>
  );
};

import { Flex } from '@mantine/core';
import { FC, PropsWithChildren } from 'react';

import { HelpButton } from './components';

export const PageHeader: FC<PropsWithChildren> = ({ children }) => (
  <Flex align="flex-end" justify="flex-end" pr="sm" pt="sm" gap="xs">
    <HelpButton />
    {children}
  </Flex>
);

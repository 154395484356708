import { ActionIcon, px, TextInput, TextInputProps } from '@mantine/core';
import { IconCircleX, IconSearch } from '@tabler/icons-react';
import { FC } from 'react';

interface SearchInputProps extends TextInputProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

export const SearchInput: FC<SearchInputProps> = ({ searchQuery, setSearchQuery, ...props }) => (
  <TextInput
    placeholder="Search"
    leftSection={<IconSearch size={px('1.125rem')} />}
    type="search"
    value={searchQuery}
    onChange={(e) => {
      setSearchQuery(e.target.value?.toString() || '');
    }}
    rightSection={
      searchQuery ? (
        <ActionIcon
          variant="transparent"
          onClick={() => {
            setSearchQuery('');
          }}
        >
          <IconCircleX size={px('1.125rem')} />
        </ActionIcon>
      ) : null
    }
    {...props}
  />
);

import { Request } from '../../../../../../../../../data';
import { isRequestComplete } from './isRequestComplete';

interface IsWithdrawPossibleProps {
  request: Request;
  currentUserId: string | null;
}

export const isWithdrawPossible = ({ request, currentUserId }: IsWithdrawPossibleProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { requester_id, withdrawn } = request;

  return requester_id === currentUserId && !isRequestComplete(request) && !withdrawn;
};

import { Box, ScrollArea } from '@mantine/core';
import { FC, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { EmptyStream } from '../../components/EmptyStream';
import { StreamList } from '../../components/StreamList';
import { useCollection, useSetDocumentTitle } from '../../hooks';
import { MeetingPageHeader } from './components';
import { useMeetingCategories } from './hooks';

export const MeetingPage: FC = () => {
  const { meetingId } = useParams();
  useSetDocumentTitle('Meetings');

  const viewport = useRef<HTMLDivElement>(null);

  const collection = useCollection(meetingId);
  const meetingCategories = useMeetingCategories(collection);

  if (!collection) {
    return null;
  }

  return (
    <Box pb={16} h="100%" style={{ overflow: 'auto' }}>
      <ScrollArea.Autosize w="100%" type="auto" viewportRef={viewport}>
        <MeetingPageHeader collection={collection} />
        <Box pl="sm" pt="sm" pr="sm">
          <StreamList id={collection.id} streamCategories={meetingCategories} />
          {meetingCategories.length === 0 && <EmptyStream />}
        </Box>
      </ScrollArea.Autosize>
    </Box>
  );
};

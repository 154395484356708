import { Group, Text } from '@mantine/core';
import { FC } from 'react';

import { UserAvatar } from '../../../../../../../../components/UserAvatar';
import { CardCreatorProps } from './types';

export const CardCreator: FC<CardCreatorProps> = ({ card }) => (
  <Group>
    <Text c="gray" fz="sm">
      Created by:
    </Text>
    <UserAvatar user_id={card.creator_id} short />
  </Group>
);

import { useDisclosure } from '@mantine/hooks';
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Card } from '../../data';
import { AddToStreamModal } from './components';
import { AddToStreamContext } from './context';

export const AddToStreamContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [card, setCard] = useState<Card>();

  const openAddToStreamModal = useCallback(
    (cardToOpen: Card) => {
      setCard(cardToOpen);

      open();
    },
    [open]
  );

  const value = useMemo(
    () => ({
      openAddToStreamModal,
    }),
    [openAddToStreamModal]
  );

  return (
    <>
      <AddToStreamContext.Provider value={value}>{children}</AddToStreamContext.Provider>

      {card && <AddToStreamModal card={card} isOpened={opened} onClose={close} />}
    </>
  );
};
